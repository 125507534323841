/* eslint-disable */
import BirthdayBenefits from '-!svg-react-loader!../icons/emojis/BirthdayBenefits.svg';
import CareerDevelopmentPolicy from '-!svg-react-loader!../icons/emojis/CareerDevelopmentPolicy.svg';
import CompanyTechProvided from '-!svg-react-loader!../icons/emojis/CompanyTechProvided.svg';
import DentalInsurance from '-!svg-react-loader!../icons/emojis/DentalInsurance.svg';
import EmployeeDiscounts from '-!svg-react-loader!../icons/emojis/EmployeeDiscounts.svg';
import FlexibleWorking from '-!svg-react-loader!../icons/emojis/FlexibleWorking.svg';
import GenerousHolidayAllowance from '-!svg-react-loader!../icons/emojis/GenerousHolidayAllowance.svg';
import GymMembership from '-!svg-react-loader!../icons/emojis/GymMembership.svg';
import HealthInsurance from '-!svg-react-loader!../icons/emojis/HealthInsurance.svg';
import HighEarningPotential from '-!svg-react-loader!../icons/emojis/HighEarningPotential.svg';
import InternationalOffices from '-!svg-react-loader!../icons/emojis/InternationalOffices.svg';
import MusicInTheOffice from '-!svg-react-loader!../icons/emojis/MusicInTheOffice.svg';
// import OfficeGames from '-!svg-react-loader!../icons/emojis/OfficeGames.svg';
import OfficeSocials from '-!svg-react-loader!../icons/emojis/OfficeSocials.svg';
import PersonalWellbeing from '-!svg-react-loader!../icons/emojis/PersonalWellbeing.svg';
import PetFriendlyOffice from '-!svg-react-loader!../icons/emojis/PetFriendlyOffice.svg';
import ShowerFacilities from '-!svg-react-loader!../icons/emojis/ShowerFacilities.svg';
import SociallyResponsibleCompany from '-!svg-react-loader!../icons/emojis/SociallyResponsibleCompany.svg';
import StockedKitchen from '-!svg-react-loader!../icons/emojis/StockedKitchen.svg';
import TeamBondingAwayDays from '-!svg-react-loader!../icons/emojis/TeamBondingAwayDays.svg';
import TravelWithWork from '-!svg-react-loader!../icons/emojis/TravelWithWork.svg';
import React, { Component } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

import EmployeeDiscount from '../images/EmployeeDiscount.svg';
import Officegames from '../images/Officegames.svg';
import OfficePet from '../images/OfficePet.svg';
import CompanyPhone from '../images/CompanyPhone.svg';
import stockedkitchen from '../images/stockedkitchen.svg';
import Strongpersonal from '../images/businesses/editProfile/Strongpersonal.svg';
import Sociallyresponsible from '../images/businesses/editProfile/Sociallyresponsible.svg';
import TeamBonding from '../images/businesses/editProfile/TeamBonding.svg';
import TheCompanyHas from '../images/TheCompanyHas.svg';


import Travel from '../images/businesses/editProfile/Travel.svg';
import umbrella from '../images/businesses/editProfile/umbrella.svg';
import Officesocials from '../images/businesses/editProfile/Officesocials.svg';
import Music from '../images/businesses/editProfile/Music.svg';
import Yoga from '../images/businesses/editProfile/Yoga.svg';
import Showers from '../images/businesses/editProfile/Showers.svg';
import GenerousSalary from '../images/businesses/editProfile/GenerousSalary.svg';
import Healthcare from '../images/businesses/editProfile/Healthcare.svg';
import birthdaycake from '../images/businesses/editProfile/birthdaycake.svg';
import DentalCare from '../images/businesses/editProfile/DentalCare.svg';
import Flexitimeworking from '../images/businesses/editProfile/Flexitimeworking.svg';
import Gymmembership from '../images/businesses/editProfile/Gymmembership.svg';


import "./VacancyEmojis.css";



class VacancyEmojis extends Component {

    generateRandomStr(length) {

    	var text = "";
    	var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    	for (var i = 0; i < length; i++)
    		text += possible.charAt(Math.floor(Math.random() * possible.length));

    	return text;

    }

    render() {

        let items = this.props.items;
        let limit = this.props.limit + 1;
        let randomId = this.generateRandomStr(10);

        if ( (items === undefined) || (items === null) || (items === "") || (items.length === 0) ) {
            return null;
        }
        items = items.slice(0, limit);

        return (
            <div className="vacancy-emojis clearfix">
                { ((items.indexOf("umbrella") > -1) || (items.indexOf("GenerousHolidayAllowance") > -1)) &&
                    <div className="vacancy-emojis-wrap">
                        <img src={umbrella} alt="umbrella" id={"umbrella" + randomId} />
                        <GenerousHolidayAllowance id={"umbrella" + randomId} />
                        <span>Generous Holiday Allowance</span>
                    </div>
                }
                { ((items.indexOf("birthdaycake") > -1) || (items.indexOf("BirthdayBenefits")) > -1) &&
                    <div className="vacancy-emojis-wrap">
                        <img src={birthdaycake} alt="birthdaycake" id={"birthdaycake" + randomId} />
                        <BirthdayBenefits id={"birthdaycake" + randomId} />
                        <span>Birthday Benefits</span>
                    </div>
                }
                { (items.indexOf("OfficePet") > -1) &&
                    <div className="vacancy-emojis-wrap">
                        <img src={OfficePet} alt="OfficePet" id={"OfficePet" + randomId} />
                        <span>Office pet</span>
                    </div>
                }
                { (items.indexOf("Officegames") > -1) &&
                    <div className="vacancy-emojis-wrap">
                        <img src={Officegames} alt="Officegames" id={"Officegames" + randomId} />
                        <span>Office games e.g. ping pong, tables tennis, office socials</span>
                    </div>
                }
                { (items.indexOf("Gymmembership") > -1) &&
                    <div className="vacancy-emojis-wrap">
                        <img src={Gymmembership} alt="Gymmembership" id={"Gymmembership" + randomId} />
                        <GymMembership id={"GymMembership" + randomId} />
                        <span>Gym membership / access</span>
                    </div>
                }
                { (items.indexOf("CompanyPhone") > -1) &&
                    <div className="vacancy-emojis-wrap">
                        <img src={CompanyPhone} alt="CompanyPhone" id={"CompanyPhone" + randomId} />
                        <span>Company phone / laptop /gear</span>
                    </div>
                }
                { ((items.indexOf("EmployeeDiscount") > -1) || (items.indexOf("EmployeeDiscounts") > -1)|| (items.indexOf("Employeediscounts") > -1)) &&
                    <div className="vacancy-emojis-wrap">
                        <img src={EmployeeDiscount} alt="EmployeeDiscount" id={"EmployeeDiscount" + randomId} />
                        <span>Employee discounts</span>
                    </div>
                }
                { (items.indexOf("TeamBonding") > -1) &&
                    <div className="vacancy-emojis-wrap">
                        <img src={TeamBonding} alt="TeamBonding" id={"TeamBonding" + randomId} />
                        <span>Team Bonding / Activities/ Away Days</span>
                    </div>
                }
                { ((items.indexOf("StockedKitchen") > -1) || (items.indexOf("Stockedkitchen") > -1)) &&
                    <div className="vacancy-emojis-wrap">
                        <img src={stockedkitchen} alt="stockedkitchen" id={"stockedkitchen" + randomId} />
                        <StockedKitchen id={"stockedkitchen" + randomId} />
                        <span>Stocked Kitchen / Breakfast provided</span>
                    </div>
                }
                { (items.indexOf("Healthcare") > -1) &&
                    <div className="vacancy-emojis-wrap">
                        <img src={Healthcare} alt="Healthcare" id={"Healthcare" + randomId} />
                        <HealthInsurance id={"HealthInsurance" + randomId} />
                        <span>Healthcare</span>
                    </div>
                }
                {( (items.indexOf("Showers") > -1) || (items.indexOf("ShowerFacilities") > -1)) &&
                    <div className="vacancy-emojis-wrap">
                        <img src={Showers} alt="Showers" id={"Showers" + randomId} />
                        <ShowerFacilities id={"Showers" + randomId} />
                        <span>Showers at Office</span>
                    </div>
                }
                { (items.indexOf("DentalCare") > -1) &&
                    <div className="vacancy-emojis-wrap">
                        <img src={DentalCare} alt="DentalCare" id={"DentalCare" + randomId} />
                        <DentalInsurance id={"DentalInsuranceEmoji" + randomId} />
                        <span>Dental Care</span>
                    </div>
                }
                { ((items.indexOf("Flexitimeworking") > -1) || (items.indexOf("FlexibleWorking") > -1)) &&
                    <div className="vacancy-emojis-wrap">
                        <img src={Flexitimeworking} alt="Flexitimeworking" id={"Flexitimeworking" + randomId} />
                        <FlexibleWorking id={"Flexitimeworking" + randomId} />
                        <span>Flexi time working</span>
                    </div>
                }
                { (items.indexOf("Travel") > -1) &&
                    <div className="vacancy-emojis-wrap">
                        <img src={Travel} alt="Travel" id={"Travel" + randomId} />
                        <TravelWithWork id={"Travel" + randomId} />
                        <span>Travel with work</span>
                    </div>
                }
                { ((items.indexOf("TheCompanyHas") > -1) || (items.indexOf("Thecompany") > -1)) &&
                    <div className="vacancy-emojis-wrap">
                        <img src={TheCompanyHas} alt="TheCompanyHas" id={"TheCompanyHas" + randomId} />
                        <span>The company has multiple / international offices</span>
                    </div>
                }
                { ((items.indexOf("Officesocials") > -1) || (items.indexOf("OfficeSocials") > -1)) &&
                    <div className="vacancy-emojis-wrap">
                        <img src={Officesocials} alt="Officesocials" id={"Officesocials" + randomId} />
                        <OfficeSocials id={"OfficeSocials" + randomId} />
                        <span>Office Socials / Nights Out</span>
                    </div>
                }
                {  (items.indexOf("GenerousSalary") > -1) &&
                    <div className="vacancy-emojis-wrap">
                        <img src={GenerousSalary} alt="EmployeeDiscount" id={"EmployeeDiscount" + randomId} />
                        <span>Generous Salary or Bonus or competitive salary</span>
                    </div>
                }
                {/* not show */}
                { ((items.indexOf("SociallyResponsibleCompany") > -1) || (items.indexOf("SociallyResponsible") > -1) || (items.indexOf("Sociallyresponsible") > -1)) &&
                    <div className="vacancy-emojis-wrap">
                        <img src={Sociallyresponsible} alt="SociallyResponsibleCompany" id={"SociallyResponsibleCompany" + randomId} />
                        <SociallyResponsibleCompany id={"SociallyResponsibleCompany" + randomId} />
                        <span>Socially responsible company</span>
                    </div>
                }
                { ((items.indexOf("StrongPersonalDevelopment") > -1) || (items.indexOf("Strongpersonal") > -1)) &&
                    <div className="vacancy-emojis-wrap">
                        <img src={Strongpersonal} alt="StrongPersonalDevelopment" id={"StrongPersonalDevelopment" + randomId} />
                        <span>Strong personal development opportunity e.g. they pay for you to upskill</span>
                    </div>
                }
                {( (items.indexOf("Yoga") > -1) || (items.indexOf("PersonalWellbeing") > -1)) &&
                    <div className="vacancy-emojis-wrap">
                        <img src={Yoga} alt="Yoga" id={"Yoga" + randomId} />
                        <PersonalWellbeing id={"Yoga" + randomId} />
                        <span>Yoga / wellness classes</span>
                    </div>
                }
                { (items.indexOf("Music") > -1) &&
                    <div className="vacancy-emojis-wrap">
                        <img src={Music} alt="Music" id={"Music" + randomId} />
                        <MusicInTheOffice id={"Music" + randomId} />
                        <span> Music in the office</span>
                    </div>
                }
            </div>
        );

    }

}

export default VacancyEmojis;
