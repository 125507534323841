import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Modal } from "reactstrap";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import GraduateLottie from '../lottie/graduate.json';
import BusinessLottie from '../lottie/business.json';
import './Modal.css';

const UserTypeWelcomePopup = () => {
  const [modal, setModal] = useState(false);
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    const urlSegments = window.location.href.split('/');
    const user = JSON.parse(localStorage.getItem("user"));
    const email = urlSegments[4];
    const token = urlSegments[5];

    if (email && token) {
      handleSelectUserType({ user_type: 4, isReloadHide: true });
      return;
    }

    const selectedUserType = parseInt(Cookies.get("user_type"));
    setModal(!(user || selectedUserType));
    setUserType(user?.type || selectedUserType);

    if (urlSegments[3] === 'profile') {
      Cookies.set("user_type", 3, { expires: new Date(Date.now() + 300 * 60 * 1000) });
      localStorage.setItem("directProfile", true);
      setModal(false);
    }

    const urlData = `${urlSegments[urlSegments.length - 3]}/${urlSegments[urlSegments.length - 2]}`;
    if (urlData === 'jobs/post' || urlData === 'graduates/post') {
      localStorage.setItem("directProfile", true);
      setModal(false);
    } else {
      localStorage.setItem("directProfile", false);
    }
  }, []);

  const handleSelectUserType = ({ user_type, isReloadHide }) => {
    setModal(false);
    setUserType(user_type);
    Cookies.set("user_type", user_type, { expires: new Date(Date.now() + 300 * 60 * 1000) });

    if (!isReloadHide) {
      window.location.reload();
    }
  };

  const createLottieOptions = (animationData) => ({
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
  });

  const graduateOptions = createLottieOptions(GraduateLottie);
  const businessOptions = createLottieOptions(BusinessLottie);

  return (
    <Modal isOpen={modal} className="welcome_popup_container">
      <div className="welcome_popup">
        <div className="welcome_popup_main">
          <Link to='/login' className="login-right" onClick={() => handleSelectUserType({ user_type: 4 })}>Login</Link>
          <div className="popup_right">
            <h2>Welcome to GradBay</h2>
            <p className="subheading">
              GradBay is an inclusive hiring platform connecting <br />
              Generation Z with growing companies.
            </p>
            <ul className="mb-4">
              <li onClick={() => handleSelectUserType({ user_type: 4 })} className={`${userType === 4 ? "active" : "not-chosen"}`} >
                <span className="icon">
                  <Lottie options={graduateOptions} className="lottie-animation" height={200} width={250} />
                </span>
                <button>I'm applying</button>
              </li>
              <li onClick={() => handleSelectUserType({ user_type: 3 })} className={`${userType === 3 ? "active" : "not-chosen"}`} >
                <span className="icon">
                  <Lottie options={businessOptions} className="lottie-animation" height={200} width={250} />
                </span>
                <button>I'm hiring</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UserTypeWelcomePopup;