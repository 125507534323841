import React from 'react';
import Slider from "react-slick";
import './JobType.css';
import { FiChevronLeft } from 'react-icons/fi';
import { FiChevronRight } from 'react-icons/fi';
import TikTokBlogFeeds from 'v2/organisms/career-hub/TikTokBlogFeeds';
import Lottie from 'react-lottie';
import TechOptions from '../lottie/tech.json'
import FinanceOptions from '../lottie/finance.json'
import MarketingOptions from '../lottie/marketing.json'
import SalesOptions from '../lottie/sales.json'

const JobType = (props) => {

   const onClickSonicCat = (data) => {
      const { sonic_city, sonic_category } = data;
      let location = sonic_city?.[0]?.id;
      let category = sonic_category?.[0]?.id;

      let urlParams;

      if (location) urlParams = new URLSearchParams({ location });

      if (category) urlParams = new URLSearchParams({ category });

      props?.history?.push(`/jobs/express?${urlParams.toString()}`);
   }

   const redirectTo = (keyword) => {
      if (!keyword) return
      props?.history?.push(`/jobs/express?keyword=${keyword}`);
   }

   const settings1 = {
      nextArrow: <FiChevronRight />,
      prevArrow: <FiChevronLeft />,
      dots: false,
      arrows: true,
      infinite: true,
      speed: 1000,
      autoplay: false,
      autoplaySpeed: 2000,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
         {
            breakpoint: 1000,
            settings: {
               slidesToShow: 3,
               slidesToScroll: 3,
            }
         },
         {
            breakpoint: 767,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 2,
            }
         },
         {
            breakpoint: 560,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
            }
         },
      ]
   };

   const createLottieOptions = (animationData) => ({
      loop: true,
      autoplay: true,
      animationData,
      rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
   });

   const techOptions = createLottieOptions(TechOptions);
   const financeOptions = createLottieOptions(FinanceOptions);
   const marketingOptions = createLottieOptions(MarketingOptions);
   const salesOptions = createLottieOptions(SalesOptions);

   return (
      <div className='home-job-feed-wrapper'>
         <div className="jobtype-page">
            <div className="container">
               <h2>Trending</h2>
               <div className="reviews-wrapper">
                  <Slider {...settings1} className="review-contain home_job_card">
                     <div onClick={() => onClickSonicCat({ sonic_category: [{ "id": "Software developers", "text": "Software developers" }] })} className="category-card">
                        <figure>
                           <Lottie options={techOptions} className="lottie-animation" />
                        </figure>
                        <h3>Tech Jobs</h3>
                     </div>
                     <div onClick={() => onClickSonicCat({ sonic_category: [{ "id": "Accountants", "text": "Accountants" }] })} className="category-card">
                        <figure>
                           <Lottie options={financeOptions} className="lottie-animation" />
                        </figure>
                        <h3>Finance Jobs</h3>
                     </div>
                     <div onClick={() => redirectTo('marketing')} className="category-card">
                        <figure>
                           <Lottie options={marketingOptions} className="lottie-animation" />
                        </figure>
                        <h3>Marketing Jobs</h3>
                     </div>
                     <div onClick={() => redirectTo('sales')} className="category-card">
                        <figure>
                           <Lottie options={salesOptions} className="lottie-animation" />
                        </figure>
                        <h3>Sales Jobs</h3>
                     </div>
                  </Slider>
               </div>
            </div>
         </div>
         <div className='container'>
            <div className='home-feed-wrapper'>
               <TikTokBlogFeeds locations="home-page0" userType={props.type === 'business' ? 'business' : 'grads'} />
            </div>
         </div>
      </div>
   );
}

export default JobType;
