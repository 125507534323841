import { combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist'
import { configureStore  } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage' 
import { reducer as formReducer } from "redux-form";

import CoreReducer from 'v2/reducers/CoreReducer'
import AuthReducer from 'v2/reducers/AuthReducer'
import SearchReducer from 'v2/reducers/SearchReducer'
import JobReducer from 'v2/reducers/JobReducer'
import TagsReducer from 'v2/reducers/TagsReducer'
import GradsReducer from 'v2/reducers/GradsReducer'
import ReadabilityReducer from 'v2/reducers/ReadabilityReducer'
import CareerHubReducer from './reducers/CareerHubReducer';

const rootReducer = combineReducers({
	form: formReducer,
	core: CoreReducer,
	auth: AuthReducer,
    search: SearchReducer,
    jobs: JobReducer,
    tags: TagsReducer,
    grads: GradsReducer,
    readability: ReadabilityReducer,
    careerHub: CareerHubReducer
})

const persistConfig = {
    key: 'root',
    version: 1,
    storage: storage,
    blacklist: ['form', 'readability', 'careerHub', 'search'] 
}

const persistedReducer  = persistReducer(persistConfig, rootReducer)
const reduxLogger       = createLogger();
const middleware        = [reduxLogger]

const configureCustomStore = () => {
    let store = configureStore({
        reducer: persistedReducer,
        middleware: [...middleware],
        devTools: process.env.NODE_ENV !== 'production',
    })
    const persistor = persistStore(store)
    return { store, persistor }
}

export default configureCustomStore();

export const { store, persistor } = configureCustomStore();