import React, { Component } from "react";
import CookieConsent from "react-cookie-consent";
import { isIOS, isMobile } from "react-device-detect";
import Loadable from "react-loadable";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";
import Footer from "./components/Footer.js";
import Loader from "./components/AppLoader.js";
import Navbar from "./components/Navbar.js";
import utilities from "./components/Utilities";
import Home from "./Home.js";
// import Redirecter from './Redirecter.js';
import Server from "./Server";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "v2/Store";
import { history } from "v2/Utilities";
import ThemeProvider from "v2/Theme";
import Cookies from "js-cookie";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
const ApplyGovKickStarterScheme = Loadable({ loader: () => import ('./ApplyGovKickStarterScheme.js'), loading: Loader });

// const new_routes = ['login', 'signup', 'restore', 'apply-kickstart-scheme', 'advance-search', 'advance-search-graduates', 'cv-search', 'jobs', 'graduates']

const new_routes = ["apply-kickstart-scheme"];

const Login = Loadable({
  loader: () => import("v2/pages/auth/Login"),
  loading: Loader,
});
const Signup = Loadable({
  loader: () => import("v2/pages/auth/Signup"),
  loading: Loader,
});
const SignupPostJobFree = Loadable({
  loader: () => import("v2/pages/auth/SignupPostJobFree"),
  loading: Loader,
});
const ForgotPassword = Loadable({
  loader: () => import("v2/pages/auth/ForgotPassword"),
  loading: Loader,
});
const ResetPassword = Loadable({
  loader: () => import("v2/pages/auth/ResetPassword"),
  loading: Loader,
});
const CompanyProfileCompletion = Loadable({
  loader: () => import("v2/pages/auth/CompanyProfileCompletion"),
  loading: Loader,
});
const AdvanceJobSearch = Loadable({
  loader: () => import("v2/pages/search/AdvanceJobSearch"),
  loading: Loader,
});
const AdvanceGradSearch = Loadable({
  loader: () => import("v2/pages/search/AdvanceGradSearch"),
  loading: Loader,
});
// const CVSearch = Loadable({ loader: () => import ('app/pages/search/CVSearch'), loading: Loader });
const GraduateList = Loadable({
  loader: () => import("v2/pages/jobs/GraduateList"),
  loading: Loader,
});
const JobList = Loadable({
  loader: () => import("v2/pages/jobs/JobList"),
  loading: Loader,
});

const ReadabilityChecker = Loadable({
  loader: () => import("v2/pages/ReadabilityChecker"),
  loading: Loader,
});

const CareerHub = Loadable({
  loader: () => import("v2/pages/CareerHub"),
  loading: Loader,
});

let Utilities = new utilities();
const LinkedInPage = Loadable({
  loader: () => import("./LinkedInPage"),
  loading: Loader,
});
const JobOnboarding = Loadable({
  loader: () => import("./JobOnboarding.js"),
  loading: Loader,
});
const JobView = Loadable({
  loader: () => import("./JobView.js"),
  loading: Loader,
});
const JobStatistics = Loadable({
  loader: () => import("./JobStatistics.js"),
  loading: Loader,
});
// const RestorePassword = Loadable({ loader: () => import ('./RestorePassword.js'), loading: Loader });
const BlogList = Loadable({
  loader: () => import("./BlogList.js"),
  loading: Loader,
});
const BlogDetails = Loadable({
  loader: () => import("./BlogDetails.js"),
  loading: Loader,
});
const CareerHubDetails = Loadable({
  loader: () => import("./CareerHubDetails.js"),
  loading: Loader,
});
const FAQ = Loadable({ loader: () => import("./FAQ.js"), loading: Loader });
const FAQVideos = Loadable({
  loader: () => import("./FAQVideos.js"),
  loading: Loader,
});
const BlogEdit = Loadable({
  loader: () => import("./BlogEdit.js"),
  loading: Loader,
});
const GraduateCV = Loadable({
  loader: () => import("./GraduateCV.js"),
  loading: Loader,
});
const GraduateDashboard = Loadable({
  loader: () => import("./GraduateDashboard.js"),
  loading: Loader,
});
const GraduateSettings = Loadable({
  loader: () => import("./GraduateSettings.js"),
  loading: Loader,
});
const GraduateOnboarding = Loadable({
  loader: () => import("./GraduateOnboarding.js"),
  loading: Loader,
});
const BusinessOnboarding = Loadable({
  loader: () => import("./BusinessOnboarding.js"),
  loading: Loader,
});
const BusinessDashboard = Loadable({
  loader: () => import("./BusinessDashboard.js"),
  loading: Loader,
});
const CompanySettings = Loadable({
  loader: () => import("./CompanySettings.js"),
  loading: Loader,
});
const Messages = Loadable({
  loader: () => import("./Messages.js"),
  loading: Loader,
});
const AdminDashboard = Loadable({
  loader: () => import("./AdminDashboard.js"),
  loading: Loader,
});
const AdminAgentDashboard = Loadable({
  loader: () => import("./AdminAgentDashboard.js"),
  loading: Loader,
});
const AdminSonicDashboard = Loadable({
  loader: () => import("./AdminSonicDashboard.js"),
  loading: Loader,
});
const AdminLoginAs = Loadable({
  loader: () => import("./components/AdminLoginAs.js"),
  loading: Loader,
});
const ContactUs = Loadable({
  loader: () => import("./ContactUs.js"),
  loading: Loader,
});
const WishList = Loadable({
  loader: () => import("./WishList.js"),
  loading: Loader,
});
const IndustryDetails = Loadable({
  loader: () => import("./IndustryDetails.js"),
  loading: Loader,
});
const About = Loadable({ loader: () => import("./About.js"), loading: Loader });
const VacancyDetails = Loadable({
  loader: () => import("./VacancyDetails.js"),
  loading: Loader,
});

const URLShortner = Loadable({
  loader: () => import("./URLShortner.js"),
  loading: Loader,
});

const GraduateDetails = Loadable({
  loader: () => import("./GraduateDetails.js"),
  loading: Loader,
});
const JobSearch = Loadable({
  loader: () => import("./JobSearch.js"),
  loading: Loader,
});
const JobSearchNew = Loadable({
  loader: () => import("./JobSearchNew.js"),
  loading: Loader,
});
const GradSearchNew = Loadable({
  loader: () => import("./GradSearchNew.js"),
  loading: Loader,
});
const OutsideJobSearch = Loadable({
  loader: () => import("./OutsideJobSearch.js"),
  loading: Loader,
});
const GraduateSearch = Loadable({
  loader: () => import("./GraduateSearch.js"),
  loading: Loader,
});
const AdvanceGraduateSearch = Loadable({
  loader: () => import("./AdvanceSearchGraduate.js"),
  loading: Loader,
});
const CVSearch = Loadable({
  loader: () => import("./CVSearch.js"),
  loading: Loader,
});
const Static = Loadable({
  loader: () => import("./Static.js"),
  loading: Loader,
});
const Error = Loadable({
  loader: () => import("./components/Error.js"),
  loading: Loader,
});
// const Login = Loadable({ loader: () => import ('./Login.js'), loading: Loader });
const Invite = Loadable({
  loader: () => import("./Invite.js"),
  loading: Loader,
});
const PotNoodleMarketing = Loadable({
  loader: () => import("./PotNoodleMarketing.js"),
  loading: Loader,
});
const PepsiCo = Loadable({
  loader: () => import("./PepsiCo.js"),
  loading: Loader,
});
const KickStarter = Loadable({
  loader: () => import("./KickStarter.js"),
  loading: Loader,
});
const GovKickStarterScheme = Loadable({
  loader: () => import("./GovKickStarterScheme.js"),
  loading: Loader,
});

const ThankyouPage = Loadable({
  loader: () => import("./components/ThankyouPage.js"),
  loading: Loader,
});
const EmailNewsletter = Loadable({
  loader: () => import("./components/EmailNewsletter.js"),
  loading: Loader,
});
const EmailVerification = Loadable({
  loader: () => import("./components/EmailVerification.js"),
  loading: Loader,
});
const InterviewScheduler = Loadable({
  loader: () => import("./components/InterviewScheduler.js"),
  loading: Loader,
});
const SubscriptionManager = Loadable({
  loader: () => import("./SubscriptionManager.js"),
  loading: Loader,
});
const EmailSignup = Loadable({
  loader: () => import("./EmailSignup.js"),
  loading: Loader,
});
const Pricing = Loadable({
  loader: () => import("./Pricing.js"),
  loading: Loader,
});

const GPTJobDescription = Loadable({
  loader: () => import("./GPTJobDescription"),
  loading: Loader,
});

const PricingNew = Loadable({
  loader: () => import("./v2/pages/PricingNew"),
  loading: Loader,
});
const Services = Loadable({
  loader: () => import("./Services.js"),
  loading: Loader,
});
const Directory = Loadable({
  loader: () => import("./Directory.js"),
  loading: Loader,
});
const MarketingSubscription = Loadable({
  loader: () => import("./components/MarketingSubscription.js"),
  loading: Loader,
});
const AdvanceSearch = Loadable({
  loader: () => import("./AdvanceSearch"),
  loading: Loader,
});
// const Deloitte = Loadable({ loader: () => import ('./Deloitte.js'), loading: Loader });
const UpdateEmailVerification = Loadable({
  loader: () => import("./UpdateEmailVerification.js"),
  loading: Loader,
});
const AdminMarketingDashboard = Loadable({
  loader: () => import("./AdminMarketingDashboard.js"),
  loading: Loader,
});
const CompnayApplicationSettings = Loadable({
  loader: () => import("./CompnayApplicationSettings.js"),
  loading: Loader,
});
const QuickApplySignup = Loadable({
  loader: () => import("./QuickApplySignup.js"),
  loading: Loader,
});
const MySubscription = Loadable({
  loader: () => import("./MySubscription.js"),
  loading: Loader,
});

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("user") != null ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const GraduateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        parseInt(Cookies.get("user_type")) === 4 || (localStorage.getItem("user") != null && JSON.parse(localStorage.getItem("user")).type === 4) ? 
        (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

const BusinessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        parseInt(Cookies.get("user_type")) === 3 ||
        (localStorage.getItem("user") != null &&
          JSON.parse(localStorage.getItem("user")).type === 3) ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

const CustomAuthRoute = ({ component: Component, ...rest }) => {
  let user = localStorage.getItem("user");
  let user_type = "";
  if (user != null) {
    user_type = JSON.parse(user).type;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        user != null ? (
          user_type == "3" ? (
            <Redirect to="/my-profile/business" />
          ) : (
            <Redirect to="/dashboard" />
          )
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const AdminRoute = ({ component: Component, ...rest }) => {
  let user = localStorage.getItem("user");
  let user_type = "";
  if (user != null) {
    user_type = parseInt(JSON.parse(user).type);
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        user !== null && user_type == 6 ? (
          <Redirect to="/admin-agent" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const AdminSonicRoute = ({ component: Component, ...rest }) => {
  let user = localStorage.getItem("user");
  let user_type = "";
  if (user != null) {
    user_type = parseInt(JSON.parse(user).type);
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        user !== null && user_type == 2 ? (
          <Redirect to="/admin-sonic-job" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      ie: false,
      currentUrl: "/",
      pathname: "/",
    };
  }

  componentDidMount() {
    let ua = navigator.userAgent;
    let is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
    this.setState({ ie: is_ie });

    let currentPage = window.location.href;

    let user = localStorage.getItem("user");

    const isExpress = currentPage.split('/')

    if (isExpress[4]?.startsWith('express') || isExpress[4] === 'hotjob' || isExpress[4] === 'post') {
      var date = new Date();
      var minutes = 300;
      date.setTime(date.getTime() + minutes * 60 * 1000);
      Cookies.set("user_type", 4, { expires: date });
    }

    if (
      currentPage.indexOf("login") === -1 &&
      (user === null || user === undefined)
    ) {
      window.globalVars.previousPage = currentPage;
      localStorage.setItem("previousPage", JSON.stringify(currentPage));
    } else {
      localStorage.removeItem("previousPage");
    }

    setInterval(
      function() {
        let urlNow = window.location.href;
        if (urlNow !== this.state.currentUrl) {
          this.setState({
            currentUrl: urlNow,
            pathname: window.location.pathname.split("/")[1],
          });
          this.getLatestUser();
        }
      }.bind(this),
      200
    );

    this.getConfigs(true);

    setInterval(
      function() {
        this.getConfigs();
      }.bind(this),
      300000
    );
  }

  componentWillMount() {
    let server = new Server();
    let utilitiesGlobal = new utilities();

    window.globalVars = {
      server: server,
      utilities: utilitiesGlobal,
      bucket: "https://s3.eu-west-2.amazonaws.com/gradbay/",
      bucketBlogCoverDir: "blog/covers/",
      bucketBlogVideosDir: "blog/videos/",
      bucketCareerHubCoverDir: "careerhub/covers/",
      bucketGraduateProfilePictureDir: "graduate/profile-pictures/",
      bucketBusinessProfilePictureDir: "business/profile-pictures/",
      bucketMessageAttachments: "email-attachments/",
      bucketGraduateVideoInterviewDir: "graduate/video-interviews/",
      bucketBusinessJobPictureDir: "business/job-pictures/",
      bucketInterviewAttachments: "business/job-attachments/",
      bucketBusinessJobVideosDir: "business/job-videos/",
      bucketBusinessJobPhotosDir: "business/job-pictures/",
      pageTitleBefore: "",
      pageTitleAfter: " | GradBay",
      domain: "https://gradbay.us/",
      outsideLocationCodes: {
        "New York": "ny",
      },
      jobApplications: {},
      tagSuggestionGroups: [
        /* "Analytics & Consultancy" */ [
          "Data Analysis",
          "Business Analysis/Commercial Analysis",
          "Management Consultancy",
          "Strategy Consultancy",
          "Digital Analysis",
          "SEO Analysis",
          "PPC Analysis",
          "Programmatic Advertising",
          "Advertising Operations",
          "Research Analysis",
          "Market Research",
          "Financial Analysis",
          "Accounting",
          "Real Estate Analysis",
          "Underwriting",
        ],
        /* "Marketing" */ [
          "Digital Marketing",
          "Social Media",
          "Copywriting",
          "Community Management",
          "Branding",
          "Email Marketing",
          "Campaign Management",
          "Performance Marketing",
          "Affiliate Marketing",
          "Acquisition Marketing",
          "Retention Marketing",
          "Ecommerce",
          "Media Planning",
          "Editorial",
          "Product Marketing ",
          "Media Buying",
        ],
        /* "Project Management, Events & Logistics" */ [
          "Project Management",
          "Event Management",
          "Operations",
          "Supply Chain",
          "Logistics",
        ],
        /* "Account Management & Sales" */ [
          "Account Management",
          "Client Services",
          "Business Development",
          "Sales",
          "Sales Support",
          "Lead Generation",
          "Telesales",
          "Field Sales",
          "Field Marketing",
        ],
        /* ""Customer Service"" */ [
          "Customer Services",
          "Customer Success",
          "Front-of-House",
          "Receptionist",
          "On-Boarding",
          "Property Management",
          "Estate Agency",
        ],
        /* ""Business Support"" */ [
          "Administration",
          "Business Support",
          "Executive Assistant",
          "Personal Assistant",
          "Office Management",
          "Team Assistant",
        ],
        /* ""HR & Recruitment"" */ [
          "Human Resources",
          "Internal Recruitment",
          "Resourcer",
          "Recruitment Consultant",
          "Executive Search",
        ],
        /* ""Design"" */ [
          "Graphic Design",
          "Web Design",
          "Games Design",
          "UX Design",
          "UI Design",
          "Mobile Design",
          "Video Production",
          "Fashion Design",
          "Product Design",
        ],
        /* ""Other"" */ [
          "Merchandising",
          "Compliance",
          "Procurement",
          "Engineering",
        ],
        /* ""Digital Marketing"" */ [
          "SEO",
          "PPC",
          "Social Media",
          "Google AdWords",
          "Google Analytics",
          "CRM",
          "WordPress",
          "Hootsuite",
          "SurveyMonkey",
          "Copywriting",
          "Email Marketing",
          "Acquisition Marketing",
          "B2B Marketing",
          "B2C Marketing",
          "Facebook Ads",
          "Facebook Analytics",
          "LinkedIn Ads",
          "Marketing Cloud",
          "Retention Marketing",
          "Twitter Ads",
          "Twitter Analytics",
          "YouTube",
          "CMS",
          "Magento",
          "Mailchimp",
          "Magento",
        ],
        /* ""Data Analysis"" */ [
          "Alteryx",
          "Financial Modelling",
          "MATLAB",
          "Xero",
          "Sage",
          "QuickBooks",
          "LaTeX",
          "R",
        ],
        /* ""Data Analysis - Excel"" */ [
          "HLOOKUPs",
          "Macros",
          "Pivot Tables",
          "VLOOKUPs",
        ],
        /* ""Design"" */ [
          "Adobe Creative Suite (all)",
          "Adobe Illustrator",
          "Adobe InDesign",
          "Adobe Photoshop",
          "AutoCAD",
          "UI",
          "UX",
          "Wireframing",
          "SolidWorks",
          "Sketch",
          "Adobe XD",
          "Figma",
        ],
        /* ""Tehnical Support"" */ [
          "Microsoft Office",
          "Active Directory",
          "Windows Server",
          "Microsoft Azure",
          "Linux",
          "Cisco ",
          "AWS",
          "Google Cloud",
        ],
        /* ""Front-end Development"" */ [
          "CSS",
          "Dreamweaver",
          "HTML",
          "JavaScript",
          "React.js",
          "jQuery",
          "Angular",
          "Vue.js",
        ],
        /* ""Back-end Development"" */ [
          "C#",
          "C++",
          "Java",
          "JavaScript",
          "Python",
          "R",
          "Ruby-on-Rails",
          ".NET",
          "Django ",
          "PHP",
          "WordPress",
          "MongoDB",
          "PostgreSQL",
          "MySQL",
          "Drupal",
          "ASP.NET",
        ],
        /* ""Recruitment & Sales"" */ [
          "Bullhorn",
          "Cold calling",
          "Lead Generation",
          "LinkedIn",
          "Salesforce",
          "Networking",
        ],
        /* ""Research"" */ [
          "Market Segmentation",
          "SPSS",
          "Focus Groups",
          "Interviewing",
        ],
        /* ""Video Production"" */ [
          "Adobe After Effects",
          "Adobe Premiere Pro",
          "Final Cut Pro",
        ],
        /* ""Game Development"" */ ["Unity", "Unreal"],
        /* ""Mobile Development"" */ [
          "Swift",
          "Objective C",
          "Java",
          "IONIC",
          "Phonegap",
          "Cordova",
          "Capacitor",
          "React Native",
        ],
        /* ""Most Popular Programming "" */ [
          "Java",
          "Python",
          "C++",
          "C#",
          "Visual Basic .NET",
          "JavaScript",
          "PHP",
          "Objective-C",
          "SQL",
          "Ruby",
          "MATLAB",
          "Go",
          "Perl",
          "ASP.NET",
          "Rust",
          "Haskell",
        ],
      ],
      autoSuggestions: {
        GCSE_GRADES: [
          { text: "9", id: "9" },
          { text: "8", id: "8" },
          { text: "7", id: "7" },
          { text: "6", id: "6" },
          { text: "5", id: "5" },
          { text: "4", id: "4" },
          { text: "3", id: "3" },
          { text: "2", id: "2" },
          { text: "1", id: "1" },
        ],
        ALEVEL_GRADES: [
          { text: "A*", id: "A*" },
          { text: "A", id: "A" },
          { text: "B", id: "B" },
          { text: "C", id: "C" },
          { text: "D", id: "D" },
          { text: "E", id: "E" },
          { text: "U", id: "U" },
        ],
        IB_GRADES: [
          { text: "7", id: "7" },
          { text: "6", id: "6" },
          { text: "5", id: "5" },
          { text: "4", id: "4" },
          { text: "3", id: "3" },
          { text: "2", id: "2" },
          { text: "1", id: "1" },
        ],
        GRADES: [
          { text: "A+", id: "A+" },
          { text: "A", id: "A" },
          { text: "A-", id: "A-" },
          { text: "B+", id: "B+" },
          { text: "B", id: "B" },
          { text: "B-", id: "B-" },
          { text: "C+", id: "C+" },
          { text: "C", id: "C" },
          { text: "C-", id: "C-" },
          { text: "D+", id: "D+" },
          { text: "D", id: "D" },
          { text: "E/F", id: "E/F" },
        ],
        ALEVELS: [
          { text: "A-Level", id: "A-Level" },
          { text: "GCSE", id: "GCSE" },
          { text: "IB", id: "IB" },
        ],
        EDUCATION_TYPE: [
          { text: "School", id: "School" },
          { text: "College", id: "College" },
          { text: "University", id: "University" },
          { text: "Other", id: "Other" },
        ],
        FIRST_ATTEND_UNIVERSITY: [
          { text: "Yes", id: "Yes" },
          { text: "No", id: "No" },
        ],
        SOURCES: [
          { text: "Pot Noodle Campaign", id: "Pot Noodle Campaign" },
          { text: "Google", id: "Google" },
          { text: "Reed", id: "Reed" },
          { text: "Indeed", id: "Indeed" },
          { text: "Guardian", id: "Guardian" },
          { text: "Instagram", id: "Instagram" },
          { text: "Facebook", id: "Facebook" },
          { text: "Other", id: "Other" },
          { text: "Friend", id: "Friend" },
          { text: "GradBay Marketing", id: "GradBay Marketing" },
        ],
        POTNOODLESOURCES: [
          { text: "Callum Whiteley", id: "Callum Whiteley" },
          { text: "Denika Millington", id: "Denika Millington" },
          { text: "Sam Smith", id: "Sam Smith" },
          { text: "Victoria Jones", id: "Victoria Jones" },
          { text: "Aliyah Abbas", id: "Aliyah Abbas" },
          { text: "Rebecca Louise McKenna", id: "Rebecca Louise McKenna" },
          { text: "Charlie Quixley", id: "Charlie Quixley" },
          { text: "Cam Causer", id: "Cam Causer" },
          { text: "Ryan Chang", id: "Ryan Chang" },
          { text: "Kavan Nijjer", id: "Kavan Nijjer" },
          { text: "Fred Parker", id: "Fred Parker" },
          { text: "Lauren Owen", id: "Lauren Owen" },
          { text: "Olivia Drysdale", id: "Olivia Drysdale" },
          { text: "Jasmine Baragwanath", id: "Jasmine Baragwanath" },
          { text: "Nina Watson", id: "Nina Watson" },
          { text: "Daniel Kikbel", id: "Daniel Kikbel" },
          { text: "Charles Ryder", id: "Charles Ryder" },
          { text: "Sophie Evans", id: "Sophie Evans" },
          { text: "Sophie Tomkins", id: "Sophie Tomkins" },
          { text: "Molly Locke", id: "Molly Locke" },
          { text: "Jessica Phillips", id: "Jessica Phillips" },
          { text: "Tom Farmilo", id: "Tom Farmilo" },
          { text: "Chris Lillie", id: "Chris Lillie" },
          { text: "Aman Padan", id: "Aman Padan" },
          { text: "Yasmin Taylor", id: "Yasmin Taylor" },
          { text: "Abby Wynne", id: "Abby Wynne" },
          { text: "Jamie Kimber", id: "Jamie Kimber" },
          { text: "Nicole Whelan", id: "Nicole Whelan" },
          { text: "Boris Strumenliev", id: "Boris Strumenliev" },
          { text: "Millie Burge", id: "Millie Burge" },
          { text: "Aneeqa Siddiqui", id: "Aneeqa Siddiqui" },
          { text: "Angie Galdini", id: "Angie Galdini" },
          { text: "Belle Blackburn", id: "Belle Blackburn" },
          { text: "Meg Jenkinson", id: "Meg Jenkinson" },
          { text: "Shannon Seaton", id: "Shannon Seaton" },
          { text: "Eden Estrajch-Moffatt", id: "Eden Estrajch-Moffatt" },
          { text: "Sam Wash", id: "Sam Wash" },
          { text: "Ned Smith", id: "Ned Smith" },
          { text: "Sam Pougatch", id: "Sam Pougatch" },
          { text: "Emily Bacchus-Waterman", id: "Emily Bacchus-Waterman" },
          { text: "Arwen Lewis-Anthony", id: "Arwen Lewis-Anthony" },
          { text: "Edward Dickinson", id: "Edward Dickinson" },
          { text: "Maggie Woloski", id: "Maggie Woloski" },
          { text: "Grace Chambers", id: "Grace Chambers" },
          { text: "Fern Gable", id: "Fern Gable" },
          { text: "Bethany E Bracken", id: "Bethany E Bracken" },
          { text: "Georgia Shakeshaft", id: "Georgia Shakeshaft" },
        ],
        DEGREES: [
          {
            text: "Higher National Diploma (HND)",
            id: "Higher National Diploma (HND)",
          },
          { text: "Bachelor of Arts (BA)", id: "Bachelor of Arts (BA)" },
          {
            text: "Bachelor of Science (BSc)",
            id: "Bachelor of Science (BSc)",
          },
          { text: "Bachelor of Music (BMus)", id: "Bachelor of Music (BMus)" },
          { text: "Law (LLB)", id: "Law (LLB)" },
          { text: "Engineering (BEng)", id: "Engineering (BEng)" },
          { text: "Master of Arts (MA)", id: "Master of Arts (MA)" },
          { text: "Master of Science (MSc)", id: "Master of Science (MSc)" },
          {
            text: "Postgraduate Certificate (PGCE)",
            id: "Postgraduate Certificate (PGCE)",
          },
          { text: "PhD", id: "PhD" },
          { text: "History", id: "History" },
        ],
        CITIES: [
          { text: "London", id: "London" },
          { text: "Birmingham", id: "Birmingham" },
          { text: "Bristol", id: "Bristol" },
          { text: "Cambridge", id: "Cambridge" },
          { text: "Cardiff", id: "Cardiff" },
          { text: "Durham", id: "Durham" },
          { text: "Edinburgh", id: "Edinburgh" },
          { text: "Exeter", id: "Exeter" },
          { text: "Glasgow", id: "Glasgow" },
          { text: "Leeds", id: "Leeds" },
          { text: "Liverpool", id: "Liverpool" },
          { text: "Manchester", id: "Manchester" },
          { text: "Newcastle", id: "Newcastle" },
          { text: "Nottingham", id: "Nottingham" },
          { text: "Oxford", id: "Oxford" },
          { text: "Belfast", id: "Belfast" },
          { text: "Sheffield", id: "Sheffield" },
          { text: "Southampton", id: "Southampton" },
          { text: "Warwick", id: "Warwick" },
          { text: "York", id: "York" },
        ],
        UNIVERSITIES: [
          { text: `Abilene Christian University`, id: `Abilene Christian University` },
          { text: `Academy of Art College`, id: `Academy of Art College` },
          { text: `Adams State College`, id: `Adams State College` },
          { text: `Adelphi University`, id: `Adelphi University` },
          { text: `Adler School of Professional Psychology`, id: `Adler School of Professional Psychology` },
          { text: `Adrian College`, id: `Adrian College` },
          { text: `Agnes Scott College`, id: `Agnes Scott College` },
          { text: `Air Force Institute of Technology`, id: `Air Force Institute of Technology` },
          { text: `Alabama Agricultural and Mechanical University`, id: `Alabama Agricultural and Mechanical University` },
          { text: `Alabama State University`, id: `Alabama State University` },
          { text: `Alaska Bible College`, id: `Alaska Bible College` },
          { text: `Alaska Pacific University`, id: `Alaska Pacific University` },
          { text: `Albany College of Pharmacy`, id: `Albany College of Pharmacy` },
          { text: `Albany Law School`, id: `Albany Law School` },
          { text: `Albany Medical Center`, id: `Albany Medical Center` },
          { text: `Albany State University`, id: `Albany State University` },
          { text: `Albertus Magnus College`, id: `Albertus Magnus College` },
          { text: `Albion College`, id: `Albion College` },
          { text: `Albright College`, id: `Albright College` },
          { text: `Alcorn State University`, id: `Alcorn State University` },
          { text: `Alderson Broaddus College`, id: `Alderson Broaddus College` },
          { text: `Alfred Adler Graduate School`, id: `Alfred Adler Graduate School` },
          { text: `Alfred University`, id: `Alfred University` },
          { text: `Alice Lloyd College`, id: `Alice Lloyd College` },
          { text: `Allegheny College`, id: `Allegheny College` },
          { text: `Allen University`, id: `Allen University` },
          { text: `Alma College`, id: `Alma College` },
          { text: `Alvernia College`, id: `Alvernia College` },
          { text: `Alverno College`, id: `Alverno College` },
          { text: `Ambassador University`, id: `Ambassador University` },
          { text: `Amber University`, id: `Amber University` },
          { text: `American Academy of Nutrition`, id: `American Academy of Nutrition` },
          { text: `American Business & Technology University`, id: `American Business & Technology University` },
          { text: `American Conservatory of Music`, id: `American Conservatory of Music` },
          { text: `American Conservatory Theater`, id: `American Conservatory Theater` },
          { text: `American-European School of Management `, id: `American-European School of Management ` },
          { text: `American Film Institute Center for Advanced Film and Television Studies`, id: `American Film Institute Center for Advanced Film and Television Studies` },
          { text: `American Indian College`, id: `American Indian College` },
          { text: `American InterContinental University - Atlanta`, id: `American InterContinental University - Atlanta` },
          { text: `American InterContinental University - Ft. Lauderdale`, id: `American InterContinental University - Ft. Lauderdale` },
          { text: `American InterContinental University - Georgia`, id: `American InterContinental University - Georgia` },
          { text: `American InterContinental University Online`, id: `American InterContinental University Online` },
          { text: `American International College`, id: `American International College` },
          { text: `American Jewish University`, id: `American Jewish University` },
          { text: `American Military University`, id: `American Military University` },
          { text: `American Public University`, id: `American Public University` },
          { text: `American University`, id: `American University` },
          { text: `American World University`, id: `American World University` },
          { text: `Amherst College`, id: `Amherst College` },
          { text: `Anderson College`, id: `Anderson College` },
          { text: `Anderson University`, id: `Anderson University` },
          { text: `Andon College - Modesto`, id: `Andon College - Modesto` },
          { text: `Andon College - Stockton`, id: `Andon College - Stockton` },
          { text: `Andrew Jackson University`, id: `Andrew Jackson University` },
          { text: `Andrews University`, id: `Andrews University` },
          { text: `Angelo State University`, id: `Angelo State University` },
          { text: `Anna Maria College`, id: `Anna Maria College` },
          { text: `Antioch New England Graduate School`, id: `Antioch New England Graduate School` },
          { text: `Antioch University`, id: `Antioch University` },
          { text: `Antioch University Los Angeles`, id: `Antioch University Los Angeles` },
          { text: `Antioch University Santa Barbara`, id: `Antioch University Santa Barbara` },
          { text: `Antioch University Seattle`, id: `Antioch University Seattle` },
          { text: `Appalachian Bible College`, id: `Appalachian Bible College` },
          { text: `Appalachian State University`, id: `Appalachian State University` },
          { text: `Aquinas College`, id: `Aquinas College` },
          { text: `Arcadia University`, id: `Arcadia University` },
          { text: `Argosy University`, id: `Argosy University` },
          { text: `Argosy University - Hawaii`, id: `Argosy University - Hawaii` },
          { text: `Arizona Christian University`, id: `Arizona Christian University` },
          { text: `Arizona State University`, id: `Arizona State University` },
          { text: `Arizona State University, Downtown Phoenix Campus`, id: `Arizona State University, Downtown Phoenix Campus` },
          { text: `Arizona State University, Polytechnic Campus`, id: `Arizona State University, Polytechnic Campus` },
          { text: `Arizona State University, Tempe Campus`, id: `Arizona State University, Tempe Campus` },
          { text: `Arizona State University, West Campus`, id: `Arizona State University, West Campus` },
          { text: `Arkansas State University`, id: `Arkansas State University` },
          { text: `Arkansas State University, Beebe`, id: `Arkansas State University, Beebe` },
          { text: `Arkansas State University, Mountain Home`, id: `Arkansas State University, Mountain Home` },
          { text: `Arkansas State University, Newport`, id: `Arkansas State University, Newport` },
          { text: `Arkansas Tech University`, id: `Arkansas Tech University` },
          { text: `Arlington Baptist College`, id: `Arlington Baptist College` },
          { text: `Armstrong Atlantic State University`, id: `Armstrong Atlantic State University` },
          { text: `Armstrong University`, id: `Armstrong University` },
          { text: `Art Academy of Cincinnati`, id: `Art Academy of Cincinnati` },
          { text: `Art Center College of Design`, id: `Art Center College of Design` },
          { text: `Arthur D. Little Management Education Institute`, id: `Arthur D. Little Management Education Institute` },
          { text: `Art Institute of Charlotte`, id: `Art Institute of Charlotte` },
          { text: `Art Institute of Southern California`, id: `Art Institute of Southern California` },
          { text: `Asbury College`, id: `Asbury College` },
          { text: `Ashland University`, id: `Ashland University` },
          { text: `Assumption College`, id: `Assumption College` },
          { text: `Athenaeum of Ohio`, id: `Athenaeum of Ohio` },
          { text: `Athens State College`, id: `Athens State College` },
          { text: `Atlanta Christian College`, id: `Atlanta Christian College` },
          { text: `Atlanta College of Art`, id: `Atlanta College of Art` },
          { text: `Atlantic International University`, id: `Atlantic International University` },
          { text: `Atlantic Union College`, id: `Atlantic Union College` },
          { text: `Atlantic University`, id: `Atlantic University` },
          { text: `Auburn University`, id: `Auburn University` },
          { text: `Auburn University at Montgomery`, id: `Auburn University at Montgomery` },
          { text: `Audrey Cohen College`, id: `Audrey Cohen College` },
          { text: `Augsburg College`, id: `Augsburg College` },
          { text: `Augustana College`, id: `Augustana College` },
          { text: `Augustana College`, id: `Augustana College` },
          { text: `Augusta State University`, id: `Augusta State University` },
          { text: `Aurora University`, id: `Aurora University` },
          { text: `Austin College`, id: `Austin College` },
          { text: `Austin Community College`, id: `Austin Community College` },
          { text: `Austin Peay State University`, id: `Austin Peay State University` },
          { text: `Ave Maria University`, id: `Ave Maria University` },
          { text: `Averett College`, id: `Averett College` },
          { text: `Avila College`, id: `Avila College` },
          { text: `Azusa Pacific University`, id: `Azusa Pacific University` },
          { text: `Babson College`, id: `Babson College` },
          { text: `Baker College of Auburn Hills`, id: `Baker College of Auburn Hills` },
          { text: `Baker College of Cadillac`, id: `Baker College of Cadillac` },
          { text: `Baker College of Flint`, id: `Baker College of Flint` },
          { text: `Baker College of Mount Clemens`, id: `Baker College of Mount Clemens` },
          { text: `Baker College of Muskegon`, id: `Baker College of Muskegon` },
          { text: `Baker College of Owosso`, id: `Baker College of Owosso` },
          { text: `Baker College of Port Huron`, id: `Baker College of Port Huron` },
          { text: `Baker University`, id: `Baker University` },
          { text: `Baldwin-Wallace College`, id: `Baldwin-Wallace College` },
          { text: `Ball State University`, id: `Ball State University` },
          { text: `Baltimore Hebrew University`, id: `Baltimore Hebrew University` },
          { text: `Baltimore International College`, id: `Baltimore International College` },
          { text: `Bank Street College of Education`, id: `Bank Street College of Education` },
          { text: `Baptist Bible College of Missouri`, id: `Baptist Bible College of Missouri` },
          { text: `Baptist Bible College of Pennsylvania`, id: `Baptist Bible College of Pennsylvania` },
          { text: `Barat College`, id: `Barat College` },
          { text: `Barber-Scotia College`, id: `Barber-Scotia College` },
          { text: `Barclay College`, id: `Barclay College` },
          { text: `Bard College`, id: `Bard College` },
          { text: `Bard Graduate Center for Studies in the Decorative Arts`, id: `Bard Graduate Center for Studies in the Decorative Arts` },
          { text: `Barnard College - Columbia University`, id: `Barnard College - Columbia University` },
          { text: `Barry University`, id: `Barry University` },
          { text: `Bartlesville Wesleyan College`, id: `Bartlesville Wesleyan College` },
          { text: `Barton College`, id: `Barton College` },
          { text: `Bastyr University`, id: `Bastyr University` },
          { text: `Bates College`, id: `Bates College` },
          { text: `Bauder College`, id: `Bauder College` },
          { text: `Baylor College of Dentistry`, id: `Baylor College of Dentistry` },
          { text: `Baylor College of Medicine`, id: `Baylor College of Medicine` },
          { text: `Baylor University`, id: `Baylor University` },
          { text: `Belhaven College`, id: `Belhaven College` },
          { text: `Bellarmine College`, id: `Bellarmine College` },
          { text: `Bellevue University`, id: `Bellevue University` },
          { text: `Bellin College of Nursing`, id: `Bellin College of Nursing` },
          { text: `Belmont Abbey College`, id: `Belmont Abbey College` },
          { text: `Belmont University`, id: `Belmont University` },
          { text: `Beloit College`, id: `Beloit College` },
          { text: `Bemidji State Univeristy`, id: `Bemidji State Univeristy` },
          { text: `Benedict College`, id: `Benedict College` },
          { text: `Benedictine College`, id: `Benedictine College` },
          { text: `Benedictine University`, id: `Benedictine University` },
          { text: `Benedictine University, Springfield College in Illinois`, id: `Benedictine University, Springfield College in Illinois` },
          { text: `Bennett College`, id: `Bennett College` },
          { text: `Bennington College`, id: `Bennington College` },
          { text: `Bentley College`, id: `Bentley College` },
          { text: `Berea College`, id: `Berea College` },
          { text: `Berean University of the Assemblies of God`, id: `Berean University of the Assemblies of God` },
          { text: `Berklee College of Music`, id: `Berklee College of Music` },
          { text: `Berne University`, id: `Berne University` },
          { text: `Berry College`, id: `Berry College` },
          { text: `Bethany College California`, id: `Bethany College California` },
          { text: `Bethany College Kansas`, id: `Bethany College Kansas` },
          { text: `Bethany College West Virginia`, id: `Bethany College West Virginia` },
          { text: `Bethel College McKenzie`, id: `Bethel College McKenzie` },
          { text: `Bethel College Mishawaka`, id: `Bethel College Mishawaka` },
          { text: `Bethel College Newton`, id: `Bethel College Newton` },
          { text: `Beth-El College of Nursing and Health Sciences`, id: `Beth-El College of Nursing and Health Sciences` },
          { text: `Bethel College St. Paul`, id: `Bethel College St. Paul` },
          { text: `Bethune-Cookman College`, id: `Bethune-Cookman College` },
          { text: `Biola University`, id: `Biola University` },
          { text: `Birmingham-Southern College`, id: `Birmingham-Southern College` },
          { text: `Blackburn College`, id: `Blackburn College` },
          { text: `Black Hawk College`, id: `Black Hawk College` },
          { text: `Black Hills State University`, id: `Black Hills State University` },
          { text: `Blessing-Rieman College of Nursing`, id: `Blessing-Rieman College of Nursing` },
          { text: `Bloomfield College`, id: `Bloomfield College` },
          { text: `Bloomsburg University of Pennsylvania`, id: `Bloomsburg University of Pennsylvania` },
          { text: `Bluefield College`, id: `Bluefield College` },
          { text: `Bluefield State College`, id: `Bluefield State College` },
          { text: `Blue Mountain College`, id: `Blue Mountain College` },
          { text: `Bluffton College`, id: `Bluffton College` },
          { text: `Bob Jones University`, id: `Bob Jones University` },
          { text: `Boise Bible College`, id: `Boise Bible College` },
          { text: `Boise State University`, id: `Boise State University` },
          { text: `Boricua College`, id: `Boricua College` },
          { text: `Boston Architectural Center`, id: `Boston Architectural Center` },
          { text: `Boston College`, id: `Boston College` },
          { text: `Boston University`, id: `Boston University` },
          { text: `Bowdoin College`, id: `Bowdoin College` },
          { text: `Bowie State University`, id: `Bowie State University` },
          { text: `Bowling Green State University`, id: `Bowling Green State University` },
          { text: `Bowling Green State University, Firelands`, id: `Bowling Green State University, Firelands` },
          { text: `Bradley University`, id: `Bradley University` },
          { text: `Brandeis University`, id: `Brandeis University` },
          { text: `Brandman University`, id: `Brandman University` },
          { text: `Brenau University`, id: `Brenau University` },
          { text: `Brescia University`, id: `Brescia University` },
          { text: `Brevard College`, id: `Brevard College` },
          { text: `Brewton-Parker College`, id: `Brewton-Parker College` },
          { text: `Breyer State University (Virtual University)`, id: `Breyer State University (Virtual University)` },
          { text: `Briar Cliff College`, id: `Briar Cliff College` },
          { text: `Bridgewater College`, id: `Bridgewater College` },
          { text: `Bridgewater State College`, id: `Bridgewater State College` },
          { text: `Brigham Young University`, id: `Brigham Young University` },
          { text: `Brigham Young University`, id: `Brigham Young University` },
          { text: `Brigham Young University Hawaii`, id: `Brigham Young University Hawaii` },
          { text: `Brooklyn Law School`, id: `Brooklyn Law School` },
          { text: `Brooks Institute of Photography`, id: `Brooks Institute of Photography` },
          { text: `Brown University`, id: `Brown University` },
          { text: `Brunswick Community College`, id: `Brunswick Community College` },
          { text: `Bryan College`, id: `Bryan College` },
          { text: `Bryant and Stratton College`, id: `Bryant and Stratton College` },
          { text: `Bryant University`, id: `Bryant University` },
          { text: `Bryn Athyn College of the New Church`, id: `Bryn Athyn College of the New Church` },
          { text: `Bryn Mawr College`, id: `Bryn Mawr College` },
          { text: `Bucknell University`, id: `Bucknell University` },
          { text: `Buena Vista University`, id: `Buena Vista University` },
          { text: `Burlington College`, id: `Burlington College` },
          { text: `Butler University`, id: `Butler University` },
          { text: `Cabrini College`, id: `Cabrini College` },
          { text: `Caldwell College`, id: `Caldwell College` },
          { text: `California Baptist College`, id: `California Baptist College` },
          { text: `California Coast University`, id: `California Coast University` },
          { text: `California College for Health Sciences`, id: `California College for Health Sciences` },
          { text: `California College of Arts and Crafts`, id: `California College of Arts and Crafts` },
          { text: `California College of Podiatric Medicine`, id: `California College of Podiatric Medicine` },
          { text: `California College San Diego`, id: `California College San Diego` },
          { text: `California Institute of Integral Studies`, id: `California Institute of Integral Studies` },
          { text: `California Institute of Technology`, id: `California Institute of Technology` },
          { text: `California Institute of the Arts`, id: `California Institute of the Arts` },
          { text: `California Lutheran University`, id: `California Lutheran University` },
          { text: `California Maritime Academy`, id: `California Maritime Academy` },
          { text: `California Polytechnic State University - San Luis Obispo`, id: `California Polytechnic State University - San Luis Obispo` },
          { text: `California School of Professional Psychology - Berkley/Alameda`, id: `California School of Professional Psychology - Berkley/Alameda` },
          { text: `California School of Professional Psychology - Fresno`, id: `California School of Professional Psychology - Fresno` },
          { text: `California School of Professional Psychology - Los Angels`, id: `California School of Professional Psychology - Los Angels` },
          { text: `California School of Professional Psychology - San Diego`, id: `California School of Professional Psychology - San Diego` },
          { text: `California State Polytechnic University - Pomona`, id: `California State Polytechnic University - Pomona` },
          { text: `California State University, Bakersfield`, id: `California State University, Bakersfield` },
          { text: `California State University, Channel Islands`, id: `California State University, Channel Islands` },
          { text: `California State University, Chico`, id: `California State University, Chico` },
          { text: `California State University, Dominguez Hills`, id: `California State University, Dominguez Hills` },
          { text: `California State University, Fresno`, id: `California State University, Fresno` },
          { text: `California State University, Fullerton`, id: `California State University, Fullerton` },
          { text: `California State University, Hayward`, id: `California State University, Hayward` },
          { text: `California State University, Long Beach`, id: `California State University, Long Beach` },
          { text: `California State University, Los Angeles`, id: `California State University, Los Angeles` },
          { text: `California State University, Monterey Bay`, id: `California State University, Monterey Bay` },
          { text: `California State University, Northridge`, id: `California State University, Northridge` },
          { text: `California State University, Sacramento`, id: `California State University, Sacramento` },
          { text: `California State University, San Bernadino`, id: `California State University, San Bernadino` },
          { text: `California State University, San Marcos`, id: `California State University, San Marcos` },
          { text: `California State University, Stanislaus`, id: `California State University, Stanislaus` },
          { text: `California University of Management and Sciences`, id: `California University of Management and Sciences` },
          { text: `California University of Pennsylvania`, id: `California University of Pennsylvania` },
          { text: `California Western School of Law`, id: `California Western School of Law` },
          { text: `Calumet College of St. Joseph`, id: `Calumet College of St. Joseph` },
          { text: `Calvary Bible College`, id: `Calvary Bible College` },
          { text: `Calvin College`, id: `Calvin College` },
          { text: `Cambridge College`, id: `Cambridge College` },
          { text: `Cameron University`, id: `Cameron University` },
          { text: `Campbellsville College`, id: `Campbellsville College` },
          { text: `Campbell University`, id: `Campbell University` },
          { text: `Canisius College`, id: `Canisius College` },
          { text: `Capella University`, id: `Capella University` },
          { text: `Capital University`, id: `Capital University` },
          { text: `Capital University Law School`, id: `Capital University Law School` },
          { text: `Capitol College`, id: `Capitol College` },
          { text: `Cardinal Stritch University`, id: `Cardinal Stritch University` },
          { text: `Carleton College`, id: `Carleton College` },
          { text: `Carlos Albizu University`, id: `Carlos Albizu University` },
          { text: `Carlow College`, id: `Carlow College` },
          { text: `Carnegie Mellon University`, id: `Carnegie Mellon University` },
          { text: `Carroll College Helena`, id: `Carroll College Helena` },
          { text: `Carroll College Waukesha`, id: `Carroll College Waukesha` },
          { text: `Carson-Newman College`, id: `Carson-Newman College` },
          { text: `Carthage College`, id: `Carthage College` },
          { text: `Case Western Reserve University`, id: `Case Western Reserve University` },
          { text: `Castleton State College`, id: `Castleton State College` },
          { text: `Catawba College`, id: `Catawba College` },
          { text: `Catholic Theological Union`, id: `Catholic Theological Union` },
          { text: `Cedar Crest College`, id: `Cedar Crest College` },
          { text: `Cedarville College`, id: `Cedarville College` },
          { text: `Centenary College`, id: `Centenary College` },
          { text: `Centenary College of Louisiana`, id: `Centenary College of Louisiana` },
          { text: `Center for Humanistic Studies`, id: `Center for Humanistic Studies` },
          { text: `Central Baptist College`, id: `Central Baptist College` },
          { text: `Central Bible College`, id: `Central Bible College` },
          { text: `Central Christian College of the Bible`, id: `Central Christian College of the Bible` },
          { text: `Central College`, id: `Central College` },
          { text: `Central Connecticut State University`, id: `Central Connecticut State University` },
          { text: `Central Methodist College`, id: `Central Methodist College` },
          { text: `Central Michigan University`, id: `Central Michigan University` },
          { text: `Central State University`, id: `Central State University` },
          { text: `Central Washington University`, id: `Central Washington University` },
          { text: `Centre College`, id: `Centre College` },
          { text: `Chadron State College`, id: `Chadron State College` },
          { text: `Chaminade University of Honolulu`, id: `Chaminade University of Honolulu` },
          { text: `Champlain College`, id: `Champlain College` },
          { text: `Chapman University`, id: `Chapman University` },
          { text: `Charles R. Drew University of Medicine and Science`, id: `Charles R. Drew University of Medicine and Science` },
          { text: `Charleston Southern University`, id: `Charleston Southern University` },
          { text: `Charter Oak State College`, id: `Charter Oak State College` },
          { text: `Chatham College`, id: `Chatham College` },
          { text: `Chestnut Hill College`, id: `Chestnut Hill College` },
          { text: `Cheyney University of Pennsylvania`, id: `Cheyney University of Pennsylvania` },
          { text: `Chicago State University`, id: `Chicago State University` },
          { text: `Chowan College`, id: `Chowan College` },
          { text: `Christendom College`, id: `Christendom College` },
          { text: `Christian Brothers University`, id: `Christian Brothers University` },
          { text: `Christian Heritage College`, id: `Christian Heritage College` },
          { text: `Christopher Newport University`, id: `Christopher Newport University` },
          { text: `Circleville Bible College`, id: `Circleville Bible College` },
          { text: `City University`, id: `City University` },
          { text: `City University of New York, Bernard M. Baruch College`, id: `City University of New York, Bernard M. Baruch College` },
          { text: `City University of New York, Brooklyn College`, id: `City University of New York, Brooklyn College` },
          { text: `City University of New York, City College`, id: `City University of New York, City College` },
          { text: `City University of New York, College of Staten Island`, id: `City University of New York, College of Staten Island` },
          { text: `City University of New York (CUNY) System`, id: `City University of New York (CUNY) System` },
          { text: `City University of New York, Graduate School and University Center`, id: `City University of New York, Graduate School and University Center` },
          { text: `City University of New York, Hunter College`, id: `City University of New York, Hunter College` },
          { text: `City University of New York, John Jay College of Criminal Justice`, id: `City University of New York, John Jay College of Criminal Justice` },
          { text: `City University of New York, Lehman College`, id: `City University of New York, Lehman College` },
          { text: `City University of New York, Medgar Evers College`, id: `City University of New York, Medgar Evers College` },
          { text: `City University of New York Medical School / Sophie Davis School of Biomedical Education`, id: `City University of New York Medical School / Sophie Davis School of Biomedical Education` },
          { text: `City University of New York, New York City Technical College`, id: `City University of New York, New York City Technical College` },
          { text: `City University of New York, Queens College`, id: `City University of New York, Queens College` },
          { text: `City University of New York, School of Law at Queens College`, id: `City University of New York, School of Law at Queens College` },
          { text: `City University of New York, York College`, id: `City University of New York, York College` },
          { text: `Claflin College`, id: `Claflin College` },
          { text: `Claremont Graduate University`, id: `Claremont Graduate University` },
          { text: `Claremont Lincoln University`, id: `Claremont Lincoln University` },
          { text: `Claremont McKenna College`, id: `Claremont McKenna College` },
          { text: `Clarion University`, id: `Clarion University` },
          { text: `Clark Atlanta University`, id: `Clark Atlanta University` },
          { text: `Clarke College`, id: `Clarke College` },
          { text: `Clarkson College`, id: `Clarkson College` },
          { text: `Clarkson University`, id: `Clarkson University` },
          { text: `Clark University`, id: `Clark University` },
          { text: `Clayton College & State University`, id: `Clayton College & State University` },
          { text: `Clearwater Christian College`, id: `Clearwater Christian College` },
          { text: `Cleary College`, id: `Cleary College` },
          { text: `Clemson University`, id: `Clemson University` },
          { text: `Cleveland Chiropractic College, Kansas City`, id: `Cleveland Chiropractic College, Kansas City` },
          { text: `Cleveland Chiropractic College, Los Angeles`, id: `Cleveland Chiropractic College, Los Angeles` },
          { text: `Cleveland Institute of Art`, id: `Cleveland Institute of Art` },
          { text: `Cleveland Institute of Music`, id: `Cleveland Institute of Music` },
          { text: `Cleveland State University`, id: `Cleveland State University` },
          { text: `Coastal Carolina University`, id: `Coastal Carolina University` },
          { text: `Coe College`, id: `Coe College` },
          { text: `Cogswell Polytechnical College`, id: `Cogswell Polytechnical College` },
          { text: `Coker College`, id: `Coker College` },
          { text: `Colby College`, id: `Colby College` },
          { text: `Colby-Sawyer College`, id: `Colby-Sawyer College` },
          { text: `Coleman College`, id: `Coleman College` },
          { text: `Colgate University`, id: `Colgate University` },
          { text: `CollegeAmerica, Denver`, id: `CollegeAmerica, Denver` },
          { text: `CollegeAmerica, Phoenix`, id: `CollegeAmerica, Phoenix` },
          { text: `College for Creative Studies`, id: `College for Creative Studies` },
          { text: `College for Financial Planning`, id: `College for Financial Planning` },
          { text: `College for Lifelong Learning`, id: `College for Lifelong Learning` },
          { text: `College Misericordia`, id: `College Misericordia` },
          { text: `College of Aeronautics`, id: `College of Aeronautics` },
          { text: `College of Charleston`, id: `College of Charleston` },
          { text: `College of DuPage`, id: `College of DuPage` },
          { text: `College of Idaho`, id: `College of Idaho` },
          { text: `College of Mount St. Joseph`, id: `College of Mount St. Joseph` },
          { text: `College of Mount St. Vincent`, id: `College of Mount St. Vincent` },
          { text: `College of New Rochelle`, id: `College of New Rochelle` },
          { text: `College of Notre Dame`, id: `College of Notre Dame` },
          { text: `College of Notre Dame of Maryland`, id: `College of Notre Dame of Maryland` },
          { text: `College of Osteopathic Medicine of the Pacific (Western University of Health Sciences)`, id: `College of Osteopathic Medicine of the Pacific (Western University of Health Sciences)` },
          { text: `College of St. Benedict and St. John's University`, id: `College of St. Benedict and St. John's University` },
          { text: `College of St. Catherine`, id: `College of St. Catherine` },
          { text: `College of St. Elizabeth`, id: `College of St. Elizabeth` },
          { text: `College of St. Joseph`, id: `College of St. Joseph` },
          { text: `College of St. Mary`, id: `College of St. Mary` },
          { text: `College of St. Rose`, id: `College of St. Rose` },
          { text: `College of St. Scholastica`, id: `College of St. Scholastica` },
          { text: `College of the Atlantic`, id: `College of the Atlantic` },
          { text: `College of the Holy Cross`, id: `College of the Holy Cross` },
          { text: `College of the Ozarks`, id: `College of the Ozarks` },
          { text: `College of Visual Arts`, id: `College of Visual Arts` },
          { text: `College of William and Mary`, id: `College of William and Mary` },
          { text: `Colorado Christian University`, id: `Colorado Christian University` },
          { text: `Colorado College`, id: `Colorado College` },
          { text: `Colorado School of Mines`, id: `Colorado School of Mines` },
          { text: `Colorado State University`, id: `Colorado State University` },
          { text: `Colorado State University-Pueblo`, id: `Colorado State University-Pueblo` },
          { text: `Colorado Technical University`, id: `Colorado Technical University` },
          { text: `Columbia College Chicago`, id: `Columbia College Chicago` },
          { text: `Columbia College Hollywood`, id: `Columbia College Hollywood` },
          { text: `Columbia College of Missouri`, id: `Columbia College of Missouri` },
          { text: `Columbia College South Carolina`, id: `Columbia College South Carolina` },
          { text: `Columbia Commonwealth University`, id: `Columbia Commonwealth University` },
          { text: `Columbia International University`, id: `Columbia International University` },
          { text: `Columbia Southern University`, id: `Columbia Southern University` },
          { text: `Columbia Union College`, id: `Columbia Union College` },
          { text: `Columbia University`, id: `Columbia University` },
          { text: `Columbus College of Art and Design`, id: `Columbus College of Art and Design` },
          { text: `Columbus State University`, id: `Columbus State University` },
          { text: `Columbus University`, id: `Columbus University` },
          { text: `Community College of Denver`, id: `Community College of Denver` },
          { text: `Concord College`, id: `Concord College` },
          { text: `Concordia College, Ann Arbor`, id: `Concordia College, Ann Arbor` },
          { text: `Concordia College, Bronxville`, id: `Concordia College, Bronxville` },
          { text: `Concordia College, Moorhead`, id: `Concordia College, Moorhead` },
          { text: `Concordia College, Selma`, id: `Concordia College, Selma` },
          { text: `Concordia College, Seward`, id: `Concordia College, Seward` },
          { text: `Concordia College, St. Paul`, id: `Concordia College, St. Paul` },
          { text: `Concordia University, Austin`, id: `Concordia University, Austin` },
          { text: `Concordia University, Irvine`, id: `Concordia University, Irvine` },
          { text: `Concordia University, Mequon`, id: `Concordia University, Mequon` },
          { text: `Concordia University, Portland`, id: `Concordia University, Portland` },
          { text: `Concordia University, River Forest`, id: `Concordia University, River Forest` },
          { text: `Connecticut College`, id: `Connecticut College` },
          { text: `Converse College`, id: `Converse College` },
          { text: `Conway School of Landscape Design`, id: `Conway School of Landscape Design` },
          { text: `Coppin State College`, id: `Coppin State College` },
          { text: `Cornell College`, id: `Cornell College` },
          { text: `Cornell University`, id: `Cornell University` },
          { text: `Cornish College of the Arts`, id: `Cornish College of the Arts` },
          { text: `Cosmopolitan University`, id: `Cosmopolitan University` },
          { text: `Covenant College`, id: `Covenant College` },
          { text: `Cranbrook Academy of Art`, id: `Cranbrook Academy of Art` },
          { text: `Creighton University`, id: `Creighton University` },
          { text: `Crichton College`, id: `Crichton College` },
          { text: `Crown College`, id: `Crown College` },
          { text: `Culver-Stockton College`, id: `Culver-Stockton College` },
          { text: `Cumberland College`, id: `Cumberland College` },
          { text: `Cumberland University`, id: `Cumberland University` },
          { text: `Curry College`, id: `Curry College` },
          { text: `Daemen College`, id: `Daemen College` },
          { text: `Dakota State University`, id: `Dakota State University` },
          { text: `Dakota Wesleyan University`, id: `Dakota Wesleyan University` },
          { text: `Dallas Baptist University`, id: `Dallas Baptist University` },
          { text: `Dallas Christian College`, id: `Dallas Christian College` },
          { text: `Dana College`, id: `Dana College` },
          { text: `Daniel Webster College`, id: `Daniel Webster College` },
          { text: `Danville Area Community College`, id: `Danville Area Community College` },
          { text: `Dartmouth College`, id: `Dartmouth College` },
          { text: `Darton College`, id: `Darton College` },
          { text: `Davenport College of Business, Grand Rapids`, id: `Davenport College of Business, Grand Rapids` },
          { text: `Davenport College of Business, Kalamazoo`, id: `Davenport College of Business, Kalamazoo` },
          { text: `Davenport College of Business, Lansing`, id: `Davenport College of Business, Lansing` },
          { text: `Davidson College`, id: `Davidson College` },
          { text: `Davis and Elkins College`, id: `Davis and Elkins College` },
          { text: `Deaconess College of Nursing`, id: `Deaconess College of Nursing` },
          { text: `Delaware State University`, id: `Delaware State University` },
          { text: `Delaware Valley College`, id: `Delaware Valley College` },
          { text: `Delta International University`, id: `Delta International University` },
          { text: `Delta State University`, id: `Delta State University` },
          { text: `Denison University`, id: `Denison University` },
          { text: `Denver Paralegal Institute`, id: `Denver Paralegal Institute` },
          { text: `Denver Technical College`, id: `Denver Technical College` },
          { text: `DePaul University`, id: `DePaul University` },
          { text: `DePauw University`, id: `DePauw University` },
          { text: `DeSales University`, id: `DeSales University` },
          { text: `Design Institute of San Diego`, id: `Design Institute of San Diego` },
          { text: `Detroit College of Business`, id: `Detroit College of Business` },
          { text: `Detroit College of Business - Flint`, id: `Detroit College of Business - Flint` },
          { text: `Detroit College of Business - Warren`, id: `Detroit College of Business - Warren` },
          { text: `Detroit College of Law`, id: `Detroit College of Law` },
          { text: `DeVry Institute of Technology, Chicago`, id: `DeVry Institute of Technology, Chicago` },
          { text: `DeVry Institute of Technology, Columbus`, id: `DeVry Institute of Technology, Columbus` },
          { text: `DeVry Institute of Technology, Decatur`, id: `DeVry Institute of Technology, Decatur` },
          { text: `DeVry Institute of Technology, DuPage`, id: `DeVry Institute of Technology, DuPage` },
          { text: `DeVry Institute of Technology, Irving`, id: `DeVry Institute of Technology, Irving` },
          { text: `DeVry Institute of Technology, Kansas City`, id: `DeVry Institute of Technology, Kansas City` },
          { text: `DeVry Institute of Technology, Phoenix`, id: `DeVry Institute of Technology, Phoenix` },
          { text: `DeVry Institute of Technology, Pomona`, id: `DeVry Institute of Technology, Pomona` },
          { text: `Dickinson College`, id: `Dickinson College` },
          { text: `Dickinson State University`, id: `Dickinson State University` },
          { text: `Dillard University`, id: `Dillard University` },
          { text: `Divine Word College`, id: `Divine Word College` },
          { text: `Dixie College`, id: `Dixie College` },
          { text: `Doane College`, id: `Doane College` },
          { text: `Dominican College`, id: `Dominican College` },
          { text: `Dominican College of San Rafael`, id: `Dominican College of San Rafael` },
          { text: `Dominican School of Philosophy and Theology`, id: `Dominican School of Philosophy and Theology` },
          { text: `Dominican University`, id: `Dominican University` },
          { text: `Dominion College`, id: `Dominion College` },
          { text: `Dordt College`, id: `Dordt College` },
          { text: `Dowling College`, id: `Dowling College` },
          { text: `Drake University`, id: `Drake University` },
          { text: `Drew University`, id: `Drew University` },
          { text: `Drexel University`, id: `Drexel University` },
          { text: `Drury College`, id: `Drury College` },
          { text: `Duke University`, id: `Duke University` },
          { text: `Duluth Business University`, id: `Duluth Business University` },
          { text: `Duquesne University`, id: `Duquesne University` },
          { text: `D'Youville College`, id: `D'Youville College` },
          { text: `Earlham College`, id: `Earlham College` },
          { text: `EarthNet Institute`, id: `EarthNet Institute` },
          { text: `East Carolina University`, id: `East Carolina University` },
          { text: `East Central University`, id: `East Central University` },
          { text: `Eastern College`, id: `Eastern College` },
          { text: `Eastern Connecticut State University`, id: `Eastern Connecticut State University` },
          { text: `Eastern Conservatory of Music`, id: `Eastern Conservatory of Music` },
          { text: `Eastern Illinois University`, id: `Eastern Illinois University` },
          { text: `Eastern Kentucky University`, id: `Eastern Kentucky University` },
          { text: `Eastern Mennonite University`, id: `Eastern Mennonite University` },
          { text: `Eastern Michigan University`, id: `Eastern Michigan University` },
          { text: `Eastern Nazarene College`, id: `Eastern Nazarene College` },
          { text: `Eastern New Mexico University`, id: `Eastern New Mexico University` },
          { text: `Eastern Oregon University`, id: `Eastern Oregon University` },
          { text: `Eastern Virginia Medical School`, id: `Eastern Virginia Medical School` },
          { text: `Eastern Washington University`, id: `Eastern Washington University` },
          { text: `East Stroudsburg State University`, id: `East Stroudsburg State University` },
          { text: `East Tennessee State University`, id: `East Tennessee State University` },
          { text: `East Texas Baptist University`, id: `East Texas Baptist University` },
          { text: `East-West University`, id: `East-West University` },
          { text: `Eckerd College`, id: `Eckerd College` },
          { text: `Edgewood College`, id: `Edgewood College` },
          { text: `Edinboro University of Pennsylvania`, id: `Edinboro University of Pennsylvania` },
          { text: `Edison Community College`, id: `Edison Community College` },
          { text: `Edward Waters College`, id: `Edward Waters College` },
          { text: `Elizabeth City State University`, id: `Elizabeth City State University` },
          { text: `Elizabethtown College`, id: `Elizabethtown College` },
          { text: `Elmhurst College`, id: `Elmhurst College` },
          { text: `Elmira College`, id: `Elmira College` },
          { text: `Elms College`, id: `Elms College` },
          { text: `Elon College`, id: `Elon College` },
          { text: `Embry-Riddle Aeronautical University`, id: `Embry-Riddle Aeronautical University` },
          { text: `Embry Riddle Aeronautical University, Prescott`, id: `Embry Riddle Aeronautical University, Prescott` },
          { text: `Emerson College`, id: `Emerson College` },
          { text: `Emmanuel College`, id: `Emmanuel College` },
          { text: `Emmanuel College Georgia`, id: `Emmanuel College Georgia` },
          { text: `Emmaus Bible College`, id: `Emmaus Bible College` },
          { text: `Emory & Henry College`, id: `Emory & Henry College` },
          { text: `Emory University`, id: `Emory University` },
          { text: `Emporia State University`, id: `Emporia State University` },
          { text: `Erskine College`, id: `Erskine College` },
          { text: `Eugene Bible College`, id: `Eugene Bible College` },
          { text: `Eureka College`, id: `Eureka College` },
          { text: `Evangel University`, id: `Evangel University` },
          { text: `Evergreen State College`, id: `Evergreen State College` },
          { text: `Excel College`, id: `Excel College` },
          { text: `Excelsior College`, id: `Excelsior College` },
          { text: `Fairfield University`, id: `Fairfield University` },
          { text: `Fairleigh Dickinson University, Florham-Madison Campus`, id: `Fairleigh Dickinson University, Florham-Madison Campus` },
          { text: `Fairleigh Dickinson University, Teaneck-Hackensack Campus`, id: `Fairleigh Dickinson University, Teaneck-Hackensack Campus` },
          { text: `Fairmont State College`, id: `Fairmont State College` },
          { text: `Fashion Institute of New York`, id: `Fashion Institute of New York` },
          { text: `Faulkner University`, id: `Faulkner University` },
          { text: `Fayetteville State University`, id: `Fayetteville State University` },
          { text: `Felician College`, id: `Felician College` },
          { text: `Ferris State University`, id: `Ferris State University` },
          { text: `Ferrum College`, id: `Ferrum College` },
          { text: `Fielding Institute`, id: `Fielding Institute` },
          { text: `Finch University of Health Sciences - The Chicago Medical School`, id: `Finch University of Health Sciences - The Chicago Medical School` },
          { text: `Finlandia University`, id: `Finlandia University` },
          { text: `Fisher College`, id: `Fisher College` },
          { text: `Fisk University`, id: `Fisk University` },
          { text: `Fitchburg State College`, id: `Fitchburg State College` },
          { text: `Flagler College`, id: `Flagler College` },
          { text: `Florida Agricultural and Mechanical University`, id: `Florida Agricultural and Mechanical University` },
          { text: `Florida Atlantic University`, id: `Florida Atlantic University` },
          { text: `Florida Christian College`, id: `Florida Christian College` },
          { text: `Florida Community College at Jacksonville`, id: `Florida Community College at Jacksonville` },
          { text: `Florida Gulf Coast University`, id: `Florida Gulf Coast University` },
          { text: `Florida Institute of Technology`, id: `Florida Institute of Technology` },
          { text: `Florida International University`, id: `Florida International University` },
          { text: `Florida Memorial College`, id: `Florida Memorial College` },
          { text: `Florida Metropolitan University, Fort Lauderdale College`, id: `Florida Metropolitan University, Fort Lauderdale College` },
          { text: `Florida Metropolitan University, Tampa College`, id: `Florida Metropolitan University, Tampa College` },
          { text: `Florida Metropolitan University, Tampa College Brandon`, id: `Florida Metropolitan University, Tampa College Brandon` },
          { text: `Florida Metropolitan University, Tampa College Lakeland`, id: `Florida Metropolitan University, Tampa College Lakeland` },
          { text: `Florida Southern College`, id: `Florida Southern College` },
          { text: `Florida State University`, id: `Florida State University` },
          { text: `Florida University of Medicine`, id: `Florida University of Medicine` },
          { text: `Fontbonne College`, id: `Fontbonne College` },
          { text: `Foothill-De Anza Community College District`, id: `Foothill-De Anza Community College District` },
          { text: `Fordham University`, id: `Fordham University` },
          { text: `Forest Institute of Professional Psychology`, id: `Forest Institute of Professional Psychology` },
          { text: `Fort Hays State University`, id: `Fort Hays State University` },
          { text: `Fort Lewis College`, id: `Fort Lewis College` },
          { text: `Fort Valley State College`, id: `Fort Valley State College` },
          { text: `Framingham State College`, id: `Framingham State College` },
          { text: `Franciscan School of Theology`, id: `Franciscan School of Theology` },
          { text: `Franciscan University of Steubenville`, id: `Franciscan University of Steubenville` },
          { text: `Francis Marion University`, id: `Francis Marion University` },
          { text: `Franklin and Marshall College`, id: `Franklin and Marshall College` },
          { text: `Franklin College`, id: `Franklin College` },
          { text: `Franklin Pierce College`, id: `Franklin Pierce College` },
          { text: `Franklin Pierce Law Center`, id: `Franklin Pierce Law Center` },
          { text: `Franklin University`, id: `Franklin University` },
          { text: `Franklin W. Olin College of Engineering`, id: `Franklin W. Olin College of Engineering` },
          { text: `Frank Lloyd Wright School of Architecture`, id: `Frank Lloyd Wright School of Architecture` },
          { text: `Fred Hutchinson Cancer Research Center`, id: `Fred Hutchinson Cancer Research Center` },
          { text: `Freed-Hardeman University`, id: `Freed-Hardeman University` },
          { text: `Free University (Virginia Theological University)`, id: `Free University (Virginia Theological University)` },
          { text: `Free Will Baptist Bible College`, id: `Free Will Baptist Bible College` },
          { text: `Fresno City College`, id: `Fresno City College` },
          { text: `Fresno Pacific University`, id: `Fresno Pacific University` },
          { text: `Friends University`, id: `Friends University` },
          { text: `Frostburg State University`, id: `Frostburg State University` },
          { text: `Full Sail University`, id: `Full Sail University` },
          { text: `Furman University`, id: `Furman University` },
          { text: `Gallaudet University`, id: `Gallaudet University` },
          { text: `Gannon University`, id: `Gannon University` },
          { text: `Gardner Webb University`, id: `Gardner Webb University` },
          { text: `Gemological Institute of America`, id: `Gemological Institute of America` },
          { text: `Geneva College`, id: `Geneva College` },
          { text: `George Fox University`, id: `George Fox University` },
          { text: `George Mason University`, id: `George Mason University` },
          { text: `Georgetown College`, id: `Georgetown College` },
          { text: `Georgetown University`, id: `Georgetown University` },
          { text: `George Washington University`, id: `George Washington University` },
          { text: `George Wythe College`, id: `George Wythe College` },
          { text: `Georgia Baptist College of Nursing`, id: `Georgia Baptist College of Nursing` },
          { text: `Georgia College & State University`, id: `Georgia College & State University` },
          { text: `Georgia Health Sciences University`, id: `Georgia Health Sciences University` },
          { text: `Georgia Institute of Technology`, id: `Georgia Institute of Technology` },
          { text: `Georgian Court College`, id: `Georgian Court College` },
          { text: `Georgia School of Professional Psychology`, id: `Georgia School of Professional Psychology` },
          { text: `Georgia Southern University`, id: `Georgia Southern University` },
          { text: `Georgia Southwestern University`, id: `Georgia Southwestern University` },
          { text: `Georgia State University`, id: `Georgia State University` },
          { text: `Gettysburg College`, id: `Gettysburg College` },
          { text: `Glendale University College of Law`, id: `Glendale University College of Law` },
          { text: `Glenville State College`, id: `Glenville State College` },
          { text: `Goddard College`, id: `Goddard College` },
          { text: `God's Bible School and College`, id: `God's Bible School and College` },
          { text: `Golden Gate University`, id: `Golden Gate University` },
          { text: `Goldey-Beacom College`, id: `Goldey-Beacom College` },
          { text: `Gonzaga University`, id: `Gonzaga University` },
          { text: `Gordon College`, id: `Gordon College` },
          { text: `Gordon Conwell Theological Seminary`, id: `Gordon Conwell Theological Seminary` },
          { text: `Goshen College`, id: `Goshen College` },
          { text: `Goucher College`, id: `Goucher College` },
          { text: `Governors State University`, id: `Governors State University` },
          { text: `Grace Bible College`, id: `Grace Bible College` },
          { text: `Grace College`, id: `Grace College` },
          { text: `Graceland College`, id: `Graceland College` },
          { text: `Grace University`, id: `Grace University` },
          { text: `Graduate Theological Union`, id: `Graduate Theological Union` },
          { text: `Grambling State University`, id: `Grambling State University` },
          { text: `Grand Canyon University`, id: `Grand Canyon University` },
          { text: `Grand Valley State University`, id: `Grand Valley State University` },
          { text: `Grand View College`, id: `Grand View College` },
          { text: `Grantham University`, id: `Grantham University` },
          { text: `Gratz College`, id: `Gratz College` },
          { text: `Great Lakes Christian College`, id: `Great Lakes Christian College` },
          { text: `Green Mountain College`, id: `Green Mountain College` },
          { text: `Greensboro College`, id: `Greensboro College` },
          { text: `Greenville College`, id: `Greenville College` },
          { text: `Grinnell College`, id: `Grinnell College` },
          { text: `Grove City College`, id: `Grove City College` },
          { text: `Guilford College`, id: `Guilford College` },
          { text: `Gustavus Adolphus College`, id: `Gustavus Adolphus College` },
          { text: `Gwynedd-Mercy College`, id: `Gwynedd-Mercy College` },
          { text: `Hagerstown Community College`, id: `Hagerstown Community College` },
          { text: `Hamilton College`, id: `Hamilton College` },
          { text: `Hamilton Technical College`, id: `Hamilton Technical College` },
          { text: `Hamline University`, id: `Hamline University` },
          { text: `Hampden-Sydney College`, id: `Hampden-Sydney College` },
          { text: `Hampshire College`, id: `Hampshire College` },
          { text: `Hampton College`, id: `Hampton College` },
          { text: `Hampton University`, id: `Hampton University` },
          { text: `Hannibal-Lagrange College`, id: `Hannibal-Lagrange College` },
          { text: `Hanover College`, id: `Hanover College` },
          { text: `Harding University`, id: `Harding University` },
          { text: `Harding University Graduate School of Religion`, id: `Harding University Graduate School of Religion` },
          { text: `Hardin-Simmons University`, id: `Hardin-Simmons University` },
          { text: `Harrington Institute of Interior Design`, id: `Harrington Institute of Interior Design` },
          { text: `Harris-Stowe State University`, id: `Harris-Stowe State University` },
          { text: `Hartford College for Women`, id: `Hartford College for Women` },
          { text: `Hartford Graduate Center (Rensselaer at Hartford)`, id: `Hartford Graduate Center (Rensselaer at Hartford)` },
          { text: `Hartwick College`, id: `Hartwick College` },
          { text: `Harvard University`, id: `Harvard University` },
          { text: `Harvey Mudd College`, id: `Harvey Mudd College` },
          { text: `Hastings College`, id: `Hastings College` },
          { text: `Haverford College`, id: `Haverford College` },
          { text: `Hawaii Pacific University`, id: `Hawaii Pacific University` },
          { text: `Heartland Baptist Bible College`, id: `Heartland Baptist Bible College` },
          { text: `Hebrew College`, id: `Hebrew College` },
          { text: `Heidelberg College`, id: `Heidelberg College` },
          { text: `Henderson State Univerisity`, id: `Henderson State Univerisity` },
          { text: `Hendrix College`, id: `Hendrix College` },
          { text: `Heritage College`, id: `Heritage College` },
          { text: `Hesser College`, id: `Hesser College` },
          { text: `High Point University`, id: `High Point University` },
          { text: `Hilbert College`, id: `Hilbert College` },
          { text: `Hillsdale College`, id: `Hillsdale College` },
          { text: `Hiram College`, id: `Hiram College` },
          { text: `Hobart and William Smith Colleges`, id: `Hobart and William Smith Colleges` },
          { text: `Hobe Sound Bible College`, id: `Hobe Sound Bible College` },
          { text: `Hodges University`, id: `Hodges University` },
          { text: `Hofstra University`, id: `Hofstra University` },
          { text: `Hollins University`, id: `Hollins University` },
          { text: `Holy Apostles College`, id: `Holy Apostles College` },
          { text: `Holy Cross College`, id: `Holy Cross College` },
          { text: `Holy Family College`, id: `Holy Family College` },
          { text: `Holy Names College`, id: `Holy Names College` },
          { text: `Hood College`, id: `Hood College` },
          { text: `Hope College`, id: `Hope College` },
          { text: `Hope International University`, id: `Hope International University` },
          { text: `Houghton College`, id: `Houghton College` },
          { text: `Houston Baptist University`, id: `Houston Baptist University` },
          { text: `Howard Payne University`, id: `Howard Payne University` },
          { text: `Howard University`, id: `Howard University` },
          { text: `Humboldt State University`, id: `Humboldt State University` },
          { text: `Humphreys College`, id: `Humphreys College` },
          { text: `Huntington College`, id: `Huntington College` },
          { text: `Huron University`, id: `Huron University` },
          { text: `Husson College`, id: `Husson College` },
          { text: `Huston-Tillotson College`, id: `Huston-Tillotson College` },
          { text: `ICI University`, id: `ICI University` },
          { text: `ICT College`, id: `ICT College` },
          { text: `Idaho State University`, id: `Idaho State University` },
          { text: `Iglobal University`, id: `Iglobal University` },
          { text: `Illinois Benedictine University`, id: `Illinois Benedictine University` },
          { text: `Illinois College`, id: `Illinois College` },
          { text: `Illinois College of Optometry`, id: `Illinois College of Optometry` },
          { text: `Illinois Institute of Technology`, id: `Illinois Institute of Technology` },
          { text: `Illinois School of Professional Psychology - Chicago Campus`, id: `Illinois School of Professional Psychology - Chicago Campus` },
          { text: `Illinois School of Professional Psychology - Meadows Campus`, id: `Illinois School of Professional Psychology - Meadows Campus` },
          { text: `Illinois State University`, id: `Illinois State University` },
          { text: `Illinois Valley Community College`, id: `Illinois Valley Community College` },
          { text: `Illinois Wesleyan University`, id: `Illinois Wesleyan University` },
          { text: `Immaculata University`, id: `Immaculata University` },
          { text: `IMPAC University`, id: `IMPAC University` },
          { text: `Indiana Institute of Technologyy`, id: `Indiana Institute of Technologyy` },
          { text: `Indiana State University`, id: `Indiana State University` },
          { text: `Indiana University at Bloomington`, id: `Indiana University at Bloomington` },
          { text: `Indiana University at Kokomo`, id: `Indiana University at Kokomo` },
          { text: `Indiana University at South Bend`, id: `Indiana University at South Bend` },
          { text: `Indiana University - East`, id: `Indiana University - East` },
          { text: `Indiana University - Northwest`, id: `Indiana University - Northwest` },
          { text: `Indiana University of Pennsylvania`, id: `Indiana University of Pennsylvania` },
          { text: `Indiana University-Purdue University at Columbus`, id: `Indiana University-Purdue University at Columbus` },
          { text: `Indiana University-Purdue University at Fort Wayne`, id: `Indiana University-Purdue University at Fort Wayne` },
          { text: `Indiana University-Purdue University at Indianapolis`, id: `Indiana University-Purdue University at Indianapolis` },
          { text: `Indiana University - Southeast`, id: `Indiana University - Southeast` },
          { text: `Indiana University (System)`, id: `Indiana University (System)` },
          { text: `Indiana Wesleyan University`, id: `Indiana Wesleyan University` },
          { text: `Institute of Clinical Social Work`, id: `Institute of Clinical Social Work` },
          { text: `Institute of Paper Science and Technology`, id: `Institute of Paper Science and Technology` },
          { text: `Institute of Textile Technology`, id: `Institute of Textile Technology` },
          { text: `Institute of Transpersonal Psychology`, id: `Institute of Transpersonal Psychology` },
          { text: `IntelliTec College - Grand Junction`, id: `IntelliTec College - Grand Junction` },
          { text: `International Academy of  Merchandising and Design Chicago`, id: `International Academy of  Merchandising and Design Chicago` },
          { text: `International Academy of  Merchandising and Design Tampa`, id: `International Academy of  Merchandising and Design Tampa` },
          { text: `International Bible College`, id: `International Bible College` },
          { text: `International College`, id: `International College` },
          { text: `Iona College`, id: `Iona College` },
          { text: `Iowa State University of Science and Technology`, id: `Iowa State University of Science and Technology` },
          { text: `Iowa Wesleyan College`, id: `Iowa Wesleyan College` },
          { text: `Ithaca College`, id: `Ithaca College` },
          { text: `ITT Technical Institute Fort Wayne`, id: `ITT Technical Institute Fort Wayne` },
          { text: `ITT Technical Institute Indianapolis`, id: `ITT Technical Institute Indianapolis` },
          { text: `ITT Technical Institute Maitland`, id: `ITT Technical Institute Maitland` },
          { text: `ITT Technical Institute Portland`, id: `ITT Technical Institute Portland` },
          { text: `ITT Technical Institute West Covina`, id: `ITT Technical Institute West Covina` },
          { text: `Jackson State University`, id: `Jackson State University` },
          { text: `Jacksonville State University`, id: `Jacksonville State University` },
          { text: `Jacksonville University`, id: `Jacksonville University` },
          { text: `James Madison University`, id: `James Madison University` },
          { text: `Jamestown College`, id: `Jamestown College` },
          { text: `Jarvis Christian College`, id: `Jarvis Christian College` },
          { text: `John Brown University`, id: `John Brown University` },
          { text: `John Carroll University`, id: `John Carroll University` },
          { text: `John F. Kennedy University`, id: `John F. Kennedy University` },
          { text: `John Marshall Law School`, id: `John Marshall Law School` },
          { text: `John Paul the Great Catholic University`, id: `John Paul the Great Catholic University` },
          { text: `Johns Hopkins University`, id: `Johns Hopkins University` },
          { text: `Johnson Bible College`, id: `Johnson Bible College` },
          { text: `Johnson County Community College`, id: `Johnson County Community College` },
          { text: `Johnson C. Smith University`, id: `Johnson C. Smith University` },
          { text: `Johnson State College`, id: `Johnson State College` },
          { text: `Johnson & Wales University`, id: `Johnson & Wales University` },
          { text: `Johnson & Wales University, Charleston`, id: `Johnson & Wales University, Charleston` },
          { text: `John Wesley College`, id: `John Wesley College` },
          { text: `Jones College`, id: `Jones College` },
          { text: `Jones International University`, id: `Jones International University` },
          { text: `Judson College Elgin`, id: `Judson College Elgin` },
          { text: `Judson College Marion`, id: `Judson College Marion` },
          { text: `Juniata College`, id: `Juniata College` },
          { text: `Kalamazoo College`, id: `Kalamazoo College` },
          { text: `Kankakee Community College`, id: `Kankakee Community College` },
          { text: `Kansas City Art Institute`, id: `Kansas City Art Institute` },
          { text: `Kansas State University`, id: `Kansas State University` },
          { text: `Kansas Wesleyan University`, id: `Kansas Wesleyan University` },
          { text: `Kaplan University`, id: `Kaplan University` },
          { text: `Katharine Gibbs School`, id: `Katharine Gibbs School` },
          { text: `Kean University of New Jersey`, id: `Kean University of New Jersey` },
          { text: `Keck Graduate Institute of Applied Life Sciences`, id: `Keck Graduate Institute of Applied Life Sciences` },
          { text: `Keene State College`, id: `Keene State College` },
          { text: `Keller Graduate School of Management`, id: `Keller Graduate School of Management` },
          { text: `Kendall College`, id: `Kendall College` },
          { text: `Kendall College of Art and Design`, id: `Kendall College of Art and Design` },
          { text: `Kennesaw State University`, id: `Kennesaw State University` },
          { text: `Kent State University`, id: `Kent State University` },
          { text: `Kent State University - Ashtabula`, id: `Kent State University - Ashtabula` },
          { text: `Kent State University - East Liverpool`, id: `Kent State University - East Liverpool` },
          { text: `Kent State University - Salem`, id: `Kent State University - Salem` },
          { text: `Kent State University - Stark`, id: `Kent State University - Stark` },
          { text: `Kent State University - Trumbull`, id: `Kent State University - Trumbull` },
          { text: `Kent State University - Tuscarawas`, id: `Kent State University - Tuscarawas` },
          { text: `Kentucky Christian College`, id: `Kentucky Christian College` },
          { text: `Kentucky State University`, id: `Kentucky State University` },
          { text: `Kentucky Wesleyan College`, id: `Kentucky Wesleyan College` },
          { text: `Kenyon College`, id: `Kenyon College` },
          { text: `Kettering University (GMI)`, id: `Kettering University (GMI)` },
          { text: `Keuka College`, id: `Keuka College` },
          { text: `King College`, id: `King College` },
          { text: `King's College`, id: `King's College` },
          { text: `Kirksville College of Osteopathic Medicine`, id: `Kirksville College of Osteopathic Medicine` },
          { text: `Kirkwood Community College`, id: `Kirkwood Community College` },
          { text: `Knox College`, id: `Knox College` },
          { text: `Knoxville College`, id: `Knoxville College` },
          { text: `Kutztown University of Pennsylvania`, id: `Kutztown University of Pennsylvania` },
          { text: `Laboratory Institute of Merchandising`, id: `Laboratory Institute of Merchandising` },
          { text: `Lafayette College`, id: `Lafayette College` },
          { text: `Lagrange College`, id: `Lagrange College` },
          { text: `Lake Erie College`, id: `Lake Erie College` },
          { text: `Lake Forest College`, id: `Lake Forest College` },
          { text: `Lake Forest Graduate School of Management`, id: `Lake Forest Graduate School of Management` },
          { text: `Lakeland College`, id: `Lakeland College` },
          { text: `Lake Superior State University`, id: `Lake Superior State University` },
          { text: `Lakeview College of Nursing`, id: `Lakeview College of Nursing` },
          { text: `Lamar University`, id: `Lamar University` },
          { text: `Lamar University - Port Arthur`, id: `Lamar University - Port Arthur` },
          { text: `Lambuth University`, id: `Lambuth University` },
          { text: `Lancaster Bible College`, id: `Lancaster Bible College` },
          { text: `Lander University`, id: `Lander University` },
          { text: `Lane College`, id: `Lane College` },
          { text: `Langston University`, id: `Langston University` },
          { text: `La Roche College`, id: `La Roche College` },
          { text: `La Salle University`, id: `La Salle University` },
          { text: `Lasell College`, id: `Lasell College` },
          { text: `La Sierra University`, id: `La Sierra University` },
          { text: `Laurus Technical Institute`, id: `Laurus Technical Institute` },
          { text: `Lawrence Technological University`, id: `Lawrence Technological University` },
          { text: `Lawrence University`, id: `Lawrence University` },
          { text: `Lebanon Valley College`, id: `Lebanon Valley College` },
          { text: `Lees-McRae College`, id: `Lees-McRae College` },
          { text: `Lee University`, id: `Lee University` },
          { text: `Lehigh Univervsity`, id: `Lehigh Univervsity` },
          { text: `Le Moyne College`, id: `Le Moyne College` },
          { text: `Le Moyne-Owen College`, id: `Le Moyne-Owen College` },
          { text: `Lenoir-Rhyne College`, id: `Lenoir-Rhyne College` },
          { text: `Lesley University`, id: `Lesley University` },
          { text: `Letourneau University`, id: `Letourneau University` },
          { text: `Lewis and Clark College`, id: `Lewis and Clark College` },
          { text: `Lewis & Clark Community College`, id: `Lewis & Clark Community College` },
          { text: `Lewis-Clark State College`, id: `Lewis-Clark State College` },
          { text: `Lewis University`, id: `Lewis University` },
          { text: `Liberty University`, id: `Liberty University` },
          { text: `Life Chiropractic College West`, id: `Life Chiropractic College West` },
          { text: `Life University`, id: `Life University` },
          { text: `Limestone College`, id: `Limestone College` },
          { text: `Lincoln Memorial University`, id: `Lincoln Memorial University` },
          { text: `Lincoln University Missouri`, id: `Lincoln University Missouri` },
          { text: `Lincoln University Pennsylvania`, id: `Lincoln University Pennsylvania` },
          { text: `Lincoln University San Francisco`, id: `Lincoln University San Francisco` },
          { text: `Lindenwood University`, id: `Lindenwood University` },
          { text: `Lindsey Wilson College`, id: `Lindsey Wilson College` },
          { text: `Linfield College`, id: `Linfield College` },
          { text: `Lipscomb University`, id: `Lipscomb University` },
          { text: `Livingstone College`, id: `Livingstone College` },
          { text: `Lock Haven University of Pennsylvania`, id: `Lock Haven University of Pennsylvania` },
          { text: `Logan College of Chiropractic`, id: `Logan College of Chiropractic` },
          { text: `Loma Linda University`, id: `Loma Linda University` },
          { text: `Long Island University`, id: `Long Island University` },
          { text: `Long Island University, C.W. Post Campus`, id: `Long Island University, C.W. Post Campus` },
          { text: `Long Island University, Southampton College`, id: `Long Island University, Southampton College` },
          { text: `Longwood College`, id: `Longwood College` },
          { text: `Loras College`, id: `Loras College` },
          { text: `Los Angeles College of Chiropractic`, id: `Los Angeles College of Chiropractic` },
          { text: `Louisiana Baptist University`, id: `Louisiana Baptist University` },
          { text: `Louisiana College`, id: `Louisiana College` },
          { text: `Louisiana State University and Agricultural and Mechanical College`, id: `Louisiana State University and Agricultural and Mechanical College` },
          { text: `Louisiana State University at Alexandria`, id: `Louisiana State University at Alexandria` },
          { text: `Louisiana State University at Eunice`, id: `Louisiana State University at Eunice` },
          { text: `Louisiana State University Health Sciences Center New Orleans`, id: `Louisiana State University Health Sciences Center New Orleans` },
          { text: `Louisiana State University in Shreveport`, id: `Louisiana State University in Shreveport` },
          { text: `Louisiana Tech University`, id: `Louisiana Tech University` },
          { text: `Lourdes College`, id: `Lourdes College` },
          { text: `Loyola College in Maryland`, id: `Loyola College in Maryland` },
          { text: `Loyola Marymount University`, id: `Loyola Marymount University` },
          { text: `Loyola University New Orleans`, id: `Loyola University New Orleans` },
          { text: `Loyola University of Chicago`, id: `Loyola University of Chicago` },
          { text: `Lubbock Christian University`, id: `Lubbock Christian University` },
          { text: `Lutheran Bible Institute of Seattle`, id: `Lutheran Bible Institute of Seattle` },
          { text: `Luther College`, id: `Luther College` },
          { text: `Lycoming College`, id: `Lycoming College` },
          { text: `Lynchburg College`, id: `Lynchburg College` },
          { text: `Lyndon State College`, id: `Lyndon State College` },
          { text: `Lynn University`, id: `Lynn University` },
          { text: `Lyon College`, id: `Lyon College` },
          { text: `Macalester College`, id: `Macalester College` },
          { text: `MacMurray College`, id: `MacMurray College` },
          { text: `Macon State College`, id: `Macon State College` },
          { text: `Madison University (Distance Education)`, id: `Madison University (Distance Education)` },
          { text: `Madonna University`, id: `Madonna University` },
          { text: `Maharishi University of Management`, id: `Maharishi University of Management` },
          { text: `Maine College of Art`, id: `Maine College of Art` },
          { text: `Maine Maritime Academy`, id: `Maine Maritime Academy` },
          { text: `Malone College`, id: `Malone College` },
          { text: `Manchester College`, id: `Manchester College` },
          { text: `Manhattan Christian College`, id: `Manhattan Christian College` },
          { text: `Manhattan College`, id: `Manhattan College` },
          { text: `Manhattan School of Music`, id: `Manhattan School of Music` },
          { text: `Manhattanville College`, id: `Manhattanville College` },
          { text: `Mankato State University`, id: `Mankato State University` },
          { text: `Mansfield University of Pennsylvania`, id: `Mansfield University of Pennsylvania` },
          { text: `Maranatha Baptist Bible College`, id: `Maranatha Baptist Bible College` },
          { text: `Marian College`, id: `Marian College` },
          { text: `Marian College of Fond Du Lac`, id: `Marian College of Fond Du Lac` },
          { text: `Marietta College`, id: `Marietta College` },
          { text: `Marist College`, id: `Marist College` },
          { text: `Marlboro College`, id: `Marlboro College` },
          { text: `Marquette University`, id: `Marquette University` },
          { text: `Marshall University`, id: `Marshall University` },
          { text: `Mars Hill College`, id: `Mars Hill College` },
          { text: `Martin Luther College`, id: `Martin Luther College` },
          { text: `Martin Methodist College`, id: `Martin Methodist College` },
          { text: `Martin University`, id: `Martin University` },
          { text: `Mary Baldwin College`, id: `Mary Baldwin College` },
          { text: `Marycrest International University`, id: `Marycrest International University` },
          { text: `Marygrove College`, id: `Marygrove College` },
          { text: `Marylhurst University`, id: `Marylhurst University` },
          { text: `Marymount College New York`, id: `Marymount College New York` },
          { text: `Marymount Manhattan College`, id: `Marymount Manhattan College` },
          { text: `Marymount University`, id: `Marymount University` },
          { text: `Maryville College`, id: `Maryville College` },
          { text: `Maryville University of St. Louis`, id: `Maryville University of St. Louis` },
          { text: `Mary Washington College`, id: `Mary Washington College` },
          { text: `Marywood University`, id: `Marywood University` },
          { text: `Massachusetts College of Art`, id: `Massachusetts College of Art` },
          { text: `Massachusetts College of Liberal Arts`, id: `Massachusetts College of Liberal Arts` },
          { text: `Massachusetts College of Pharmacy and Allied Health Sciences`, id: `Massachusetts College of Pharmacy and Allied Health Sciences` },
          { text: `Massachusetts Institute of Technology`, id: `Massachusetts Institute of Technology` },
          { text: `Massachusetts Maritime Academy`, id: `Massachusetts Maritime Academy` },
          { text: `Massachusetts School of Professional Psychology`, id: `Massachusetts School of Professional Psychology` },
          { text: `Mayo Graduate School`, id: `Mayo Graduate School` },
          { text: `Mayo Medical School`, id: `Mayo Medical School` },
          { text: `Mayville State University`, id: `Mayville State University` },
          { text: `McKendree College`, id: `McKendree College` },
          { text: `McMurry University`, id: `McMurry University` },
          { text: `McNeese State University`, id: `McNeese State University` },
          { text: `McPherson College`, id: `McPherson College` },
          { text: `Medaille College`, id: `Medaille College` },
          { text: `Medcenter One College of Nursing`, id: `Medcenter One College of Nursing` },
          { text: `Medical College of Georgia`, id: `Medical College of Georgia` },
          { text: `Medical College of Ohio`, id: `Medical College of Ohio` },
          { text: `Medical College of Pennsylvania and Hahnemann University`, id: `Medical College of Pennsylvania and Hahnemann University` },
          { text: `Medical College of Wisconsin`, id: `Medical College of Wisconsin` },
          { text: `Medical University of South Carolina`, id: `Medical University of South Carolina` },
          { text: `Meharry Medical College`, id: `Meharry Medical College` },
          { text: `Memphis College of Art`, id: `Memphis College of Art` },
          { text: `Menlo College`, id: `Menlo College` },
          { text: `Mennonite College of Nursing`, id: `Mennonite College of Nursing` },
          { text: `Mercer University`, id: `Mercer University` },
          { text: `Mercer University, Cecil B. Day Campus`, id: `Mercer University, Cecil B. Day Campus` },
          { text: `Mercy College`, id: `Mercy College` },
          { text: `Mercyhurst College`, id: `Mercyhurst College` },
          { text: `Meredith College`, id: `Meredith College` },
          { text: `Merrimack College`, id: `Merrimack College` },
          { text: `Mesa State College`, id: `Mesa State College` },
          { text: `Messiah College`, id: `Messiah College` },
          { text: `Methodist College`, id: `Methodist College` },
          { text: `Metropolitan State College of Denver`, id: `Metropolitan State College of Denver` },
          { text: `Metropolitan State University`, id: `Metropolitan State University` },
          { text: `MGH Institute of Health Professions`, id: `MGH Institute of Health Professions` },
          { text: `Miami Dade College`, id: `Miami Dade College` },
          { text: `Miami University of Ohio`, id: `Miami University of Ohio` },
          { text: `Miami University of Ohio - Hamilton`, id: `Miami University of Ohio - Hamilton` },
          { text: `Miami University of Ohio - Middletown`, id: `Miami University of Ohio - Middletown` },
          { text: `Michigan School of Professional Psychology`, id: `Michigan School of Professional Psychology` },
          { text: `Michigan State University`, id: `Michigan State University` },
          { text: `Michigan Technological University`, id: `Michigan Technological University` },
          { text: `Mid-America Nazarene University`, id: `Mid-America Nazarene University` },
          { text: `Mid-American Bible College`, id: `Mid-American Bible College` },
          { text: `Mid-Continent Baptist Bible College`, id: `Mid-Continent Baptist Bible College` },
          { text: `Middlebury College`, id: `Middlebury College` },
          { text: `Middle Tennessee State University`, id: `Middle Tennessee State University` },
          { text: `Midland Lutheran College`, id: `Midland Lutheran College` },
          { text: `Midway College`, id: `Midway College` },
          { text: `Midwestern State University`, id: `Midwestern State University` },
          { text: `Midwestern University`, id: `Midwestern University` },
          { text: `Miles College`, id: `Miles College` },
          { text: `Millennia Atlantic University`, id: `Millennia Atlantic University` },
          { text: `Millersville University of Pennsylvania`, id: `Millersville University of Pennsylvania` },
          { text: `Milligan College`, id: `Milligan College` },
          { text: `Millikin University`, id: `Millikin University` },
          { text: `Millsaps College`, id: `Millsaps College` },
          { text: `Mills College`, id: `Mills College` },
          { text: `Mills Grae University`, id: `Mills Grae University` },
          { text: `Milwaukee Institute of Art and Design`, id: `Milwaukee Institute of Art and Design` },
          { text: `Milwaukee School of Engineering`, id: `Milwaukee School of Engineering` },
          { text: `Minneapolis College of Art and Design`, id: `Minneapolis College of Art and Design` },
          { text: `Minnesota Bible College`, id: `Minnesota Bible College` },
          { text: `Minnesota School of Professional Psychology`, id: `Minnesota School of Professional Psychology` },
          { text: `Minot State University`, id: `Minot State University` },
          { text: `Mississippi College`, id: `Mississippi College` },
          { text: `Mississippi State University`, id: `Mississippi State University` },
          { text: `Mississippi University for Women`, id: `Mississippi University for Women` },
          { text: `Mississippi Valley State University`, id: `Mississippi Valley State University` },
          { text: `Missouri Baptist College`, id: `Missouri Baptist College` },
          { text: `Missouri Southern State College`, id: `Missouri Southern State College` },
          { text: `Missouri Tech`, id: `Missouri Tech` },
          { text: `Missouri University of Science and Technology`, id: `Missouri University of Science and Technology` },
          { text: `Missouri Valley College`, id: `Missouri Valley College` },
          { text: `Missouri Western State College`, id: `Missouri Western State College` },
          { text: `Molloy College`, id: `Molloy College` },
          { text: `Monmouth University`, id: `Monmouth University` },
          { text: `Montana State University`, id: `Montana State University` },
          { text: `Montana State University - Billings`, id: `Montana State University - Billings` },
          { text: `Montana State University - Northern`, id: `Montana State University - Northern` },
          { text: `Montana Tech`, id: `Montana Tech` },
          { text: `Montclair State University`, id: `Montclair State University` },
          { text: `Monterey Institute of International Studies`, id: `Monterey Institute of International Studies` },
          { text: `Montreat College`, id: `Montreat College` },
          { text: `Montserrat College of Art`, id: `Montserrat College of Art` },
          { text: `Moody Bible Institute`, id: `Moody Bible Institute` },
          { text: `Moore College of Art and Design`, id: `Moore College of Art and Design` },
          { text: `Moorhead State University`, id: `Moorhead State University` },
          { text: `Moraine Valley Community College`, id: `Moraine Valley Community College` },
          { text: `Moravian College`, id: `Moravian College` },
          { text: `Morehead State University`, id: `Morehead State University` },
          { text: `Morehouse College`, id: `Morehouse College` },
          { text: `Morehouse School of Medicine`, id: `Morehouse School of Medicine` },
          { text: `Morgan State University`, id: `Morgan State University` },
          { text: `Morningside College`, id: `Morningside College` },
          { text: `Morris Brown College`, id: `Morris Brown College` },
          { text: `Morris College`, id: `Morris College` },
          { text: `Morrison College`, id: `Morrison College` },
          { text: `Mountain State University`, id: `Mountain State University` },
          { text: `Mount Aloysius College`, id: `Mount Aloysius College` },
          { text: `Mount Carmel College of Nursing`, id: `Mount Carmel College of Nursing` },
          { text: `Mount Holyoke College`, id: `Mount Holyoke College` },
          { text: `Mount Ida College`, id: `Mount Ida College` },
          { text: `Mount Marty College`, id: `Mount Marty College` },
          { text: `Mount Mary College`, id: `Mount Mary College` },
          { text: `Mount Mercy College`, id: `Mount Mercy College` },
          { text: `Mount Olive College`, id: `Mount Olive College` },
          { text: `Mount Senario College`, id: `Mount Senario College` },
          { text: `Mount Sinai School of Medicine`, id: `Mount Sinai School of Medicine` },
          { text: `Mount St. Clare College`, id: `Mount St. Clare College` },
          { text: `Mount St. Mary College`, id: `Mount St. Mary College` },
          { text: `Mount St. Mary's College California`, id: `Mount St. Mary's College California` },
          { text: `Mount St. Mary's College Maryland`, id: `Mount St. Mary's College Maryland` },
          { text: `Mount Union College`, id: `Mount Union College` },
          { text: `Mount Vernon College`, id: `Mount Vernon College` },
          { text: `Mount Vernon Nazarene College`, id: `Mount Vernon Nazarene College` },
          { text: `Muhlenberg College`, id: `Muhlenberg College` },
          { text: `Multnomah Bible College`, id: `Multnomah Bible College` },
          { text: `Murray State University`, id: `Murray State University` },
          { text: `Muskingum College`, id: `Muskingum College` },
          { text: `NAES College`, id: `NAES College` },
          { text: `National American University`, id: `National American University` },
          { text: `National American University, Albuquerque`, id: `National American University, Albuquerque` },
          { text: `National American University, Colorado Springs`, id: `National American University, Colorado Springs` },
          { text: `National American University, Denver`, id: `National American University, Denver` },
          { text: `National American University, Kansas City`, id: `National American University, Kansas City` },
          { text: `National American University, Roseville`, id: `National American University, Roseville` },
          { text: `National American University, Sioux Falls`, id: `National American University, Sioux Falls` },
          { text: `National College of Chiropractic`, id: `National College of Chiropractic` },
          { text: `National College of Naturopathic Medicine`, id: `National College of Naturopathic Medicine` },
          { text: `National Defense University`, id: `National Defense University` },
          { text: `National Hispanic University`, id: `National Hispanic University` },
          { text: `National-Louis University`, id: `National-Louis University` },
          { text: `National Technological University`, id: `National Technological University` },
          { text: `National Theatre Conservatory`, id: `National Theatre Conservatory` },
          { text: `National University`, id: `National University` },
          { text: `Naval Postgraduate School`, id: `Naval Postgraduate School` },
          { text: `Nazarene Bible College`, id: `Nazarene Bible College` },
          { text: `Nazareth College`, id: `Nazareth College` },
          { text: `Nebraska Christian College`, id: `Nebraska Christian College` },
          { text: `Nebraska Methodist College of Nursing and Allied Health`, id: `Nebraska Methodist College of Nursing and Allied Health` },
          { text: `Nebraska Wesleyan University`, id: `Nebraska Wesleyan University` },
          { text: `Neumann College`, id: `Neumann College` },
          { text: `Newberry College`, id: `Newberry College` },
          { text: `New College of California`, id: `New College of California` },
          { text: `New College of Florida`, id: `New College of Florida` },
          { text: `New England College`, id: `New England College` },
          { text: `New England College of Optometry`, id: `New England College of Optometry` },
          { text: `New England Conservatory of Music`, id: `New England Conservatory of Music` },
          { text: `New England Institute of Technology`, id: `New England Institute of Technology` },
          { text: `New England School of Art and Design`, id: `New England School of Art and Design` },
          { text: `New England School of Communications`, id: `New England School of Communications` },
          { text: `New England School of Law`, id: `New England School of Law` },
          { text: `New Hampshire College`, id: `New Hampshire College` },
          { text: `New Jersey City University`, id: `New Jersey City University` },
          { text: `New Jersey Institute of Technology`, id: `New Jersey Institute of Technology` },
          { text: `Newman University`, id: `Newman University` },
          { text: `New Mexico Highlands University`, id: `New Mexico Highlands University` },
          { text: `New Mexico Institute of Mining and Technology`, id: `New Mexico Institute of Mining and Technology` },
          { text: `New Mexico State University`, id: `New Mexico State University` },
          { text: `Newschool of Architecture and Design`, id: `Newschool of Architecture and Design` },
          { text: `New York Academy of Art, Graduate School of Figurative Art`, id: `New York Academy of Art, Graduate School of Figurative Art` },
          { text: `New York Chiropractic College`, id: `New York Chiropractic College` },
          { text: `New York College of Podiatric Medicine`, id: `New York College of Podiatric Medicine` },
          { text: `New York Film Academy`, id: `New York Film Academy` },
          { text: `New York Institute of Technology`, id: `New York Institute of Technology` },
          { text: `New York Law School`, id: `New York Law School` },
          { text: `New York Medical College`, id: `New York Medical College` },
          { text: `New York School of Interior Design`, id: `New York School of Interior Design` },
          { text: `New York University`, id: `New York University` },
          { text: `Niagara University`, id: `Niagara University` },
          { text: `Nicholls State University`, id: `Nicholls State University` },
          { text: `Nichols College`, id: `Nichols College` },
          { text: `Norfolk State University`, id: `Norfolk State University` },
          { text: `North Carolina Agricultural and Technical State University`, id: `North Carolina Agricultural and Technical State University` },
          { text: `North Carolina Central University`, id: `North Carolina Central University` },
          { text: `North Carolina School of the Arts`, id: `North Carolina School of the Arts` },
          { text: `North Carolina State University`, id: `North Carolina State University` },
          { text: `North Carolina Wesleyan College`, id: `North Carolina Wesleyan College` },
          { text: `North Central Bible College`, id: `North Central Bible College` },
          { text: `North Central College`, id: `North Central College` },
          { text: `Northcentral University`, id: `Northcentral University` },
          { text: `North Dakota State University`, id: `North Dakota State University` },
          { text: `Northeastern Illinois University`, id: `Northeastern Illinois University` },
          { text: `Northeastern Ohio University College of Medicine`, id: `Northeastern Ohio University College of Medicine` },
          { text: `Northeastern State University`, id: `Northeastern State University` },
          { text: `Northeastern University`, id: `Northeastern University` },
          { text: `Northern Arizona University`, id: `Northern Arizona University` },
          { text: `Northern Illinois University`, id: `Northern Illinois University` },
          { text: `Northern Kentucky University`, id: `Northern Kentucky University` },
          { text: `Northern Michigan University`, id: `Northern Michigan University` },
          { text: `Northern State University`, id: `Northern State University` },
          { text: `Northern Virginia Community College`, id: `Northern Virginia Community College` },
          { text: `Northface University`, id: `Northface University` },
          { text: `North Georgia College`, id: `North Georgia College` },
          { text: `North Greenville College`, id: `North Greenville College` },
          { text: `Northland College`, id: `Northland College` },
          { text: `North Park University`, id: `North Park University` },
          { text: `Northwest Christian College`, id: `Northwest Christian College` },
          { text: `Northwest College of Art`, id: `Northwest College of Art` },
          { text: `Northwestern College Iowa`, id: `Northwestern College Iowa` },
          { text: `Northwestern College of Chiropractic`, id: `Northwestern College of Chiropractic` },
          { text: `Northwestern College St. Paul`, id: `Northwestern College St. Paul` },
          { text: `Northwestern Oklahoma State University`, id: `Northwestern Oklahoma State University` },
          { text: `Northwestern State University of Louisiana`, id: `Northwestern State University of Louisiana` },
          { text: `Northwestern University`, id: `Northwestern University` },
          { text: `Northwest Missouri State University`, id: `Northwest Missouri State University` },
          { text: `Northwest Nazarene University`, id: `Northwest Nazarene University` },
          { text: `Northwest University`, id: `Northwest University` },
          { text: `Northwood University`, id: `Northwood University` },
          { text: `Northwood University, Florida Campus`, id: `Northwood University, Florida Campus` },
          { text: `Northwood University, Texas Campus`, id: `Northwood University, Texas Campus` },
          { text: `Norwich University`, id: `Norwich University` },
          { text: `Notre Dame College`, id: `Notre Dame College` },
          { text: `Notre Dame College of Ohio`, id: `Notre Dame College of Ohio` },
          { text: `Notre Dame de Namur University`, id: `Notre Dame de Namur University` },
          { text: `Nova Southeastern University`, id: `Nova Southeastern University` },
          { text: `Nyack College`, id: `Nyack College` },
          { text: `Oakland City University`, id: `Oakland City University` },
          { text: `Oakland University`, id: `Oakland University` },
          { text: `Oakton Community College`, id: `Oakton Community College` },
          { text: `Oakwood College`, id: `Oakwood College` },
          { text: `Oberlin College`, id: `Oberlin College` },
          { text: `Occidental College`, id: `Occidental College` },
          { text: `Oglala Lakota College`, id: `Oglala Lakota College` },
          { text: `Oglethorpe University`, id: `Oglethorpe University` },
          { text: `Ohio College of Podiatric Medicine`, id: `Ohio College of Podiatric Medicine` },
          { text: `Ohio Dominican College`, id: `Ohio Dominican College` },
          { text: `Ohio Northern University`, id: `Ohio Northern University` },
          { text: `Ohio State University`, id: `Ohio State University` },
          { text: `Ohio State University - Lima`, id: `Ohio State University - Lima` },
          { text: `Ohio State University - Mansfield`, id: `Ohio State University - Mansfield` },
          { text: `Ohio State University - Marion`, id: `Ohio State University - Marion` },
          { text: `Ohio State University - Newark`, id: `Ohio State University - Newark` },
          { text: `Ohio University`, id: `Ohio University` },
          { text: `Ohio University - Chillicothe`, id: `Ohio University - Chillicothe` },
          { text: `Ohio University - Eastern`, id: `Ohio University - Eastern` },
          { text: `Ohio University - Lancaster`, id: `Ohio University - Lancaster` },
          { text: `Ohio University - Southern`, id: `Ohio University - Southern` },
          { text: `Ohio University - Zanesville`, id: `Ohio University - Zanesville` },
          { text: `Ohio Valley College`, id: `Ohio Valley College` },
          { text: `Ohio Wesleyan University`, id: `Ohio Wesleyan University` },
          { text: `Oklahoma Baptist University`, id: `Oklahoma Baptist University` },
          { text: `Oklahoma Christian University`, id: `Oklahoma Christian University` },
          { text: `Oklahoma City University`, id: `Oklahoma City University` },
          { text: `Oklahoma Panhandle State University`, id: `Oklahoma Panhandle State University` },
          { text: `Oklahoma State University`, id: `Oklahoma State University` },
          { text: `Oklahoma State University Center for Health Sciences`, id: `Oklahoma State University Center for Health Sciences` },
          { text: `Oklahoma State University - Institute of Technology`, id: `Oklahoma State University - Institute of Technology` },
          { text: `Oklahoma State University - Oklahoma City`, id: `Oklahoma State University - Oklahoma City` },
          { text: `Oklahoma State University - Tulsa`, id: `Oklahoma State University - Tulsa` },
          { text: `Old Dominion University`, id: `Old Dominion University` },
          { text: `Olive-Harvey College`, id: `Olive-Harvey College` },
          { text: `Olivet College`, id: `Olivet College` },
          { text: `Olivet Nazarene University`, id: `Olivet Nazarene University` },
          { text: `O'More College of Design`, id: `O'More College of Design` },
          { text: `Oral Roberts University`, id: `Oral Roberts University` },
          { text: `Oregon College of Arts and Crafts`, id: `Oregon College of Arts and Crafts` },
          { text: `Oregon Graduate Institute of Science and Technology`, id: `Oregon Graduate Institute of Science and Technology` },
          { text: `Oregon Health Sciences University`, id: `Oregon Health Sciences University` },
          { text: `Oregon Institute of Technology`, id: `Oregon Institute of Technology` },
          { text: `Oregon State University`, id: `Oregon State University` },
          { text: `Otis College of Art & Design`, id: `Otis College of Art & Design` },
          { text: `Ottawa University`, id: `Ottawa University` },
          { text: `Otterbein College`, id: `Otterbein College` },
          { text: `Ouachita Baptist University`, id: `Ouachita Baptist University` },
          { text: `Our Lady of Holy Cross College`, id: `Our Lady of Holy Cross College` },
          { text: `Our Lady of the Lake University`, id: `Our Lady of the Lake University` },
          { text: `Ozark Christian College`, id: `Ozark Christian College` },
          { text: `Pace University`, id: `Pace University` },
          { text: `Pace University  Pleasantville/Briarcliff`, id: `Pace University  Pleasantville/Briarcliff` },
          { text: `Pacifica Graduate Institute`, id: `Pacifica Graduate Institute` },
          { text: `Pacific College of Oriental Medicine`, id: `Pacific College of Oriental Medicine` },
          { text: `Pacific Graduate School of Psychology`, id: `Pacific Graduate School of Psychology` },
          { text: `Pacific Lutheran University`, id: `Pacific Lutheran University` },
          { text: `Pacific Northwest College of Art`, id: `Pacific Northwest College of Art` },
          { text: `Pacific Oaks College`, id: `Pacific Oaks College` },
          { text: `Pacific Union College`, id: `Pacific Union College` },
          { text: `Pacific University`, id: `Pacific University` },
          { text: `Paier College of Art`, id: `Paier College of Art` },
          { text: `Paine College`, id: `Paine College` },
          { text: `Palm Beach Atlantic University`, id: `Palm Beach Atlantic University` },
          { text: `Palm Beach State College`, id: `Palm Beach State College` },
          { text: `Palmer College of Chiropractic`, id: `Palmer College of Chiropractic` },
          { text: `Palmer College of Chiropractic West`, id: `Palmer College of Chiropractic West` },
          { text: `Park College`, id: `Park College` },
          { text: `Parsons School of Design`, id: `Parsons School of Design` },
          { text: `Paul Quinn College`, id: `Paul Quinn College` },
          { text: `Peace College`, id: `Peace College` },
          { text: `Pebble Hills University`, id: `Pebble Hills University` },
          { text: `Pennsylvania Academy of the Fine Arts`, id: `Pennsylvania Academy of the Fine Arts` },
          { text: `Pennsylvania College of Optometry`, id: `Pennsylvania College of Optometry` },
          { text: `Pennsylvania Institute of Technology`, id: `Pennsylvania Institute of Technology` },
          { text: `Pennsylvania State University`, id: `Pennsylvania State University` },
          { text: `Pennsylvania State University - Abington`, id: `Pennsylvania State University - Abington` },
          { text: `Pennsylvania State University - Altoona`, id: `Pennsylvania State University - Altoona` },
          { text: `Pennsylvania State University at Erie - Behrend College`, id: `Pennsylvania State University at Erie - Behrend College` },
          { text: `Pennsylvania State University at Harrisburg - The Capital College`, id: `Pennsylvania State University at Harrisburg - The Capital College` },
          { text: `Pennsylvania State University - Berks-Lehigh Valley College`, id: `Pennsylvania State University - Berks-Lehigh Valley College` },
          { text: `Pennsylvania State University Delaware County`, id: `Pennsylvania State University Delaware County` },
          { text: `Pennsylvania State University Great Valley`, id: `Pennsylvania State University Great Valley` },
          { text: `Pennsylvania State University - Lehigh Valley`, id: `Pennsylvania State University - Lehigh Valley` },
          { text: `Pennsylvania State University - Milton S.Hershey Medical Center`, id: `Pennsylvania State University - Milton S.Hershey Medical Center` },
          { text: `Pennsylvania State University - Schuylkill`, id: `Pennsylvania State University - Schuylkill` },
          { text: `Pepperdine University`, id: `Pepperdine University` },
          { text: `Peru State College`, id: `Peru State College` },
          { text: `Pfeiffer University`, id: `Pfeiffer University` },
          { text: `Philadelphia College of Bible`, id: `Philadelphia College of Bible` },
          { text: `Philadelphia College of Osteopathic Medicine`, id: `Philadelphia College of Osteopathic Medicine` },
          { text: `Philadelphia University`, id: `Philadelphia University` },
          { text: `Philander Smith College`, id: `Philander Smith College` },
          { text: `Phillips Graduate Institute`, id: `Phillips Graduate Institute` },
          { text: `Phillips University`, id: `Phillips University` },
          { text: `Piedmont Baptist College`, id: `Piedmont Baptist College` },
          { text: `Piedmont College`, id: `Piedmont College` },
          { text: `Pikeville College`, id: `Pikeville College` },
          { text: `Pillsbury Baptist Bible College`, id: `Pillsbury Baptist Bible College` },
          { text: `Pittsburg State University`, id: `Pittsburg State University` },
          { text: `Pitzer College`, id: `Pitzer College` },
          { text: `Plymouth State College`, id: `Plymouth State College` },
          { text: `Point Loma Nazarene College`, id: `Point Loma Nazarene College` },
          { text: `Point Park College`, id: `Point Park College` },
          { text: `Polytechnic University`, id: `Polytechnic University` },
          { text: `Polytechnic University, Long Island Campus`, id: `Polytechnic University, Long Island Campus` },
          { text: `Polytechnic University, Westchester Graduate Center`, id: `Polytechnic University, Westchester Graduate Center` },
          { text: `Pomona College`, id: `Pomona College` },
          { text: `Portland Community College`, id: `Portland Community College` },
          { text: `Portland State University`, id: `Portland State University` },
          { text: `Post University of Waterbury`, id: `Post University of Waterbury` },
          { text: `Prairie View Agricultural and Mechanical University`, id: `Prairie View Agricultural and Mechanical University` },
          { text: `Pratt Institute`, id: `Pratt Institute` },
          { text: `Presbyterian College`, id: `Presbyterian College` },
          { text: `Prescott College`, id: `Prescott College` },
          { text: `Preston University`, id: `Preston University` },
          { text: `Princeton University`, id: `Princeton University` },
          { text: `Principia College`, id: `Principia College` },
          { text: `Providence College`, id: `Providence College` },
          { text: `Puget Sound Christian College`, id: `Puget Sound Christian College` },
          { text: `Purdue University`, id: `Purdue University` },
          { text: `Purdue University Calumet`, id: `Purdue University Calumet` },
          { text: `Purdue University North Central`, id: `Purdue University North Central` },
          { text: `Quantum-Veritas International University`, id: `Quantum-Veritas International University` },
          { text: `Queens College`, id: `Queens College` },
          { text: `Quincy University`, id: `Quincy University` },
          { text: `Quinnipiac College`, id: `Quinnipiac College` },
          { text: `Radford University`, id: `Radford University` },
          { text: `Ramapo College of New Jersey`, id: `Ramapo College of New Jersey` },
          { text: `Rand Graduate School of Policy Studies`, id: `Rand Graduate School of Policy Studies` },
          { text: `Randolph-Macon College`, id: `Randolph-Macon College` },
          { text: `Randolph-Macon Woman's College`, id: `Randolph-Macon Woman's College` },
          { text: `Rasmussen College`, id: `Rasmussen College` },
          { text: `Rasmussen College, Florida Campuses`, id: `Rasmussen College, Florida Campuses` },
          { text: `Rasmussen College, Illinois Campuses`, id: `Rasmussen College, Illinois Campuses` },
          { text: `Rasmussen College, Minnesota Campuses`, id: `Rasmussen College, Minnesota Campuses` },
          { text: `Rasmussen College, North Dakota Campuses`, id: `Rasmussen College, North Dakota Campuses` },
          { text: `Rasmussen College, Wisconsin Campuses`, id: `Rasmussen College, Wisconsin Campuses` },
          { text: `Reed College`, id: `Reed College` },
          { text: `Reformed Bible College`, id: `Reformed Bible College` },
          { text: `Regent International University`, id: `Regent International University` },
          { text: `Regent University`, id: `Regent University` },
          { text: `Regis College`, id: `Regis College` },
          { text: `Regis University`, id: `Regis University` },
          { text: `Reinhardt College`, id: `Reinhardt College` },
          { text: `Rensselaer Polytechnic Institute`, id: `Rensselaer Polytechnic Institute` },
          { text: `Research College of Nursing - Rockhurst University`, id: `Research College of Nursing - Rockhurst University` },
          { text: `Rhode Island College`, id: `Rhode Island College` },
          { text: `Rhode Island School of Design`, id: `Rhode Island School of Design` },
          { text: `Rhodes College`, id: `Rhodes College` },
          { text: `Rice University`, id: `Rice University` },
          { text: `Richard Stockton College of New Jersey`, id: `Richard Stockton College of New Jersey` },
          { text: `Rider University`, id: `Rider University` },
          { text: `Ringling College of Art and Design`, id: `Ringling College of Art and Design` },
          { text: `Ripon College`, id: `Ripon College` },
          { text: `Rivier College`, id: `Rivier College` },
          { text: `Roanoke Bible College`, id: `Roanoke Bible College` },
          { text: `Roanoke College`, id: `Roanoke College` },
          { text: `Robert Morris College`, id: `Robert Morris College` },
          { text: `Robert Morris College of Chicago`, id: `Robert Morris College of Chicago` },
          { text: `Roberts Wesleyan College`, id: `Roberts Wesleyan College` },
          { text: `Rochester College`, id: `Rochester College` },
          { text: `Rochester Institute of Technology`, id: `Rochester Institute of Technology` },
          { text: `Rockford College`, id: `Rockford College` },
          { text: `Rockhurst College`, id: `Rockhurst College` },
          { text: `Rock Valley College`, id: `Rock Valley College` },
          { text: `Rocky Mountain College`, id: `Rocky Mountain College` },
          { text: `Rocky Mountain College of Art and Design`, id: `Rocky Mountain College of Art and Design` },
          { text: `Rogers State University`, id: `Rogers State University` },
          { text: `Roger Williams University`, id: `Roger Williams University` },
          { text: `Rollins College`, id: `Rollins College` },
          { text: `Roosevelt University`, id: `Roosevelt University` },
          { text: `Rose-Hulman Institute of Technology`, id: `Rose-Hulman Institute of Technology` },
          { text: `Rosemont College`, id: `Rosemont College` },
          { text: `Ross University, School of Medicine`, id: `Ross University, School of Medicine` },
          { text: `Rowan University`, id: `Rowan University` },
          { text: `Rush University`, id: `Rush University` },
          { text: `Russel Sage College`, id: `Russel Sage College` },
          { text: `Rust College`, id: `Rust College` },
          { text: `Rutgers, The State University of New Jersey`, id: `Rutgers, The State University of New Jersey` },
          { text: `Rutgers, The State University of New Jersey - Camden`, id: `Rutgers, The State University of New Jersey - Camden` },
          { text: `Rutgers, The State University of New Jersey - Newark`, id: `Rutgers, The State University of New Jersey - Newark` },
          { text: `Sacred Heart University`, id: `Sacred Heart University` },
          { text: `Sage Graduate School`, id: `Sage Graduate School` },
          { text: `Saginaw Valley State University`, id: `Saginaw Valley State University` },
          { text: `Salem College`, id: `Salem College` },
          { text: `Salem International University`, id: `Salem International University` },
          { text: `Salem State College`, id: `Salem State College` },
          { text: `Salem Teikyo University`, id: `Salem Teikyo University` },
          { text: `Salisbury State University`, id: `Salisbury State University` },
          { text: `Salve Regina University`, id: `Salve Regina University` },
          { text: `Samford University`, id: `Samford University` },
          { text: `Sam Houston State University`, id: `Sam Houston State University` },
          { text: `Samuel Merritt College`, id: `Samuel Merritt College` },
          { text: `San Diego State University`, id: `San Diego State University` },
          { text: `San Diego University for Integrative Studies`, id: `San Diego University for Integrative Studies` },
          { text: `Sanford-Brown Institute`, id: `Sanford-Brown Institute` },
          { text: `San Francisco Art Institute`, id: `San Francisco Art Institute` },
          { text: `San Francisco Conservatory of Music`, id: `San Francisco Conservatory of Music` },
          { text: `San Francisco State University`, id: `San Francisco State University` },
          { text: `San Joaquin College of Law`, id: `San Joaquin College of Law` },
          { text: `San Jose Christian College`, id: `San Jose Christian College` },
          { text: `San Jose State University`, id: `San Jose State University` },
          { text: `Santa Clara University`, id: `Santa Clara University` },
          { text: `Sarah Lawrence College`, id: `Sarah Lawrence College` },
          { text: `Savannah College of Art and Design`, id: `Savannah College of Art and Design` },
          { text: `Savannah State University`, id: `Savannah State University` },
          { text: `Saybrook Institute`, id: `Saybrook Institute` },
          { text: `Schiller International University`, id: `Schiller International University` },
          { text: `Scholl College of Podiatric Medicine`, id: `Scholl College of Podiatric Medicine` },
          { text: `School for International Training`, id: `School for International Training` },
          { text: `School of the Museum of Fine Arts`, id: `School of the Museum of Fine Arts` },
          { text: `School of the Visual Arts`, id: `School of the Visual Arts` },
          { text: `Schreiner College`, id: `Schreiner College` },
          { text: `Scripps College`, id: `Scripps College` },
          { text: `Seattle Pacific University`, id: `Seattle Pacific University` },
          { text: `Seattle University`, id: `Seattle University` },
          { text: `Seton Hall University`, id: `Seton Hall University` },
          { text: `Seton Hill College`, id: `Seton Hill College` },
          { text: `Shawnee Community College`, id: `Shawnee Community College` },
          { text: `Shawnee State University`, id: `Shawnee State University` },
          { text: `Shaw University`, id: `Shaw University` },
          { text: `Sheldon Jackson College`, id: `Sheldon Jackson College` },
          { text: `Shenandoah University`, id: `Shenandoah University` },
          { text: `Shepherd College`, id: `Shepherd College` },
          { text: `Sherman College of Straight Chiropractic`, id: `Sherman College of Straight Chiropractic` },
          { text: `Shimer College`, id: `Shimer College` },
          { text: `Shippensburg University of Pennsylvania`, id: `Shippensburg University of Pennsylvania` },
          { text: `Shoreline Community College`, id: `Shoreline Community College` },
          { text: `Shorter College`, id: `Shorter College` },
          { text: `Siena College`, id: `Siena College` },
          { text: `Siena Heights University`, id: `Siena Heights University` },
          { text: `Sierra Nevada College`, id: `Sierra Nevada College` },
          { text: `Silver Lake College`, id: `Silver Lake College` },
          { text: `Simmons College`, id: `Simmons College` },
          { text: `Simon's Rock College`, id: `Simon's Rock College` },
          { text: `Simpson College`, id: `Simpson College` },
          { text: `Simpson College Iowa`, id: `Simpson College Iowa` },
          { text: `Sinte Gleska University`, id: `Sinte Gleska University` },
          { text: `Skadron College`, id: `Skadron College` },
          { text: `Skidmore College`, id: `Skidmore College` },
          { text: `Slippery Rock University`, id: `Slippery Rock University` },
          { text: `Smith College`, id: `Smith College` },
          { text: `Sojourner-Douglass College`, id: `Sojourner-Douglass College` },
          { text: `Soka University of America`, id: `Soka University of America` },
          { text: `Sonoma State University`, id: `Sonoma State University` },
          { text: `South Carolina State University`, id: `South Carolina State University` },
          { text: `South Dakota School of Mines and Technology`, id: `South Dakota School of Mines and Technology` },
          { text: `South Dakota State University`, id: `South Dakota State University` },
          { text: `Southeastern Baptist College`, id: `Southeastern Baptist College` },
          { text: `Southeastern Bible College`, id: `Southeastern Bible College` },
          { text: `Southeastern College of the Assemblies of God`, id: `Southeastern College of the Assemblies of God` },
          { text: `Southeastern Louisiana University`, id: `Southeastern Louisiana University` },
          { text: `Southeastern Oklahoma State University`, id: `Southeastern Oklahoma State University` },
          { text: `Southeastern University`, id: `Southeastern University` },
          { text: `Southeast Missouri State University`, id: `Southeast Missouri State University` },
          { text: `Southern Adventist University`, id: `Southern Adventist University` },
          { text: `Southern Arkansas University`, id: `Southern Arkansas University` },
          { text: `Southern California College`, id: `Southern California College` },
          { text: `Southern California College of Optometry`, id: `Southern California College of Optometry` },
          { text: `Southern California Institute of Architecture`, id: `Southern California Institute of Architecture` },
          { text: `Southern College of Optometry`, id: `Southern College of Optometry` },
          { text: `Southern Connecticut State University`, id: `Southern Connecticut State University` },
          { text: `Southern Illinois University at Carbondale`, id: `Southern Illinois University at Carbondale` },
          { text: `Southern Illinois University at Edwardsville`, id: `Southern Illinois University at Edwardsville` },
          { text: `Southern Methodist University`, id: `Southern Methodist University` },
          { text: `Southern Nazarene University`, id: `Southern Nazarene University` },
          { text: `Southern New Hampshire University`, id: `Southern New Hampshire University` },
          { text: `Southern Oregon University`, id: `Southern Oregon University` },
          { text: `Southern Polytechnic State Univerisity`, id: `Southern Polytechnic State Univerisity` },
          { text: `Southern University - Baton Rouge`, id: `Southern University - Baton Rouge` },
          { text: `Southern University - New Orleans`, id: `Southern University - New Orleans` },
          { text: `Southern University - Shreveport`, id: `Southern University - Shreveport` },
          { text: `Southern Utah University`, id: `Southern Utah University` },
          { text: `Southern Vermont College`, id: `Southern Vermont College` },
          { text: `Southern Wesleyan University`, id: `Southern Wesleyan University` },
          { text: `South Florida Bible College & Theological Seminary`, id: `South Florida Bible College & Theological Seminary` },
          { text: `South Texas College of Law`, id: `South Texas College of Law` },
          { text: `Southwest Baptist University`, id: `Southwest Baptist University` },
          { text: `Southwestern Adventist University`, id: `Southwestern Adventist University` },
          { text: `Southwestern Assemblies of God University`, id: `Southwestern Assemblies of God University` },
          { text: `Southwestern Baptist Theological Seminary`, id: `Southwestern Baptist Theological Seminary` },
          { text: `Southwestern Christian College`, id: `Southwestern Christian College` },
          { text: `Southwestern Christian University`, id: `Southwestern Christian University` },
          { text: `Southwestern College Kansas`, id: `Southwestern College Kansas` },
          { text: `Southwestern College Santa Fe`, id: `Southwestern College Santa Fe` },
          { text: `Southwestern Oklahoma State University`, id: `Southwestern Oklahoma State University` },
          { text: `Southwestern University`, id: `Southwestern University` },
          { text: `Southwestern University School of Law`, id: `Southwestern University School of Law` },
          { text: `Southwest Missouri State University`, id: `Southwest Missouri State University` },
          { text: `Southwest Missouri State University - West Plains`, id: `Southwest Missouri State University - West Plains` },
          { text: `Southwest State University`, id: `Southwest State University` },
          { text: `Southwest Texas State University`, id: `Southwest Texas State University` },
          { text: `Southwest University`, id: `Southwest University` },
          { text: `Spalding University`, id: `Spalding University` },
          { text: `Spelman College`, id: `Spelman College` },
          { text: `Spertus Institute of Jewish Studies`, id: `Spertus Institute of Jewish Studies` },
          { text: `Spring Arbor College`, id: `Spring Arbor College` },
          { text: `Springfield College`, id: `Springfield College` },
          { text: `Spring Hill College`, id: `Spring Hill College` },
          { text: `St. Ambrose University`, id: `St. Ambrose University` },
          { text: `Standford Online University`, id: `Standford Online University` },
          { text: `Standford University`, id: `Standford University` },
          { text: `St. Andrews Presbyterian College`, id: `St. Andrews Presbyterian College` },
          { text: `Stanford University`, id: `Stanford University` },
          { text: `St. Anselm College`, id: `St. Anselm College` },
          { text: `St. Anthony College of Nursing`, id: `St. Anthony College of Nursing` },
          { text: `State University of New York at Albany`, id: `State University of New York at Albany` },
          { text: `State University of New York at Binghamton`, id: `State University of New York at Binghamton` },
          { text: `State University of New York at Buffalo`, id: `State University of New York at Buffalo` },
          { text: `State University of New York at New Paltz`, id: `State University of New York at New Paltz` },
          { text: `State University of New York at Oswego`, id: `State University of New York at Oswego` },
          { text: `State University of New York at Stony Brook`, id: `State University of New York at Stony Brook` },
          { text: `State University of New York College at Brockport`, id: `State University of New York College at Brockport` },
          { text: `State University of New York College at Cortland`, id: `State University of New York College at Cortland` },
          { text: `State University of New York College at Fredonia`, id: `State University of New York College at Fredonia` },
          { text: `State University of New York College at Geneseo`, id: `State University of New York College at Geneseo` },
          { text: `State University of New York College at Old Westbury`, id: `State University of New York College at Old Westbury` },
          { text: `State University of New York College at Oneonta`, id: `State University of New York College at Oneonta` },
          { text: `State University of New York College at Plattsburgh`, id: `State University of New York College at Plattsburgh` },
          { text: `State University of New York College at Potsdam`, id: `State University of New York College at Potsdam` },
          { text: `State University of New York College at Purchase`, id: `State University of New York College at Purchase` },
          { text: `State University of New York College of Agriculture and Technology at Cobleskill`, id: `State University of New York College of Agriculture and Technology at Cobleskill` },
          { text: `State University of New York College of Environmental Science and Forestry`, id: `State University of New York College of Environmental Science and Forestry` },
          { text: `State University of New York College of Optometry`, id: `State University of New York College of Optometry` },
          { text: `State University of New York College of Technology at Alfred`, id: `State University of New York College of Technology at Alfred` },
          { text: `State University of New York College of Technology at Farmingdale`, id: `State University of New York College of Technology at Farmingdale` },
          { text: `State University of New York Downstate Medical Center`, id: `State University of New York Downstate Medical Center` },
          { text: `State University of New York Empire State College`, id: `State University of New York Empire State College` },
          { text: `State University of New York Health Sience Centre Syracuse`, id: `State University of New York Health Sience Centre Syracuse` },
          { text: `State University of New York Institute of Technology at Utica/Rome`, id: `State University of New York Institute of Technology at Utica/Rome` },
          { text: `State University of New York Maritime College`, id: `State University of New York Maritime College` },
          { text: `State University of New York School of Engineering and Applied Sciences`, id: `State University of New York School of Engineering and Applied Sciences` },
          { text: `State University of New York (SUNY)`, id: `State University of New York (SUNY)` },
          { text: `State University of New York Upstate Medical University `, id: `State University of New York Upstate Medical University ` },
          { text: `State University of West Georgia`, id: `State University of West Georgia` },
          { text: `St. Augustine's College North Carolina`, id: `St. Augustine's College North Carolina` },
          { text: `St. Bernard's Institute`, id: `St. Bernard's Institute` },
          { text: `St. Bonaventure University`, id: `St. Bonaventure University` },
          { text: `St. Cloud State University`, id: `St. Cloud State University` },
          { text: `St. Edwards University`, id: `St. Edwards University` },
          { text: `Stefan University`, id: `Stefan University` },
          { text: `Stephen F. Austin State University`, id: `Stephen F. Austin State University` },
          { text: `Stephens College`, id: `Stephens College` },
          { text: `Sterling College`, id: `Sterling College` },
          { text: `Stetson University`, id: `Stetson University` },
          { text: `Stevens Institute of Technology`, id: `Stevens Institute of Technology` },
          { text: `St. Francis College, Brooklyn Heights`, id: `St. Francis College, Brooklyn Heights` },
          { text: `St. Francis College, Fort Wayne`, id: `St. Francis College, Fort Wayne` },
          { text: `St. Francis College, Loretto`, id: `St. Francis College, Loretto` },
          { text: `St. Francis Medical Center College of Nursing`, id: `St. Francis Medical Center College of Nursing` },
          { text: `St. George's University`, id: `St. George's University` },
          { text: `Stillman College`, id: `Stillman College` },
          { text: `St. John Fisher College`, id: `St. John Fisher College` },
          { text: `St. John's College Maryland`, id: `St. John's College Maryland` },
          { text: `St. John's College New Mexico`, id: `St. John's College New Mexico` },
          { text: `St. John's Seminary`, id: `St. John's Seminary` },
          { text: `St. John's University`, id: `St. John's University` },
          { text: `St. Joseph College`, id: `St. Joseph College` },
          { text: `St. Joseph College of Nursing`, id: `St. Joseph College of Nursing` },
          { text: `St. Joseph's College`, id: `St. Joseph's College` },
          { text: `St. Joseph's College New York`, id: `St. Joseph's College New York` },
          { text: `St. Joseph's College New York, Suffolk Campus`, id: `St. Joseph's College New York, Suffolk Campus` },
          { text: `St. Joseph's College of Maine`, id: `St. Joseph's College of Maine` },
          { text: `St. Joseph's University`, id: `St. Joseph's University` },
          { text: `St. Lawrence University`, id: `St. Lawrence University` },
          { text: `St. Leo College`, id: `St. Leo College` },
          { text: `St. Louis Christian College`, id: `St. Louis Christian College` },
          { text: `St. Louis College of Pharmacy`, id: `St. Louis College of Pharmacy` },
          { text: `St. Louis University`, id: `St. Louis University` },
          { text: `St. Luke's College`, id: `St. Luke's College` },
          { text: `St. Martin's College`, id: `St. Martin's College` },
          { text: `St. Mary College`, id: `St. Mary College` },
          { text: `St. Mary-of-the-Woods College`, id: `St. Mary-of-the-Woods College` },
          { text: `St. Mary's College Indiana`, id: `St. Mary's College Indiana` },
          { text: `St. Mary's College of California`, id: `St. Mary's College of California` },
          { text: `St. Mary's College of Maryland`, id: `St. Mary's College of Maryland` },
          { text: `St. Mary's University of Minnesota`, id: `St. Mary's University of Minnesota` },
          { text: `St. Mary's University of San Antonio`, id: `St. Mary's University of San Antonio` },
          { text: `St. Meinrad College`, id: `St. Meinrad College` },
          { text: `St. Michael's College`, id: `St. Michael's College` },
          { text: `St. Norbert College`, id: `St. Norbert College` },
          { text: `St. Olaf College`, id: `St. Olaf College` },
          { text: `Stonehill College`, id: `Stonehill College` },
          { text: `St. Paul's College`, id: `St. Paul's College` },
          { text: `St. Petersburg College`, id: `St. Petersburg College` },
          { text: `St. Peter's College`, id: `St. Peter's College` },
          { text: `Strayer University`, id: `Strayer University` },
          { text: `St. Thomas Aquinas College`, id: `St. Thomas Aquinas College` },
          { text: `St. Thomas University`, id: `St. Thomas University` },
          { text: `St. Vincent College`, id: `St. Vincent College` },
          { text: `St. Xavier University`, id: `St. Xavier University` },
          { text: `Suffolk University`, id: `Suffolk University` },
          { text: `Sullivan College`, id: `Sullivan College` },
          { text: `Sul Ross State University`, id: `Sul Ross State University` },
          { text: `Susquehanna University`, id: `Susquehanna University` },
          { text: `Swarthmore College`, id: `Swarthmore College` },
          { text: `Sweet Briar College`, id: `Sweet Briar College` },
          { text: `Syracuse University`, id: `Syracuse University` },
          { text: `Tabor College`, id: `Tabor College` },
          { text: `Talladega College`, id: `Talladega College` },
          { text: `Tarleton State University`, id: `Tarleton State University` },
          { text: `Taylor University`, id: `Taylor University` },
          { text: `Taylor University, Fort Wayne Campus`, id: `Taylor University, Fort Wayne Campus` },
          { text: `Teachers College, Columbia University`, id: `Teachers College, Columbia University` },
          { text: `Temple University`, id: `Temple University` },
          { text: `Temple University School of Podiatric Medicine`, id: `Temple University School of Podiatric Medicine` },
          { text: `Tennessee State University`, id: `Tennessee State University` },
          { text: `Tennessee Technological University`, id: `Tennessee Technological University` },
          { text: `Tennessee Temple University`, id: `Tennessee Temple University` },
          { text: `Tennessee Wesleyan College`, id: `Tennessee Wesleyan College` },
          { text: `Texas A&M International University`, id: `Texas A&M International University` },
          { text: `Texas A&M University`, id: `Texas A&M University` },
          { text: `Texas A&M University - Commerce`, id: `Texas A&M University - Commerce` },
          { text: `Texas A&M University - Corpus Christi`, id: `Texas A&M University - Corpus Christi` },
          { text: `Texas A&M University - Galveston`, id: `Texas A&M University - Galveston` },
          { text: `Texas A&M University - Kingsville`, id: `Texas A&M University - Kingsville` },
          { text: `Texas A&M University - Texarkana`, id: `Texas A&M University - Texarkana` },
          { text: `Texas Chiropractic College`, id: `Texas Chiropractic College` },
          { text: `Texas Christian University`, id: `Texas Christian University` },
          { text: `Texas College`, id: `Texas College` },
          { text: `Texas College of Osteopathic Medicine`, id: `Texas College of Osteopathic Medicine` },
          { text: `Texas Lutheran University`, id: `Texas Lutheran University` },
          { text: `Texas Southern University`, id: `Texas Southern University` },
          { text: `Texas Tech University`, id: `Texas Tech University` },
          { text: `Texas Tech University Health Science Center`, id: `Texas Tech University Health Science Center` },
          { text: `Texas Wesleyan University`, id: `Texas Wesleyan University` },
          { text: `Texas Woman's University`, id: `Texas Woman's University` },
          { text: `The American College`, id: `The American College` },
          { text: `The Art Institute of Boston`, id: `The Art Institute of Boston` },
          { text: `The Art Institutes International Portland`, id: `The Art Institutes International Portland` },
          { text: `The Art Institutes International San Francisco`, id: `The Art Institutes International San Francisco` },
          { text: `The Boston Conservatory`, id: `The Boston Conservatory` },
          { text: `The Catholic University of America`, id: `The Catholic University of America` },
          { text: `The Chicago School of Professional Psychology`, id: `The Chicago School of Professional Psychology` },
          { text: `The College of Insurance`, id: `The College of Insurance` },
          { text: `The College of New Jersey`, id: `The College of New Jersey` },
          { text: `The College of Santa Fe`, id: `The College of Santa Fe` },
          { text: `The College of St. Scholastica`, id: `The College of St. Scholastica` },
          { text: `The College of Westchester`, id: `The College of Westchester` },
          { text: `The College of Wooster`, id: `The College of Wooster` },
          { text: `The Cooper Union for the Advancement of Science and Art`, id: `The Cooper Union for the Advancement of Science and Art` },
          { text: `The Corcoran College of Art`, id: `The Corcoran College of Art` },
          { text: `The Curtis Institute of Music`, id: `The Curtis Institute of Music` },
          { text: `The Defiance College`, id: `The Defiance College` },
          { text: `The Dickinson School of Law`, id: `The Dickinson School of Law` },
          { text: `The Illinois Institute of Art-Chicago`, id: `The Illinois Institute of Art-Chicago` },
          { text: `The Johns Hopkins University`, id: `The Johns Hopkins University` },
          { text: `The Juilliard School`, id: `The Juilliard School` },
          { text: `The Leadership Institute of Seattle`, id: `The Leadership Institute of Seattle` },
          { text: `The Maryland Institute, College of Art`, id: `The Maryland Institute, College of Art` },
          { text: `The Master's College`, id: `The Master's College` },
          { text: `The McGregor School of Antioch University`, id: `The McGregor School of Antioch University` },
          { text: `The Naropa Institute`, id: `The Naropa Institute` },
          { text: `The New School`, id: `The New School` },
          { text: `The Rockefeller University`, id: `The Rockefeller University` },
          { text: `The School of the Art Institute of Chicago`, id: `The School of the Art Institute of Chicago` },
          { text: `The Scripps Research Institute`, id: `The Scripps Research Institute` },
          { text: `The Southern Christian University`, id: `The Southern Christian University` },
          { text: `The Tulane University of New Orleans`, id: `The Tulane University of New Orleans` },
          { text: `The Union Institute`, id: `The Union Institute` },
          { text: `Thiel College`, id: `Thiel College` },
          { text: `Thomas A. Edison State College`, id: `Thomas A. Edison State College` },
          { text: `Thomas Aquinas College`, id: `Thomas Aquinas College` },
          { text: `Thomas College Maine`, id: `Thomas College Maine` },
          { text: `Thomas Jefferson University`, id: `Thomas Jefferson University` },
          { text: `Thomas More College`, id: `Thomas More College` },
          { text: `Thomas More College of Liberal Arts`, id: `Thomas More College of Liberal Arts` },
          { text: `Thomas University`, id: `Thomas University` },
          { text: `Thunderbird School of Global Management`, id: `Thunderbird School of Global Management` },
          { text: `Tiffin University`, id: `Tiffin University` },
          { text: `Toccoa Falls College`, id: `Toccoa Falls College` },
          { text: `Tomball College`, id: `Tomball College` },
          { text: `Tougaloo College`, id: `Tougaloo College` },
          { text: `Touro College`, id: `Touro College` },
          { text: `Touro University`, id: `Touro University` },
          { text: `Towson University`, id: `Towson University` },
          { text: `Transylvania University`, id: `Transylvania University` },
          { text: `Trevecca Nazarene University`, id: `Trevecca Nazarene University` },
          { text: `Tri-College University`, id: `Tri-College University` },
          { text: `Trident University`, id: `Trident University` },
          { text: `Trinity Bible College`, id: `Trinity Bible College` },
          { text: `Trinity Christian College`, id: `Trinity Christian College` },
          { text: `Trinity College Connecticut`, id: `Trinity College Connecticut` },
          { text: `Trinity College of Florida`, id: `Trinity College of Florida` },
          { text: `Trinity College of Vermont`, id: `Trinity College of Vermont` },
          { text: `Trinity International University`, id: `Trinity International University` },
          { text: `Trinity International University (Excel), Miami`, id: `Trinity International University (Excel), Miami` },
          { text: `Trinity University`, id: `Trinity University` },
          { text: `Trinity University`, id: `Trinity University` },
          { text: `Tri-State University`, id: `Tri-State University` },
          { text: `Triton College`, id: `Triton College` },
          { text: `Troy University`, id: `Troy University` },
          { text: `Troy University, Dothan`, id: `Troy University, Dothan` },
          { text: `Troy University, Montgomery`, id: `Troy University, Montgomery` },
          { text: `Troy University, Phenix City`, id: `Troy University, Phenix City` },
          { text: `Troy University, Troy`, id: `Troy University, Troy` },
          { text: `Truman College`, id: `Truman College` },
          { text: `Truman State University`, id: `Truman State University` },
          { text: `Tufts University`, id: `Tufts University` },
          { text: `Tui Online University`, id: `Tui Online University` },
          { text: `Tusculum College`, id: `Tusculum College` },
          { text: `Tuskegee University`, id: `Tuskegee University` },
          { text: `Uniformed Services Universty of the Health Sciences`, id: `Uniformed Services Universty of the Health Sciences` },
          { text: `Union College`, id: `Union College` },
          { text: `Union College Kentucky`, id: `Union College Kentucky` },
          { text: `Union College Nebraska`, id: `Union College Nebraska` },
          { text: `Union Theological Seminary (UTS)`, id: `Union Theological Seminary (UTS)` },
          { text: `Union University`, id: `Union University` },
          { text: `United States Air Force Academy`, id: `United States Air Force Academy` },
          { text: `United States Coast Guard Academy`, id: `United States Coast Guard Academy` },
          { text: `United States International University`, id: `United States International University` },
          { text: `United States Merchant Marine Academy`, id: `United States Merchant Marine Academy` },
          { text: `United States Military Academy`, id: `United States Military Academy` },
          { text: `United States Naval Academy`, id: `United States Naval Academy` },
          { text: `United States Sports Academy`, id: `United States Sports Academy` },
          { text: `Unity College`, id: `Unity College` },
          { text: `University of Advancing Technology (UAT)`, id: `University of Advancing Technology (UAT)` },
          { text: `University of Akron`, id: `University of Akron` },
          { text: `University of Alabama - Birmingham`, id: `University of Alabama - Birmingham` },
          { text: `University of Alabama - Huntsville`, id: `University of Alabama - Huntsville` },
          { text: `University of Alabama - Tuscaloosa`, id: `University of Alabama - Tuscaloosa` },
          { text: `University of Alanta`, id: `University of Alanta` },
          { text: `University of Alaska - Anchorage`, id: `University of Alaska - Anchorage` },
          { text: `University of Alaska - Fairbanks`, id: `University of Alaska - Fairbanks` },
          { text: `University of Alaska - Southeast`, id: `University of Alaska - Southeast` },
          { text: `University of Alaska (System)`, id: `University of Alaska (System)` },
          { text: `University of Arizona`, id: `University of Arizona` },
          { text: `University of Arkansas at Fayetteville`, id: `University of Arkansas at Fayetteville` },
          { text: `University of Arkansas at Little Rock`, id: `University of Arkansas at Little Rock` },
          { text: `University of Arkansas at Monticello`, id: `University of Arkansas at Monticello` },
          { text: `University of Arkansas at Pine Bluff`, id: `University of Arkansas at Pine Bluff` },
          { text: `University of Arkansas for Medical Sciences`, id: `University of Arkansas for Medical Sciences` },
          { text: `University of Arkansas (System)`, id: `University of Arkansas (System)` },
          { text: `University of Baltimore`, id: `University of Baltimore` },
          { text: `University of Bridgeport`, id: `University of Bridgeport` },
          { text: `University of California, Berkeley`, id: `University of California, Berkeley` },
          { text: `University of California, Davis`, id: `University of California, Davis` },
          { text: `University of California, Hastings College of Law`, id: `University of California, Hastings College of Law` },
          { text: `University of California, Irvine`, id: `University of California, Irvine` },
          { text: `University of California, Los Angeles`, id: `University of California, Los Angeles` },
          { text: `University of California, Merced`, id: `University of California, Merced` },
          { text: `University of California, Oakland`, id: `University of California, Oakland` },
          { text: `University of California, Riverside`, id: `University of California, Riverside` },
          { text: `University of California, San Diego`, id: `University of California, San Diego` },
          { text: `University of California, San Francisco`, id: `University of California, San Francisco` },
          { text: `University of California, Santa Barbara`, id: `University of California, Santa Barbara` },
          { text: `University of California, Santa Cruz`, id: `University of California, Santa Cruz` },
          { text: `University of California System`, id: `University of California System` },
          { text: `University of Central Arkansas`, id: `University of Central Arkansas` },
          { text: `University of Central Florida`, id: `University of Central Florida` },
          { text: `University of Central Missouri`, id: `University of Central Missouri` },
          { text: `University of Central Oklahoma`, id: `University of Central Oklahoma` },
          { text: `University of Central Texas`, id: `University of Central Texas` },
          { text: `University of Charleston`, id: `University of Charleston` },
          { text: `University of Charleston South Carolina`, id: `University of Charleston South Carolina` },
          { text: `University of Chicago`, id: `University of Chicago` },
          { text: `University of Cincinnati`, id: `University of Cincinnati` },
          { text: `University of Colorado at Boulder`, id: `University of Colorado at Boulder` },
          { text: `University of Colorado at Colorado Springs`, id: `University of Colorado at Colorado Springs` },
          { text: `University of Colorado at Denver`, id: `University of Colorado at Denver` },
          { text: `University of Colorado Health Sciences Center`, id: `University of Colorado Health Sciences Center` },
          { text: `University of Connecticut`, id: `University of Connecticut` },
          { text: `University of Connecticut at Avery Point`, id: `University of Connecticut at Avery Point` },
          { text: `University of Connecticut at Hartford`, id: `University of Connecticut at Hartford` },
          { text: `University of Connecticut at Stamford`, id: `University of Connecticut at Stamford` },
          { text: `University of Connecticut at Waterbury`, id: `University of Connecticut at Waterbury` },
          { text: `University of Connecticut Health Center`, id: `University of Connecticut Health Center` },
          { text: `University of Dallas`, id: `University of Dallas` },
          { text: `University of Dayton`, id: `University of Dayton` },
          { text: `University of Delaware`, id: `University of Delaware` },
          { text: `University of Denver`, id: `University of Denver` },
          { text: `University of Detroit Mercy`, id: `University of Detroit Mercy` },
          { text: `University of Dubuque`, id: `University of Dubuque` },
          { text: `University of Evansville`, id: `University of Evansville` },
          { text: `University of Findlay`, id: `University of Findlay` },
          { text: `University of Florida`, id: `University of Florida` },
          { text: `University of Georgia`, id: `University of Georgia` },
          { text: `University of Great Falls`, id: `University of Great Falls` },
          { text: `University of Hartford`, id: `University of Hartford` },
          { text: `University of Hawaii - Hilo`, id: `University of Hawaii - Hilo` },
          { text: `University of Hawaii - Manoa`, id: `University of Hawaii - Manoa` },
          { text: `University Of Hawaii - System`, id: `University Of Hawaii - System` },
          { text: `University of Hawaii - West Oahu`, id: `University of Hawaii - West Oahu` },
          { text: `University of Health Sciences`, id: `University of Health Sciences` },
          { text: `University of Houston`, id: `University of Houston` },
          { text: `University of Houston, Clear Lake`, id: `University of Houston, Clear Lake` },
          { text: `University of Houston, Downtown`, id: `University of Houston, Downtown` },
          { text: `University of Houston, Victoria`, id: `University of Houston, Victoria` },
          { text: `University of Idaho`, id: `University of Idaho` },
          { text: `University of Illinois`, id: `University of Illinois` },
          { text: `University of Illinois at Chicago`, id: `University of Illinois at Chicago` },
          { text: `University of Illinois at Springfield`, id: `University of Illinois at Springfield` },
          { text: `University of Illinois at Urbana-Champaign`, id: `University of Illinois at Urbana-Champaign` },
          { text: `University of Indianapolis`, id: `University of Indianapolis` },
          { text: `University of Iowa`, id: `University of Iowa` },
          { text: `University of Kansas`, id: `University of Kansas` },
          { text: `University of Kentucky`, id: `University of Kentucky` },
          { text: `University of La Verne`, id: `University of La Verne` },
          { text: `University of Louisiana at Lafayette`, id: `University of Louisiana at Lafayette` },
          { text: `University of Louisiana at Monroe`, id: `University of Louisiana at Monroe` },
          { text: `University of Louisville`, id: `University of Louisville` },
          { text: `University of Maine, Augusta`, id: `University of Maine, Augusta` },
          { text: `University of Maine, Farmington`, id: `University of Maine, Farmington` },
          { text: `University of Maine, Fort Kent`, id: `University of Maine, Fort Kent` },
          { text: `University of Maine, Machias`, id: `University of Maine, Machias` },
          { text: `University of Maine, Orono`, id: `University of Maine, Orono` },
          { text: `University of Maine, Presque Isle`, id: `University of Maine, Presque Isle` },
          { text: `University of Maine (System)`, id: `University of Maine (System)` },
          { text: `University of Management & Technology`, id: `University of Management & Technology` },
          { text: `University of Mary`, id: `University of Mary` },
          { text: `University of Mary Hardin-Baylor`, id: `University of Mary Hardin-Baylor` },
          { text: `University of Maryland at Baltimore`, id: `University of Maryland at Baltimore` },
          { text: `University of Maryland at College Park`, id: `University of Maryland at College Park` },
          { text: `University of Maryland Baltimore County`, id: `University of Maryland Baltimore County` },
          { text: `University of Maryland Eastern Shore`, id: `University of Maryland Eastern Shore` },
          { text: `University of Maryland Medicine`, id: `University of Maryland Medicine` },
          { text: `University of Maryland (System)`, id: `University of Maryland (System)` },
          { text: `University of Maryland University College`, id: `University of Maryland University College` },
          { text: `University of Massachusetts at Amherst`, id: `University of Massachusetts at Amherst` },
          { text: `University of Massachusetts at Boston`, id: `University of Massachusetts at Boston` },
          { text: `University of Massachusetts at Dartmouth`, id: `University of Massachusetts at Dartmouth` },
          { text: `University of Massachusetts at Lowell`, id: `University of Massachusetts at Lowell` },
          { text: `University of Massachusetts Medical Center at Worcester`, id: `University of Massachusetts Medical Center at Worcester` },
          { text: `University of Massachusetts (System)`, id: `University of Massachusetts (System)` },
          { text: `University of Medicine and Dentistry of New Jersey`, id: `University of Medicine and Dentistry of New Jersey` },
          { text: `University of Memphis`, id: `University of Memphis` },
          { text: `University of Miami`, id: `University of Miami` },
          { text: `University of Michigan - Ann Arbor`, id: `University of Michigan - Ann Arbor` },
          { text: `University of Michigan - Dearborn`, id: `University of Michigan - Dearborn` },
          { text: `University of Michigan - Flint`, id: `University of Michigan - Flint` },
          { text: `University of Minnesota - Crookston`, id: `University of Minnesota - Crookston` },
          { text: `University of Minnesota - Duluth`, id: `University of Minnesota - Duluth` },
          { text: `University of Minnesota - Morris`, id: `University of Minnesota - Morris` },
          { text: `University of Minnesota - Twin Cities Campus`, id: `University of Minnesota - Twin Cities Campus` },
          { text: `University of Mississippi`, id: `University of Mississippi` },
          { text: `University of Mississippi Medical Center`, id: `University of Mississippi Medical Center` },
          { text: `University of Missouri - Columbia`, id: `University of Missouri - Columbia` },
          { text: `University of Missouri - Kansas City`, id: `University of Missouri - Kansas City` },
          { text: `University of Missouri - Saint Louis`, id: `University of Missouri - Saint Louis` },
          { text: `University of Mobile`, id: `University of Mobile` },
          { text: `University of Montana`, id: `University of Montana` },
          { text: `University of Montana Western`, id: `University of Montana Western` },
          { text: `University of Montevallo`, id: `University of Montevallo` },
          { text: `University of Nebraska - Kearney`, id: `University of Nebraska - Kearney` },
          { text: `University of Nebraska - Lincoln`, id: `University of Nebraska - Lincoln` },
          { text: `University of Nebraska Medical Center`, id: `University of Nebraska Medical Center` },
          { text: `University of Nebraska - Omaha`, id: `University of Nebraska - Omaha` },
          { text: `University of Nebraska (System)`, id: `University of Nebraska (System)` },
          { text: `University of Nevada - Las Vegas`, id: `University of Nevada - Las Vegas` },
          { text: `University of Nevada - Reno`, id: `University of Nevada - Reno` },
          { text: `University of New England`, id: `University of New England` },
          { text: `University of New England, Westbrook College Campus`, id: `University of New England, Westbrook College Campus` },
          { text: `University of New Hampshire`, id: `University of New Hampshire` },
          { text: `University of New Hampshire - Manchester`, id: `University of New Hampshire - Manchester` },
          { text: `University of New Haven`, id: `University of New Haven` },
          { text: `University of New Mexico`, id: `University of New Mexico` },
          { text: `University of New Orleans`, id: `University of New Orleans` },
          { text: `University of North Alabama`, id: `University of North Alabama` },
          { text: `University of North America`, id: `University of North America` },
          { text: `University of North Carolina at Asheville`, id: `University of North Carolina at Asheville` },
          { text: `University of North Carolina at Chapel Hill`, id: `University of North Carolina at Chapel Hill` },
          { text: `University of North Carolina at Charlotte`, id: `University of North Carolina at Charlotte` },
          { text: `University of North Carolina at Greensboro`, id: `University of North Carolina at Greensboro` },
          { text: `University of North Carolina at Pembroke`, id: `University of North Carolina at Pembroke` },
          { text: `University of North Carolina at Wilmington`, id: `University of North Carolina at Wilmington` },
          { text: `University of North Dakota`, id: `University of North Dakota` },
          { text: `University of Northern Colorado`, id: `University of Northern Colorado` },
          { text: `University of Northern Iowa`, id: `University of Northern Iowa` },
          { text: `University of Northern Virginia`, id: `University of Northern Virginia` },
          { text: `University of Northern Washington`, id: `University of Northern Washington` },
          { text: `University of North Florida`, id: `University of North Florida` },
          { text: `University of North Texas`, id: `University of North Texas` },
          { text: `University of North Texas Health Science Center at Fort Worth`, id: `University of North Texas Health Science Center at Fort Worth` },
          { text: `University of NorthWest`, id: `University of NorthWest` },
          { text: `University of Notre Dame`, id: `University of Notre Dame` },
          { text: `University of Oklahoma`, id: `University of Oklahoma` },
          { text: `University of Oklahoma Health Sciences Center`, id: `University of Oklahoma Health Sciences Center` },
          { text: `University of Oregon`, id: `University of Oregon` },
          { text: `University of Osteopathic Medicine and Health Science`, id: `University of Osteopathic Medicine and Health Science` },
          { text: `University of Pennsylvania`, id: `University of Pennsylvania` },
          { text: `University of Phoenix`, id: `University of Phoenix` },
          { text: `University of Pittsburgh`, id: `University of Pittsburgh` },
          { text: `University of Pittsburgh at Bradford`, id: `University of Pittsburgh at Bradford` },
          { text: `University of Pittsburgh at Greensburg`, id: `University of Pittsburgh at Greensburg` },
          { text: `University of Pittsburgh at Johnstown`, id: `University of Pittsburgh at Johnstown` },
          { text: `University of Portland`, id: `University of Portland` },
          { text: `University of Puget Sound`, id: `University of Puget Sound` },
          { text: `University of Redlands`, id: `University of Redlands` },
          { text: `University of Rhode Island`, id: `University of Rhode Island` },
          { text: `University of Richmond`, id: `University of Richmond` },
          { text: `University of Rio Grande`, id: `University of Rio Grande` },
          { text: `University of Rochester`, id: `University of Rochester` },
          { text: `University of San Diego`, id: `University of San Diego` },
          { text: `University of San Francisco`, id: `University of San Francisco` },
          { text: `University of Science and Arts of Oklahoma`, id: `University of Science and Arts of Oklahoma` },
          { text: `University of Scranton`, id: `University of Scranton` },
          { text: `University of Sioux Falls`, id: `University of Sioux Falls` },
          { text: `University of South Alabama`, id: `University of South Alabama` },
          { text: `University of South Carolina`, id: `University of South Carolina` },
          { text: `University of South Carolina - Aiken`, id: `University of South Carolina - Aiken` },
          { text: `University of South Carolina - Beaufort`, id: `University of South Carolina - Beaufort` },
          { text: `University of South Carolina - Lancaster`, id: `University of South Carolina - Lancaster` },
          { text: `University of South Carolina - Salkehatchie`, id: `University of South Carolina - Salkehatchie` },
          { text: `University of South Carolina - Spartanburg`, id: `University of South Carolina - Spartanburg` },
          { text: `University of South Carolina - Sumter`, id: `University of South Carolina - Sumter` },
          { text: `University of South Carolina - Union`, id: `University of South Carolina - Union` },
          { text: `University of South Dakota`, id: `University of South Dakota` },
          { text: `University of Southern California`, id: `University of Southern California` },
          { text: `University of Southern Indiana`, id: `University of Southern Indiana` },
          { text: `University of Southern Maine`, id: `University of Southern Maine` },
          { text: `University of Southern Mississippi`, id: `University of Southern Mississippi` },
          { text: `University of South Florida`, id: `University of South Florida` },
          { text: `University of St. Francis`, id: `University of St. Francis` },
          { text: `University of St. Thomas, Houston`, id: `University of St. Thomas, Houston` },
          { text: `University of St. Thomas, St. Paul`, id: `University of St. Thomas, St. Paul` },
          { text: `University of Tampa`, id: `University of Tampa` },
          { text: `University of Tennessee - Chattanooga`, id: `University of Tennessee - Chattanooga` },
          { text: `University of Tennessee - Knoxville`, id: `University of Tennessee - Knoxville` },
          { text: `University of Tennessee - Martin`, id: `University of Tennessee - Martin` },
          { text: `University of Tennessee - Memphis`, id: `University of Tennessee - Memphis` },
          { text: `University of Tennessee Space Institute`, id: `University of Tennessee Space Institute` },
          { text: `University of Texas`, id: `University of Texas` },
          { text: `University of Texas at Arlington`, id: `University of Texas at Arlington` },
          { text: `University of Texas at Austin`, id: `University of Texas at Austin` },
          { text: `University of Texas at Brownsville`, id: `University of Texas at Brownsville` },
          { text: `University of Texas at Dallas`, id: `University of Texas at Dallas` },
          { text: `University of Texas at El Paso`, id: `University of Texas at El Paso` },
          { text: `University of Texas at San Antonio`, id: `University of Texas at San Antonio` },
          { text: `University of Texas at Tyler`, id: `University of Texas at Tyler` },
          { text: `University of Texas Health Center at Houston`, id: `University of Texas Health Center at Houston` },
          { text: `University of Texas Health Center at Tyler`, id: `University of Texas Health Center at Tyler` },
          { text: `University of Texas Health Science Center at San Antonio`, id: `University of Texas Health Science Center at San Antonio` },
          { text: `University of Texas M.D. Anderson Cancer Center`, id: `University of Texas M.D. Anderson Cancer Center` },
          { text: `University of Texas Medical Branch Galveston`, id: `University of Texas Medical Branch Galveston` },
          { text: `University of Texas of the Permian Basin`, id: `University of Texas of the Permian Basin` },
          { text: `University of Texas Pan American`, id: `University of Texas Pan American` },
          { text: `University of Texas Southwestern Medical Center at Dallas`, id: `University of Texas Southwestern Medical Center at Dallas` },
          { text: `University of the Arts`, id: `University of the Arts` },
          { text: `University of the District of Columbia`, id: `University of the District of Columbia` },
          { text: `University of the Incarnate World`, id: `University of the Incarnate World` },
          { text: `University of the Ozarks`, id: `University of the Ozarks` },
          { text: `University of the Pacific`, id: `University of the Pacific` },
          { text: `University of the Sciences in Philadelphia`, id: `University of the Sciences in Philadelphia` },
          { text: `University of the South`, id: `University of the South` },
          { text: `University of the Southwest`, id: `University of the Southwest` },
          { text: `University of Toledo`, id: `University of Toledo` },
          { text: `University of Tulsa`, id: `University of Tulsa` },
          { text: `University of Utah`, id: `University of Utah` },
          { text: `University of Vermont`, id: `University of Vermont` },
          { text: `University of Virginia`, id: `University of Virginia` },
          { text: `University of Virginia, College at Wise`, id: `University of Virginia, College at Wise` },
          { text: `University of Washington`, id: `University of Washington` },
          { text: `University of Washington, Tacoma`, id: `University of Washington, Tacoma` },
          { text: `University of West Alabama`, id: `University of West Alabama` },
          { text: `University of West Florida`, id: `University of West Florida` },
          { text: `University of West Los Angeles`, id: `University of West Los Angeles` },
          { text: `University of Wisconsin - Eau Claire`, id: `University of Wisconsin - Eau Claire` },
          { text: `University of Wisconsin - Green Bay`, id: `University of Wisconsin - Green Bay` },
          { text: `University of Wisconsin - La Crosse`, id: `University of Wisconsin - La Crosse` },
          { text: `University of Wisconsin - Madison`, id: `University of Wisconsin - Madison` },
          { text: `University of Wisconsin - Milwaukee`, id: `University of Wisconsin - Milwaukee` },
          { text: `University of Wisconsin - Oshkosh`, id: `University of Wisconsin - Oshkosh` },
          { text: `University of Wisconsin - Parkside`, id: `University of Wisconsin - Parkside` },
          { text: `University of Wisconsin - Platteville`, id: `University of Wisconsin - Platteville` },
          { text: `University of Wisconsin - River Falls`, id: `University of Wisconsin - River Falls` },
          { text: `University of Wisconsin - Stevens Point`, id: `University of Wisconsin - Stevens Point` },
          { text: `University of Wisconsin - Stout`, id: `University of Wisconsin - Stout` },
          { text: `University of Wisconsin - Superior`, id: `University of Wisconsin - Superior` },
          { text: `University of Wisconsin - Whitewater`, id: `University of Wisconsin - Whitewater` },
          { text: `University of Wyoming`, id: `University of Wyoming` },
          { text: `Upper Iowa University`, id: `Upper Iowa University` },
          { text: `Urbana University`, id: `Urbana University` },
          { text: `Ursinus College`, id: `Ursinus College` },
          { text: `Ursuline College`, id: `Ursuline College` },
          { text: `Utah State University`, id: `Utah State University` },
          { text: `Utah Valley State College`, id: `Utah Valley State College` },
          { text: `Utica College`, id: `Utica College` },
          { text: `Valdosta State University`, id: `Valdosta State University` },
          { text: `Valley City State University`, id: `Valley City State University` },
          { text: `Valley Forge Christian College`, id: `Valley Forge Christian College` },
          { text: `Valparaiso University`, id: `Valparaiso University` },
          { text: `Vanderbilt University`, id: `Vanderbilt University` },
          { text: `VanderCook College of Music`, id: `VanderCook College of Music` },
          { text: `Vanguard University of Southern California`, id: `Vanguard University of Southern California` },
          { text: `Vassar College`, id: `Vassar College` },
          { text: `Vennard College`, id: `Vennard College` },
          { text: `Vermont Law School`, id: `Vermont Law School` },
          { text: `Vermont Technical College`, id: `Vermont Technical College` },
          { text: `Villa Julie College`, id: `Villa Julie College` },
          { text: `Villanova University`, id: `Villanova University` },
          { text: `Virginia College`, id: `Virginia College` },
          { text: `Virginia Commonwealth University`, id: `Virginia Commonwealth University` },
          { text: `Virginia Intermont College`, id: `Virginia Intermont College` },
          { text: `Virginia International University`, id: `Virginia International University` },
          { text: `Virginia Military Institute`, id: `Virginia Military Institute` },
          { text: `Virginia Polytechnic Institute and State University (Virginia Tech)`, id: `Virginia Polytechnic Institute and State University (Virginia Tech)` },
          { text: `Virginia State University`, id: `Virginia State University` },
          { text: `Virginia Union University`, id: `Virginia Union University` },
          { text: `Virginia Wesleyan College`, id: `Virginia Wesleyan College` },
          { text: `Viterbo College`, id: `Viterbo College` },
          { text: `Voorhees College`, id: `Voorhees College` },
          { text: `Wabash College`, id: `Wabash College` },
          { text: `Wagner College`, id: `Wagner College` },
          { text: `Wake Forest University`, id: `Wake Forest University` },
          { text: `Walden University`, id: `Walden University` },
          { text: `Walla Walla College`, id: `Walla Walla College` },
          { text: `Walsh College of Accountancy and Business Administration`, id: `Walsh College of Accountancy and Business Administration` },
          { text: `Walsh University`, id: `Walsh University` },
          { text: `Warner Pacific College`, id: `Warner Pacific College` },
          { text: `Warner Southern College`, id: `Warner Southern College` },
          { text: `Warren Wilson College`, id: `Warren Wilson College` },
          { text: `Wartburg College`, id: `Wartburg College` },
          { text: `Washburn University`, id: `Washburn University` },
          { text: `Washington and Lee University`, id: `Washington and Lee University` },
          { text: `Washington Bible College`, id: `Washington Bible College` },
          { text: `Washington College`, id: `Washington College` },
          { text: `Washington State University`, id: `Washington State University` },
          { text: `Washington State University, Spokane`, id: `Washington State University, Spokane` },
          { text: `Washington State University, Tri-Cities`, id: `Washington State University, Tri-Cities` },
          { text: `Washington State University, Vancouver`, id: `Washington State University, Vancouver` },
          { text: `Washington University in St. Louis`, id: `Washington University in St. Louis` },
          { text: `Wayland Baptist University`, id: `Wayland Baptist University` },
          { text: `Waynesburg College`, id: `Waynesburg College` },
          { text: `Wayne State College`, id: `Wayne State College` },
          { text: `Wayne State University`, id: `Wayne State University` },
          { text: `Webber College`, id: `Webber College` },
          { text: `Webb Institute`, id: `Webb Institute` },
          { text: `Weber State University`, id: `Weber State University` },
          { text: `Webster University`, id: `Webster University` },
          { text: `Webster University North Florida`, id: `Webster University North Florida` },
          { text: `Weill Medical College of Cornell University`, id: `Weill Medical College of Cornell University` },
          { text: `Wellesley College`, id: `Wellesley College` },
          { text: `Wells College`, id: `Wells College` },
          { text: `Wentworth Institute of Technology`, id: `Wentworth Institute of Technology` },
          { text: `Wesleyan College`, id: `Wesleyan College` },
          { text: `Wesleyan University`, id: `Wesleyan University` },
          { text: `Wesley College`, id: `Wesley College` },
          { text: `Wesley College Mississippi`, id: `Wesley College Mississippi` },
          { text: `Westbrook University `, id: `Westbrook University ` },
          { text: `West Chester University of Pennsylvania`, id: `West Chester University of Pennsylvania` },
          { text: `West Coast University`, id: `West Coast University` },
          { text: `Western Baptist College`, id: `Western Baptist College` },
          { text: `Western Bible College`, id: `Western Bible College` },
          { text: `Western Carolina University`, id: `Western Carolina University` },
          { text: `Western Connecticut State University`, id: `Western Connecticut State University` },
          { text: `Western Governors University`, id: `Western Governors University` },
          { text: `Western Illinois University`, id: `Western Illinois University` },
          { text: `Western International University`, id: `Western International University` },
          { text: `Western Kentucky University`, id: `Western Kentucky University` },
          { text: `Western Maryland College`, id: `Western Maryland College` },
          { text: `Western Michigan University`, id: `Western Michigan University` },
          { text: `Western New England College`, id: `Western New England College` },
          { text: `Western New Mexico University`, id: `Western New Mexico University` },
          { text: `Western Oregon University`, id: `Western Oregon University` },
          { text: `Western State College`, id: `Western State College` },
          { text: `Western States Chiropractic College`, id: `Western States Chiropractic College` },
          { text: `Western State University College of Law`, id: `Western State University College of Law` },
          { text: `Western State University College of Law - Orange County`, id: `Western State University College of Law - Orange County` },
          { text: `Western Washington University`, id: `Western Washington University` },
          { text: `Westfield State College`, id: `Westfield State College` },
          { text: `West Liberty State College`, id: `West Liberty State College` },
          { text: `Westminster College Fulton`, id: `Westminster College Fulton` },
          { text: `Westminster College New Wilmington`, id: `Westminster College New Wilmington` },
          { text: `Westminster College of Salt Lake City`, id: `Westminster College of Salt Lake City` },
          { text: `Westmont College`, id: `Westmont College` },
          { text: `West Suburban College of Nursing`, id: `West Suburban College of Nursing` },
          { text: `West Texas A&M University`, id: `West Texas A&M University` },
          { text: `West Virginia School of Osteopathic Medicine`, id: `West Virginia School of Osteopathic Medicine` },
          { text: `West Virginia State College`, id: `West Virginia State College` },
          { text: `West Virginia University`, id: `West Virginia University` },
          { text: `West Virginia University Institute of Technology`, id: `West Virginia University Institute of Technology` },
          { text: `West Virginia Wesleyan College`, id: `West Virginia Wesleyan College` },
          { text: `Westwood College`, id: `Westwood College` },
          { text: `Wheaton College Massachusetts`, id: `Wheaton College Massachusetts` },
          { text: `Wheeling Jesuit University`, id: `Wheeling Jesuit University` },
          { text: `Wheelock College`, id: `Wheelock College` },
          { text: `Whitman College`, id: `Whitman College` },
          { text: `Whittier College`, id: `Whittier College` },
          { text: `Whitworth College`, id: `Whitworth College` },
          { text: `Wichita State University`, id: `Wichita State University` },
          { text: `Widener University`, id: `Widener University` },
          { text: `Wilberforce University`, id: `Wilberforce University` },
          { text: `Wilbur Wright College`, id: `Wilbur Wright College` },
          { text: `Wiley College`, id: `Wiley College` },
          { text: `Wilkes University`, id: `Wilkes University` },
          { text: `Willamette University`, id: `Willamette University` },
          { text: `William Carey College`, id: `William Carey College` },
          { text: `William Jewell College`, id: `William Jewell College` },
          { text: `William Mitchell College of Law`, id: `William Mitchell College of Law` },
          { text: `William Paterson University`, id: `William Paterson University` },
          { text: `William Penn College`, id: `William Penn College` },
          { text: `Williams Baptist College`, id: `Williams Baptist College` },
          { text: `Williams College`, id: `Williams College` },
          { text: `William Tyndale College`, id: `William Tyndale College` },
          { text: `William Woods University`, id: `William Woods University` },
          { text: `Wilmington College`, id: `Wilmington College` },
          { text: `Wilmington College`, id: `Wilmington College` },
          { text: `Wilson College`, id: `Wilson College` },
          { text: `Wingate University`, id: `Wingate University` },
          { text: `Winona State University`, id: `Winona State University` },
          { text: `Winston-Salem State University`, id: `Winston-Salem State University` },
          { text: `Winthrop University`, id: `Winthrop University` },
          { text: `Wisconsin Lutheran College`, id: `Wisconsin Lutheran College` },
          { text: `Wisconsin School of Professional Psychology`, id: `Wisconsin School of Professional Psychology` },
          { text: `Wittenberg University`, id: `Wittenberg University` },
          { text: `Wofford College`, id: `Wofford College` },
          { text: `Woodbury University`, id: `Woodbury University` },
          { text: `Worcester Polytechnic Institute`, id: `Worcester Polytechnic Institute` },
          { text: `Worcester State College`, id: `Worcester State College` },
          { text: `Wright Institute`, id: `Wright Institute` },
          { text: `Wright State University`, id: `Wright State University` },
          { text: `Xavier University`, id: `Xavier University` },
          { text: `Xavier University of Louisiana`, id: `Xavier University of Louisiana` },
          { text: `Yale University`, id: `Yale University` },
          { text: `Yeshiva University`, id: `Yeshiva University` },
          { text: `York College Nebraska`, id: `York College Nebraska` },
          { text: `York College of Pennsylvania`, id: `York College of Pennsylvania` },
          { text: `Yorker International University`, id: `Yorker International University` },
          { text: `York University`, id: `York University` },
          { text: `Youngstown State University`, id: `Youngstown State University`} 
        ],
        SUBJECTS: [
          { text: "Accounting", id: "Accounting" },
          { text: "Aerospace engineering", id: "Aerospace engineering" },
          { text: "African studies", id: "African studies" },
          { text: "Agricultural sciences", id: "Agricultural sciences" },
          { text: "Agriculture", id: "Agriculture" },
          { text: "American studies", id: "American studies" },
          {
            text: "Anatomy, physiology & pathology",
            id: "Anatomy, physiology & pathology",
          },
          { text: "Ancient language studies", id: "Ancient language studies" },
          { text: "Animal science", id: "Animal science" },
          { text: "Anthropology", id: "Anthropology" },
          { text: "Archaeology", id: "Archaeology" },
          { text: "Architecture", id: "Architecture" },
          {
            text: "Architecture, Building and Planning",
            id: "Architecture, Building and Planning",
          },
          { text: "Astronomy", id: "Astronomy" },
          {
            text: "Aural &amp; oral sciences",
            id: "Aural &amp; oral sciences",
          },
          { text: "Biological Sciences", id: "Biological Sciences" },
          { text: "Biology", id: "Biology" },
          { text: "Biotechnology", id: "Biotechnology" },
          { text: "Botany", id: "Botany" },
          { text: "Building", id: "Building" },
          {
            text: "Business and Administrative Studies",
            id: "Business and Administrative Studies",
          },
          { text: "Business studies", id: "Business studies" },
          { text: "Celtic studies", id: "Celtic studies" },
          { text: "Ceramics &amp; glass", id: "Ceramics &amp; glass" },
          {
            text: "Chemical, process &amp; energy engineering",
            id: "Chemical, process &amp; energy engineering",
          },
          { text: "Chemistry", id: "Chemistry" },
          { text: "Chinese studies", id: "Chinese studies" },
          {
            text: "Cinematics &amp; photography",
            id: "Cinematics &amp; photography",
          },
          { text: "Civil engineering", id: "Civil engineering" },
          { text: "Classical Greek studies", id: "Classical Greek studies" },
          { text: "Clinical dentistry", id: "Clinical dentistry" },
          {
            text: "Comparative literary studies",
            id: "Comparative literary studies",
          },
          {
            text: "Complementary medicines, therapies &amp; well-being",
            id: "Complementary medicines, therapies &amp; well-being",
          },
          {
            text: "Computer generated visual &amp; audio effects",
            id: "Computer generated visual &amp; audio effects",
          },
          { text: "Computer science", id: "Computer science" },
          { text: "Computer Sciences", id: "Computer Sciences" },
          { text: "Crafts", id: "Crafts" },
          { text: "Creative Arts and Design", id: "Creative Arts and Design" },
          { text: "Dance", id: "Dance" },
          { text: "Design studies", id: "Design studies" },
          { text: "Development studies", id: "Development studies" },
          { text: "Drama", id: "Drama" },
          {
            text:
              "Eastern, Asiatic, African, American and Australasian Languages, Literature and Related Subjects",
            id:
              "Eastern, Asiatic, African, American and Australasian Languages, Literature and Related Subjects",
          },
          { text: "Economics", id: "Economics" },
          { text: "Education", id: "Education" },
          {
            text: "Electronic &amp; electrical engineering",
            id: "Electronic &amp; electrical engineering",
          },
          { text: "Engineering", id: "Engineering" },
          { text: "English studies", id: "English studies" },
          {
            text: "European Languages, Literature and Related Subjects",
            id: "European Languages, Literature and Related Subjects",
          },
          { text: "European Studies", id: "European Studies" },
          { text: "Finance", id: "Finance" },
          { text: "Fine art", id: "Fine art" },
          {
            text: "Food &amp; beverage studies",
            id: "Food &amp; beverage studies",
          },
          {
            text: "Forensic &amp; archaeological sciences",
            id: "Forensic &amp; archaeological sciences",
          },
          {
            text: "Forestry &amp; arboriculture",
            id: "Forestry &amp; arboriculture",
          },
          { text: "French studies", id: "French studies" },
          { text: "Games", id: "Games" },
          { text: "General engineering", id: "General engineering" },
          { text: "Genetics", id: "Genetics" },
          { text: "Geology", id: "Geology" },
          { text: "German studies", id: "German studies" },
          { text: "Health informatics", id: "Health informatics" },
          { text: "Heritage studies", id: "Heritage studies" },
          {
            text: "Historical and Philosophical Studies",
            id: "Historical and Philosophical Studies",
          },
          { text: "History by area", id: "History by area" },
          { text: "History by period", id: "History by period" },
          { text: "History by topic", id: "History by topic" },
          {
            text: "Hospitality, leisure, sport, tourism &amp; transport",
            id: "Hospitality, leisure, sport, tourism &amp; transport",
          },
          {
            text: "Human &amp; social geography",
            id: "Human &amp; social geography",
          },
          {
            text: "Human resource management",
            id: "Human resource management",
          },
          { text: "Imaginative writing", id: "Imaginative writing" },
          { text: "Information services", id: "Information services" },
          { text: "Information systems", id: "Information systems" },
          { text: "Italian studies", id: "Italian studies" },
          { text: "Japanese studies", id: "Japanese studies" },
          { text: "Journalism", id: "Journalism" },
          {
            text: "Landscape &amp; garden design",
            id: "Landscape &amp; garden design",
          },
          { text: "Latin studies", id: "Latin studies" },
          { text: "Law", id: "Law" },
          { text: "Law by area", id: "Law by area" },
          { text: "Law by topic", id: "Law by topic" },
          { text: "Linguistics", id: "Linguistics" },
          {
            text: "Linguistics, Classics and Related Subjects",
            id: "Linguistics, Classics and Related Subjects",
          },
          { text: "Management studies", id: "Management studies" },
          { text: "Maritime technology", id: "Maritime technology" },
          { text: "Marketing", id: "Marketing" },
          {
            text: "Mass Communication and Documentation",
            id: "Mass Communication and Documentation",
          },
          { text: "Materials science", id: "Materials science" },
          {
            text: "Materials technology not otherwise specified",
            id: "Materials technology not otherwise specified",
          },
          { text: "Mathematical Sciences", id: "Mathematical Sciences" },
          { text: "Mathematics", id: "Mathematics" },
          { text: "Mechanical engineering", id: "Mechanical engineering" },
          { text: "Media studies", id: "Media studies" },
          { text: "Medical technology", id: "Medical technology" },
          { text: "Medicine and Dentistry", id: "Medicine and Dentistry" },
          { text: "Metallurgy", id: "Metallurgy" },
          { text: "Microbiology", id: "Microbiology" },
          { text: "Minerals technology", id: "Minerals technology" },
          {
            text: "Modern Middle Eastern studies",
            id: "Modern Middle Eastern studies",
          },
          {
            text: "Molecular biology, biophysics &amp; biochemistry (C700)",
            id: "Molecular biology, biophysics &amp; biochemistry (C700)",
          },
          { text: "Music", id: "Music" },
          { text: "Naval architecture", id: "Naval architecture" },
          { text: "Nursing", id: "Nursing" },
          { text: "Nutrition", id: "Nutrition" },
          { text: "Office skills", id: "Office skills" },
          { text: "Operational research", id: "Operational research" },
          { text: "Ophthalmics", id: "Ophthalmics" },
          { text: "Other Asian studies", id: "Other Asian studies" },
          {
            text: "Pharmacology, toxicology &amp; pharmacy",
            id: "Pharmacology, toxicology &amp; pharmacy",
          },
          { text: "Philosophy", id: "Philosophy" },
          {
            text: "Physical geographical sciences",
            id: "Physical geographical sciences",
          },
          { text: "Physical Sciences", id: "Physical Sciences" },
          { text: "Physics", id: "Physics" },
          {
            text: "Planning (urban, rural &amp; regional)",
            id: "Planning (urban, rural &amp; regional)",
          },
          { text: "Politics", id: "Politics" },
          { text: "Polymers &amp; textiles", id: "Polymers &amp; textiles" },
          { text: "Portuguese studies", id: "Portuguese studies" },
          { text: "Pre-clinical dentistry", id: "Pre-clinical dentistry" },
          {
            text: "Production &amp; manufacturing engineering",
            id: "Production &amp; manufacturing engineering",
          },
          { text: "Psychology", id: "Psychology" },
          { text: "Publicity studies", id: "Publicity studies" },
          { text: "Publishing", id: "Publishing" },
          {
            text: "Research &amp; study skills in education",
            id: "Research &amp; study skills in education",
          },
          {
            text: "Russian &amp; East European studies",
            id: "Russian &amp; East European studies",
          },
          { text: "Scandinavian studies", id: "Scandinavian studies" },
          {
            text: "Science of aquatic &amp; terrestrial environments",
            id: "Science of aquatic &amp; terrestrial environments",
          },
          { text: "Social policy", id: "Social policy" },
          { text: "Social Studies", id: "Social Studies" },
          { text: "Social work", id: "Social work" },
          { text: "Sociology", id: "Sociology" },
          { text: "Software engineering", id: "Software engineering" },
          { text: "South Asian studies", id: "South Asian studies" },
          { text: "Spanish studies", id: "Spanish studies" },
          {
            text: "Sport &amp; exercise science",
            id: "Sport &amp; exercise science",
          },
          { text: "Statistics", id: "Statistics" },
          {
            text: "Subjects Allied to Medicine",
            id: "Subjects Allied to Medicine",
          },
          { text: "Technologies", id: "Technologies" },
          {
            text: "Theology &amp; religious studies",
            id: "Theology &amp; religious studies",
          },
          { text: "Training teachers", id: "Training teachers" },
          {
            text: "Veterinary Sciences, Agriculture and Related Subjects",
            id: "Veterinary Sciences, Agriculture and Related Subjects",
          },
          { text: "Zoology", id: "Zoology" },
        ],
        NOTICE_PERIODS: [
          { text: "None", id: "None" },
          { text: "1 week", id: "1 week" },
          { text: "2 weeks", id: "2 weeks" },
          { text: "3 weeks", id: "3 weeks" },
          { text: "1 month", id: "1 month" },
          { text: "2 months", id: "2 months" },
          { text: "3 months", id: "3 months" },
          { text: "6 months", id: "6 months" },
        ],
        GRADES: [
          { text: "1st" },
          { text: "2:1" },
          { text: "2:2" },
          { text: "3rd" },
          { text: "Ordinary Degree (Pass)" },
          { text: "Fail" },
          { text: "Distinction" },
          { text: "Merit" },
          { text: "Other" },
        ],
      },
    };

    let user = JSON.parse(localStorage.getItem("user"));

    if (user !== null && user !== undefined) {
      this.setState({ user: user });
      window.globalVars.user = user;
      this.getLatestUser();
      this.getLatestTags();
    } else {
      setTimeout(
        function() {
          let user = JSON.parse(localStorage.getItem("user"));

          if (user !== null && user !== undefined) {
            this.setState({ user: user });
            window.globalVars.user = user;
            this.getLatestUser();
          }

          this.getLatestTags();
        }.bind(this),
        250
      );
    }
  }

  getConfigs = (force) => {
    let configs = JSON.parse(localStorage.getItem("configs"));

    if (!configs || force || Date.now() > configs.timestamp + 30000) {

      window.globalVars.server.api({
        method: "get",
        url: "api/config",
        then: function(res) {
          if (res.data) {
            localStorage.setItem(
              "configs",
              JSON.stringify({
                timestamp: Date.now(),
                configs: res.data,
              })
            );

            if (
              res.data.bullhornDown &&
              res.data.bullhornDown === "true" &&
              window.location.href.indexOf("maintenance") === -1
            ) {
              window.location.href = "/#maintenance";
            } else if (
              res.data.bullhornDown &&
              res.data.bullhornDown === "false" &&
              window.location.href.indexOf("maintenance") !== -1
            ) {
              window.location.href = "/";
            }
          }

        }.bind(this),
        catch: function(e) {}.bind(this),
        ignoreUnauthorized: true,
      });
    } 
  };

  getLatestTags = () => {
    let tags = JSON.parse(localStorage.getItem("tags"));

    if (
      tags === null ||
      tags === undefined ||
      tags.vacancyTags === undefined ||
      tags.candidateTags === undefined ||
      Date.now() > tags.timestamp + 900000
    ) {
      window.globalVars.server.api({
        method: "get",
        url: "api/tags/all",
        then: function(res) {
          let tags = res.data.data.all;
          let featuredTags = res.data.data.featured;
          let vacancyTags = [];
          let candidateTags = [];
          let featuredCandidateTags = [];
          let featuredVacancyTags = [];

          for (let i = 0; i < tags.length; i++) {
            let categoryName = tags[i].categoryName;

            tags[i] = {
              id: tags[i].tagId.toString(),
              text: tags[i].tagName,
              cat: tags[i].categoryName,
            };

            if (categoryName[0] === "J") {
              vacancyTags.push(tags[i]);
            } else {
              candidateTags.push(tags[i]);
            }
          }

          for (let i = 0; i < featuredTags.length; i++) {
            let categoryName = featuredTags[i].category;

            let fullCategoryName = null;

            for (let j = 0; j < tags.length; j++) {
              //
              if (
                tags[j].text.split(" - ")[0] ===
                featuredTags[i].tagName.split(" - ")[0]
              ) {
                fullCategoryName = tags[j].cat;
                break;
              }
            }

            featuredTags[i] = {
              id: featuredTags[i]._id.toString(),
              text: featuredTags[i].tagName,
              count: featuredTags[i].count,
              type: featuredTags[i].category,
              category: featuredTags[i].tagName,
            };

            if (fullCategoryName) {
              featuredTags[i].catName = fullCategoryName.split(" ")[1];
            }

            if (categoryName === "G") {
              featuredCandidateTags.push(featuredTags[i]);
            } else {
              featuredVacancyTags.push(featuredTags[i]);
            }
          }

          let dateNow = Date.now();

          let tagsStorage = {
            timestamp: dateNow,
            tags: tags,
            candidateTags: candidateTags,
            vacancyTags: vacancyTags,
            featured: {
              candidate: featuredCandidateTags,
              vacancy: featuredVacancyTags,
            },
          };

          localStorage.setItem("tags", JSON.stringify(tagsStorage));
        }.bind(this),
        catch: function(e) {}.bind(this),
        ignoreUnauthorized: true,
      });
    }

    let newTags = JSON.parse(localStorage.getItem("newTags"));
    if (
      newTags === null ||
      newTags === undefined ||
      newTags.vacancyTags === undefined ||
      newTags.candidateTags === undefined
    ) {
      window.globalVars.server.api({
        method: "get",
        url: "api/tags/get-all",
        then: function(res) {
          let tags = res.data.data.all;

          tags = tags.map((item) => {
            return {
              id: item._id,
              text: item.tagName,
              cat: item.type,
            };
          });

          let dateNow = Date.now();

          let tagsStorage = {
            timestamp: dateNow,
            tags: tags,
            candidateTags: tags,
            vacancyTags: tags,
          };

          localStorage.setItem("newTags", JSON.stringify(tagsStorage));
        }.bind(this),
        catch: function(e) {}.bind(this),
        ignoreUnauthorized: true,
      });
    }
  };

  getLatestUser = () => {
    if (window.globalVars.preventGetUser) {
      return;
    }

    window.globalVars.preventGetUser = true;
    setTimeout(function() {
      window.globalVars.preventGetUser = false;
    }, 300);

    let authToken = localStorage.getItem("authToken");

    if (authToken !== null && authToken !== undefined) {
      window.globalVars.server.api({
        method: "post",
        url: "api/v2/user/current",
        then: function(res) {
          let user = res.data.data.user;

          if (user.locked) {
            if (
              localStorage.getItem("adminBussinessToken") ||
              localStorage.getItem("adminToken")
            ) {
            } else {
              window.globalVars.server.logout();
              return;
            }
          }

          this.setState({
            loggedIn: true,
            user: user,
          });

          if (!Utilities.isENU(user.wishlist)) {
            if (user.type === 4) {
              user.wishlist = user.wishlist.map((item) => {
                return item;
              });
              user.wishlist = user.wishlist.filter(Number);
              localStorage.setItem(
                "vacancyWishList",
                JSON.stringify(user.wishlist)
              );
            } else {
              user.wishlist = user.wishlist.map((item) => {
                if (item.indexOf("||") !== -1) {
                  let n = item.split("||");
                  if (n[n.length - 1] !== "undefined") {
                    return item;
                  }
                }
              });
              user.wishlist = user.wishlist.filter(function(element) {
                return element !== undefined;
              });
              localStorage.setItem(
                "candidateWishList",
                JSON.stringify(user.wishlist)
              );
            }

            let wishListCountDom = document.getElementsByClassName(
              "wish-list-count"
            );

            if (user.wishlist) {
              if (wishListCountDom[0])
                wishListCountDom[0].innerHTML = user.wishlist.length;
              if (wishListCountDom[1])
                wishListCountDom[1].innerHTML = user.wishlist.length;
            }
          }

          let guestWishList = JSON.parse(localStorage.getItem("guestWishList"));
          let guestWishListType = JSON.parse(
            localStorage.getItem("guestWishListType")
          );

          if (
            !Utilities.isENU(guestWishList) &&
            !Utilities.isENU(guestWishListType)
          ) {
            window.globalVars.server.api({
              method: "post",
              url: "api/user/wishlist/rewrite",
              data: {
                ids: guestWishList,
              },
              then: function(res) {
                if (guestWishListType === "candidate") {
                  localStorage.setItem(
                    "candidateWishList",
                    JSON.stringify(guestWishList)
                  );
                } else {
                  localStorage.setItem(
                    "vacancyWishList",
                    JSON.stringify(guestWishList)
                  );
                }

                localStorage.removeItem("guestWishList");
                localStorage.removeItem("guestWishListType");
                window.location.reload();
              }.bind(this),
              catch: function(e) {}.bind(this),
            });
          }

          if (!Utilities.isENU(user.applications)) {
            localStorage.setItem(
              "applications",
              JSON.stringify(user.applications)
            );
          }

          if (user.wishlistNames) {
            user.wishlistNames.unshift("Wish List 1");
            localStorage.setItem(
              "wishlistNames",
              JSON.stringify(user.wishlistNames)
            );
          }

          setTimeout(function() {
            delete user.wishlist;
            delete user.applications;
            delete user.wishlistNames;
            delete user.cvs;
            localStorage.setItem("user", JSON.stringify(user));

            window.globalVars.loggedIn = true;
            window.globalVars.user = user;
          });
        }.bind(this),
        catch: function(e) {
          window.globalVars.loggedIn = false;
          this.setState({ loggedIn: false });
        }.bind(this),
        ignoreUnauthorized: true,
      });
    }
  };

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider>
            <div
              data-ie={this.state.ie}
              data-ios={isIOS}
              data-mobile={isMobile}
              data-url={this.state.currentUrl}
            >
              {/*Maintenance*/}
              <Router history={history}>
                <div>
                  {!new_routes.includes(this.state.pathname) && (
                    <Navbar
                      history={history}
                      loggedIn={this.state.loggedIn}
                      user={this.state.user}
                      currentUrl={this.state.currentUrl}
                    />
                    )}
                  
                  <Switch>
                    <Route path="/" component={Home} exact></Route>

                    <CustomAuthRoute
                      path="/login/:type?"
                      component={Login}
                    ></CustomAuthRoute>
                    <Route
                      exact
                      path="/linkedinlogin"
                      component={LinkedInPage}
                    ></Route>
                    <Route
                      exact
                      path="/linkedin"
                      component={LinkedInCallback}
                    />
                    <CustomAuthRoute
                      path="/signup"
                      component={Signup}
                    ></CustomAuthRoute>
                    <BusinessRoute
                      path="/post-free-job"
                      component={SignupPostJobFree}
                    ></BusinessRoute>
                    {/* <CustomAuthRoute path="/login" component={ Login } ></CustomAuthRoute>
-                                       <CustomAuthRoute path="/signup" component={Login} ></CustomAuthRoute> */}
                    <CustomAuthRoute
                      path="/quick-apply-signup"
                      component={QuickApplySignup}
                    ></CustomAuthRoute>

                    <Route
                      exact
                      path="/directory/jobs"
                      component={() => <Directory type="jobs" />}
                    ></Route>
                    <Route
                      exact
                      path="/directory/grads"
                      component={() => <Directory type="grads" />}
                    ></Route>

                    <Route
                      path="/terms/employers"
                      component={() => <Static type="employer-terms" />}
                    ></Route>
                    <Route
                      path="/terms/jobseekers"
                      component={() => <Static type="graduate-terms" />}
                      exact
                    ></Route>
                    <Route
                      path="/terms/graduates"
                      component={() => <Static type="graduate-terms" />}
                      exact
                    ></Route>
                    <Route
                      path="/terms/potnoodle-graduates"
                      component={() => <Static type="potnoodle-graduates" />}
                      exact
                    ></Route>
                    <Route
                      path="/privacy"
                      component={() => <Static type="privacy" />}
                      exact
                    ></Route>
                    <Route
                      path="/cookiepolicy"
                      component={() => <Static type="cookie" />}
                      exact
                    ></Route>
                    <Route
                      path="/acceptable-use"
                      component={() => <Static type="acceptable-use" />}
                      exact
                    ></Route>
                    <Route path="/faq" component={() => <FAQ />} exact></Route>
                    <Route
                      path="/faq-videos"
                      component={() => <FAQVideos />}
                      exact
                    ></Route>

                    <Route
                      path="/graduates/post/:jobId/:id/:applicationId/:stack"
                      component={GraduateDetails}
                    ></Route>
                    <Route
                      path="/graduates/post/:id"
                      component={GraduateDetails}
                    ></Route>
                    <Route path="/graduates/cv" component={GraduateCV}></Route>
                    <Route
                      path="/graduates/ny"
                      component={() => <GraduateSearch city="ny" />}
                    ></Route>
                    {/* <BusinessRoute path="/graduates" component={ GraduateSearch }></BusinessRoute> */}
                    <BusinessRoute
                      path="/graduates"
                      component={GraduateList}
                    ></BusinessRoute>
                    <Route
                      path="/advance-search-graduates"
                      component={AdvanceGraduateSearch}
                    ></Route>
                    {/* <Route path="/advance-search-graduates" component={ AdvanceGradSearch }></Route> */}
                    <Route
                      path="/graduates/:type/:tags"
                      component={GraduateSearch}
                    ></Route>
                    {/* <Route path="/cv-search" component={ CVSearch }></Route> */}
                    <Route path="/cv-search" component={CVSearch}></Route>
                    <Route
                      path="/jobs/ny/post/"
                      component={() => <VacancyDetails city="ny" />}
                    ></Route>
                    <Route
                      path="/jobs/ny"
                      component={() => <OutsideJobSearch city="ny" />}
                    ></Route>
                    <Route
                      path="/jobs/potnoodle"
                      component={() => <JobSearch preloadTag="Pot Noodle" />}
                      exact
                    ></Route>
                    <PrivateRoute
                      path="/jobs/post/view/:id/statistics/:type"
                      component={JobStatistics}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/jobs/post/edit/"
                      component={JobOnboarding}
                    ></PrivateRoute>
                    <Route path="/jobs/post/view/" component={JobView}></Route>
                    <Route
                      path="/jobs/post/"
                      component={VacancyDetails}
                    ></Route>
                    {/* <GraduateRoute path="/jobs" component={ JobSearch }></GraduateRoute> */}
                    <GraduateRoute
                      path="/jobs/:jobtype?/:jobId?"
                      component={JobList}
                    ></GraduateRoute>
                    <Route path="/readability-checker" component={ReadabilityChecker}></Route>
                    <Route path="/resource-center" component={CareerHub} exact></Route>

                    <Route path="/job-search-new" component={JobSearchNew} />
                    <Route path="/grad-search-new" component={GradSearchNew} />
                    <Route
                      path="/jobs/:type/:tags"
                      component={JobSearch}
                    ></Route>
                    <Route
                      path="/advance-search"
                      component={AdvanceSearch}
                    ></Route>
                    {/* <Route path="/advance-search" component={AdvanceJobSearch} ></Route> */}
                    <Route path="/invite/:token" component={Invite}></Route>
                    {/* <Route path="/restore/email" exact component={() => <RestorePassword type="email" />}></Route>
                                        <Route path="/restore/password/:userId/:hash" exact component={() => <RestorePassword type="password" />}></Route> */}
                    <Route
                      path="/restore/email"
                      component={ForgotPassword}
                    ></Route>
                    <Route
                      path="/s/:shortUrl"
                      component={URLShortner}
                    ></Route>
                    <Route
                      path="/restore/password/:userId/:hash"
                      exact
                      component={ResetPassword}
                    ></Route>
                    <Route path="/insight" component={BlogList} exact></Route>
                    <Route path="/about" component={About} exact></Route>
                    <Route path="/trackevent" component={TrackEvent} exact></Route>
                    <Route
                      path="/insight/post/"
                      component={BlogDetails}
                    ></Route>
                    <Route
                      path="/resource-center/post/"
                      component={CareerHubDetails}
                    ></Route>
                    <Route
                      path="/insight/edit/:id"
                      component={BlogEdit}
                    ></Route>
                    <PrivateRoute
                      path="/saved/jobs"
                      component={() => <WishList type="vacancy" />}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/saved/grads"
                      component={() => <WishList type="candidate" />}
                    ></PrivateRoute>
                    <Route
                      path="/jobs-in/post/"
                      component={IndustryDetails}
                    ></Route>
                    <PrivateRoute
                      path="/dashboard/"
                      component={GraduateDashboard}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/graduate/onboarding"
                      component={GraduateOnboarding}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/business/onboarding"
                      component={BusinessOnboarding}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/business/company"
                      component={CompanySettings}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/graduate/settings/:type?"
                      component={GraduateSettings}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/my-profile/business/"
                      component={BusinessDashboard}
                      exact
                    ></PrivateRoute>
                    <Route
                      path="/admin/login-as/:id"
                      component={AdminLoginAs}
                    ></Route>
                    <AdminRoute
                      path="/admin/"
                      component={AdminDashboard}
                    ></AdminRoute>
                    <Route
                      path="/admin-agent/"
                      component={AdminAgentDashboard}
                    ></Route>
                    <Route
                      path="/admin-sonic-job/"
                      component={AdminSonicDashboard}
                    ></Route>
                    <Route path="/contact-us/" component={ContactUs}></Route>
                    <PrivateRoute
                      path="/messages/"
                      component={Messages}
                    ></PrivateRoute>
                    {/*<Route path="/potnoodle/" component={ PotNoodleMarketing }></Route>*/}
                    <Route path="/pepsico/" component={PepsiCo}></Route>
                    <Route path="/kickstart">
                      <Redirect to="/government-kickstart-scheme" />
                    </Route>
                    <Route
                      path="/government-kickstart-scheme"
                      component={GovKickStarterScheme}
                    ></Route>
                    <Route
                      path="/apply-kickstart-scheme"
                      component={ApplyGovKickStarterScheme}
                    ></Route>
                    <Route path="/thank-you/" component={ThankyouPage}></Route>
                    <Route
                      path="/manage-emails/:id/:token"
                      component={EmailNewsletter}
                    ></Route>
                    <Route
                      path="/subscription/:type/:action/:email/:token"
                      component={SubscriptionManager}
                    ></Route>
                    <Route
                      path="/email-signup/:email/:token"
                      component={EmailSignup}
                    ></Route>
                    <Route
                      path="/interview/schedule/:interviewId/:token"
                      component={InterviewScheduler}
                    ></Route>
                    <PrivateRoute
                      path="/pricing"
                      component={Pricing}
                    ></PrivateRoute>
                    <Route
                      path="/job-ad-writer"
                      component={GPTJobDescription}
                    ></Route>
                    <Route
                      path="/services/:section"
                      component={Services}
                    ></Route>
                    <Route
                      path="/business-pricing/:type?"
                      component={PricingNew}
                    ></Route>
                    <Route
                      path="/401/"
                      component={() => <Error code="401" />}
                    ></Route>
                    <Route
                      path="/newsletter/marketing/:email"
                      component={() => (
                        <MarketingSubscription key="marketing-subscription" />
                      )}
                    ></Route>
                    <Route
                      path="/unsubscribe/:email"
                      component={MarketingSubscription}
                    ></Route>
                    <Route
                      path="/unsubscribe"
                      component={MarketingSubscription}
                    ></Route>
                    <Route
                      path="/verify/:token"
                      component={EmailVerification}
                    ></Route>
                    {/* <Route path="/deloitte" component={ Deloitte }></Route> */}
                    <Route
                      path="/update-email-verification/:type/:token"
                      component={UpdateEmailVerification}
                    ></Route>
                    <PrivateRoute
                      path="/business/application/settings"
                      component={CompnayApplicationSettings}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/my-subscription"
                      component={MySubscription}
                    ></PrivateRoute>
                    <Route
                      path="/profile/:token"
                      component={CompanyProfileCompletion}
                    ></Route>
                    <Route component={Error} />
                    {/*<Route component={ Redirecter } />*/}
                  </Switch>
                  {!new_routes.includes(this.state.pathname) && <Footer />}
                  <CookieConsent
                    enableDeclineButton
                    location="bottom"
                    buttonText="Accept"
                    declineButtonText="Decline"
                  >
                    The website uses cookies to enhance the User experience. To
                    find out more, read our updated
                    <Link to="/cookiepolicy"> cookie policy</Link>.
                  </CookieConsent>
                </div>
              </Router>
            </div>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    );
  }
}

// App.contextTypes = {
//     router: () => {
//         return React.PropTypes.func.isRequired
//     }
// }

export default App;
