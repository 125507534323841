import './Sustainability.css';
import ClimateLogo from '../images/climate_logo.svg'

const Sustainability = () => {
    return (
        <div className='sustainability-wrapper'>
            <div className='container'>
                <div className="sustainability-home">
                    <h2>GradBay's Green Initiative: Partnering with Stripe Climate</h2>
                    <div>
                        <div className='sustainability-image'><img width={130} height={40} src={ClimateLogo} alt='sustainability' /></div>
                        <div>
                            <p>GradBay is a proud partner of Stripe Climate, a coalition of businesses accelerating carbon removal. Read more about how using GradBay contributes to a greener future here{" "}
                                <a target='_blank' href='https://climate.stripe.com/UPtogx' className='sustainability-link'>View more </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Sustainability;