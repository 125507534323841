let keys = {
    stripeSecret : "pk_live_uJxcVPoHoSyRkHLFibsOr8ae000rl6Z2Mt",
    domainUploadMS :  "https://gradbay-us-upload.gradbay.us/"
    
    

    // for staging
    
    // stripeSecret : "pk_test_LROHDtRGGy5pptQpdjgYhLMm00KcBKwv8v",
    // domainUploadMS :  "https://gradbay-usa-upload.hestawork.com/"
}

if (process.env.NODE_ENV === "development") {
    keys.stripeSecret = "pk_test_LROHDtRGGy5pptQpdjgYhLMm00KcBKwv8v",
    // keys.domainUploadMS = "https://gradbay-ms-upload.hestawork.com/"
    keys.domainUploadMS = "http://localhost:8082/"
    console.log("Loaded Local config")
} else {
    console.log("Loaded Dev config")
}

module.exports = keys;