import React, { PureComponent } from 'react';
import Slider from "react-slick";
import Logo1 from '../images/businesses/company_slider/1.webp';
import Logo2 from '../images/businesses/company_slider/2.webp';
import Logo3 from '../images/businesses/company_slider/3.webp';
import Logo4 from '../images/businesses/company_slider/4.webp';
import Logo5 from '../images/businesses/company_slider/5.webp';
import Logo6 from '../images/businesses/company_slider/6.webp';
import Logo7 from '../images/businesses/company_slider/7.webp';
import Logo8 from '../images/businesses/company_slider/8.webp';
import Logo9 from '../images/businesses/company_slider/9.webp';
import Logo10 from '../images/businesses/company_slider/10.webp';
import Logo11 from '../images/businesses/company_slider/11.webp';
import Logo12 from '../images/businesses/company_slider/12.webp';
import Logo13 from '../images/businesses/company_slider/13.webp';
import Logo14 from '../images/businesses/company_slider/14.webp';
import Logo15 from '../images/businesses/company_slider/15.webp';
import Logo16 from '../images/businesses/company_slider/16.webp';
import Logo17 from '../images/businesses/company_slider/17.webp';
import Logo18 from '../images/businesses/company_slider/18.webp';
import Logo19 from '../images/businesses/company_slider/19.webp';

import './CompaniesSlider.css';
import './Slider.css';
import { FiChevronLeft } from 'react-icons/fi';
import { FiChevronRight } from 'react-icons/fi';

class CompaniesSlider extends PureComponent {
    render() {
        let settings = {
            nextArrow: <FiChevronRight />,
            prevArrow: <FiChevronLeft />,
            dots: false,
            arrows: false,
            infinite: true,
            autoplay: true,
            speed: 2000,
            slidesToShow: 8,
            rows: 2,
            responsive: [
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 5,
                    }
                },
                {
                    breakpoint: 840,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4
                    }
                },
                {
                    breakpoint: 660,
                    settings: {
                        slidesToShow: 3,
                        arrows: true,
                        dots: false,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 560,
                    settings: {
                        slidesToShow: 2,
                        arrows: true,
                        dots: false,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 360,
                    settings: {
                        slidesToShow: 1,
                        arrows: true,
                        dots: false,
                        slidesToScroll: 1
                    }
                },
            ]
        };
        return (
            <div className='companies-slider-wrapper'>
                <h3>Companies hiring on GradBay</h3>
                <Slider className="companies-slider" {...settings}>
                    <div className="slide"> <img width={95} height={90} loading="lazy" alt="santander" src={Logo1} /></div>
                    <div className="slide"> <img width={95} height={90} loading="lazy" alt="citi" src={Logo2} /></div>
                    <div className="slide"> <img width={95} height={90} loading="lazy" alt="intel" src={Logo3} /></div>
                    <div className="slide"> <img width={95} height={90} loading="lazy" alt="dell" src={Logo4} /></div>
                    <div className="slide"> <img width={95} height={90} loading="lazy" alt="mass mutual" src={Logo5} /></div>
                    <div className="slide"> <img width={95} height={90} loading="lazy" alt="baxter" src={Logo6} /></div>
                    <div className="slide"> <img width={95} height={90} loading="lazy" alt="dish" src={Logo7} /></div>
                    <div className="slide"> <img width={95} height={90} loading="lazy" alt="empower" src={Logo8} /></div>
                    <div className="slide"> <img width={95} height={90} loading="lazy" alt="fidelity" src={Logo9} /></div>
                    <div className="slide"> <img width={95} height={90} loading="lazy" alt="staple" src={Logo10} /></div>
                    <div className="slide"> <img width={95} height={90} loading="lazy" alt="fm global" src={Logo11} /></div>
                    <div className="slide"> <img width={95} height={90} loading="lazy" alt="capital one" src={Logo12} /></div>
                    <div className="slide"> <img width={95} height={90} loading="lazy" alt="mckesson" src={Logo13} /></div>
                    <div className="slide"> <img width={95} height={90} loading="lazy" alt="walgreens" src={Logo14} /></div>
                    <div className="slide"> <img width={95} height={90} loading="lazy" alt="raytheon" src={Logo15} /></div>
                    <div className="slide"> <img width={95} height={90} loading="lazy" alt="charles" src={Logo16} /></div>
                    <div className="slide"> <img width={95} height={90} loading="lazy" alt="usaa" src={Logo17} /></div>
                    <div className="slide"> <img width={95} height={90} loading="lazy" alt="fisher investments" src={Logo18} /></div>
                    <div className="slide"> <img width={95} height={90} loading="lazy" alt="northrop grumman" src={Logo19} /></div>
                </Slider>
            </div>
        )
    }
}

export default CompaniesSlider;
