import { createSlice } from '@reduxjs/toolkit';

const JobReducer = createSlice({
    name: 'JobReducer',

    initialState: {
        jobs: null,
        isDetails: false
    },
  
    reducers: {
        setJobs: (state, action) => {
            state.jobs = action.payload
        },
        setIsDetails: (state, action) => {
            state.isDetails = action.payload
        }
    }
})

export const JobActions = JobReducer.actions

export default JobReducer.reducer