import React, { useState, useEffect } from "react";
import Loadable from "react-loadable";
import Cookies from "js-cookie";
import LoaderEmpty from "./components/LoaderEmpty.js";
import JobType from "./components/JobType";
import HomeSliderNew from "./HomeSlider.js";
import UserTypeWelcomePopup from "./components/UserTypeWelcomePopup.js";
import JobForYouSonicJobs from "./components/JobForYouSonicJobs.js";
import Sustainability from "./components/Sustainability.js";
import CompaniesSlider from "./components/CompaniesSlider.js"

const Reviews = Loadable({
    loader: () => import("./components/Reviews.js"),
    loading: LoaderEmpty,
});

const Home = (props) => {
    const [isBusiness, setIsBusiness] = useState(false);
    const [directProfile, setDirectProfile] = useState(false);

    useEffect(() => {
        const directProfile = JSON.parse(localStorage.getItem("directProfile"));
        if (directProfile === true) {
            setDirectProfile(directProfile);
        }

        const user = JSON.parse(localStorage.getItem("user"));
        const selected = user ? null : parseInt(Cookies.get("user_type"));
        setIsBusiness((user && user.type === 4) || (selected && selected === 4) ? true : false);

        document.title = window.globalVars.pageTitleBefore + "Home" + window.globalVars.pageTitleAfter;
        window.scrollTo(0, 0);
        window.globalVars.utilities.trackPageview("/");
    }, []);

    const userType = isBusiness ? "business" : "grad";

    return (
        <div className="home-page-gradbay">
            <HomeSliderNew type={userType} />
            <CompaniesSlider />
            <div className="gradbay-sections home_page_new">
                <JobType type={userType} history={props.history} />
                <JobForYouSonicJobs history={props.history} />
                <Sustainability />
                <div className={`${userType}-review`}>
                    <Reviews />
                </div>
            </div>

            {directProfile && <UserTypeWelcomePopup />}
        </div>
    );
};

export default Home;