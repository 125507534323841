/* eslint-disable */
import ShareIcon from '-!svg-react-loader!../icons/share.svg';
import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import utilities from './Utilities';
import VacancyEmojis from './VacancyEmojis.js';
import './JobForYou.css';
import './TagsContainer.css';
import jobprofile from "../images/jobprofile.svg";
import ShareModal from './ShareModal';
import sanitizeHtml from "sanitize-html";
import { apiRequest } from 'v2/Utilities';

let Utilities = new utilities();

const JobForYouSonicJobs = (props) => {
    const [shareModal, setShareModal] = useState(false);
    const [sonicJobs, setSonicJobs] = useState([]);
    const [selectedJob, setSelectedJob] = useState(null);

    useEffect(() => {
        getSonicJobs();
    }, []);

    const getSonicJobs = async () => {
        try {
            const res = await apiRequest({ method: 'get', url: "sonic-vacancy/home-jobs" });
            if (res.data.success === true) {
                setSonicJobs(res.data.data || []);
            }
        } catch (error) {
            console.log('Error fetch to load getSonicJobs ===>>> ', error.message);
        }
    };

    const toggleShareModal = (job) => {
        setSelectedJob(job);
        setShareModal(!shareModal);
    };

    const jobDetails = (id) => {
        props.history.push(`./jobs/express/${id}`);
    };

    const sanitizeText = (html) => {
        const blogContent = sanitizeHtml(html, {
            allowedTags: ["b", "i", "em", "strong", "a", "p", "div", "span"]
        });
        return blogContent;
    };

    return (
        <>
            <div className="Job-for-you">
                <h2 className="text-center">Recently added jobs</h2>
                <div className="container">
                    <div className="row">
                        {sonicJobs.map((item) => (
                            <div className="col-md-4" key={item.id}>
                                <div className="job-for-you-preview-card">
                                    <div className="d-flex profile-container">
                                        <div className="profile-img">
                                            <img onClick={() => jobDetails(item.id)} src={item.logo || jobprofile} alt="job profile" loading='lazy' />
                                        </div>
                                        <div className="content-wrap">
                                            <button className="favorite-btn" onClick={() => toggleShareModal(item)}>
                                                <ShareIcon />
                                                <span></span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="add_inner_wrap">
                                        <h2 onClick={() => jobDetails(item.id)}>
                                            {item.title !== undefined ? (item.title.length < 30 ? item.title : `${item.title.substr(0, 30)}...`) : <Skeleton count={2} width="260px" />}
                                        </h2>
                                        <div className="service_based_wrap">
                                            <label>{item.jobtype}</label>
                                            <span className="time_duratin">{Utilities.timeSince(item.dateposted)}</span>
                                        </div>
                                        {item.id !== undefined ? (
                                            <p className="description sonicDescription" dangerouslySetInnerHTML={{ __html: Utilities.shorten(Utilities.htmlToText(sanitizeText(item.description))).length > 370 ? `${sanitizeText(item.description).substr(0, 370)} ...` : sanitizeText(item.description) }} />
                                        ) : (
                                            <Skeleton count="3" />
                                        )}
                                        <span>
                                            <div className="tags-container current-search-result" data-style="2">
                                                <div className="each-tags">
                                                    {item.location ? <div className="tag false"><span>{item.location}</span></div> : ''}
                                                    {item.category ? <div className="tag false"><span>{item.category}</span></div> : ''}
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                    <div>
                                        <span onClick={() => jobDetails(item.id)}>
                                            <div className="cta">
                                                {item.id !== undefined ? (
                                                    <VacancyEmojis limit={4} items={item.emojis} />
                                                ) : (
                                                    <Skeleton width="140px" />
                                                )}
                                            </div>
                                        </span>
                                        <div className="text-center py-3">
                                            {item.id !== undefined ? (
                                                <button onClick={() => jobDetails(item.id)} className="apply-for-job-btn">View Details</button>
                                            ) : (
                                                <Skeleton width="100px" />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {selectedJob && (
                <ShareModal
                    open={shareModal}
                    toggle={toggleShareModal}
                    type="single"
                    contentType={props.type}
                    url={`${window.location.origin}/jobs/express/${selectedJob.id}`}
                    title="GradBay"
                    shareAttachVacancy={props.shareAttachVacancy}
                />
            )}
        </>
    );
};

export default JobForYouSonicJobs;
