/* eslint-disable */
import React, { Component } from 'react';
import Autocomplete from 'react-autocomplete';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { ToastContainer, ToastStore } from 'react-toasts';
import { Modal } from 'reactstrap';
import './Input.css';
import './Modal.css';
import './ShareModal.css';
import facebook from '../images/facebook.svg';
import copy from '../images/copy.svg';
import email from '../images/email.svg';
import linkedin from '../images/linkedin.svg';
import twitter from '../images/twitter.svg';
import whatsapp from '../images/whatsapp.svg';

class ShareModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: this.props.open,
            emailOpen: false,
            justCopied: false,
            statusMsg: null,
            url: this.props.url,
            vacancyId: null,
            attachVacancy: false,
            vacancyTitle: "",
            vacancies: [
                { title: 'Loading' },
            ]
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            modal: nextProps.open,
            url: nextProps.url
        });
    }

    sendEmail = () => {
        if(!this.state.email){
            this.setState({
                statusMsg: "Please enter an email address."
            })
        }

        this.setState({
            statusMsg: null
        })

        window.globalVars.server.api({
            method: "post",
            url: "api/contact/share/",
            data: {
                email: this.state.email,
                contentType: this.props.contentType,
                type: this.props.type,
                url: this.state.url,
                vacancyId: this.state.vacancyId,
                vacancyTitle: this.state.vacancyTitle
            },
            then: function(res) {
                if(res.data.success === false){
                    this.setState({
                        statusMsg: (res.data.message || "Unexpected Error")
                    })
                    return
                }else{
                    this.setState({
                        successMsg: res.data.message,
                        email: ''
                    }, ()=> {
                        setTimeout(() => {
                            this.setState({successMsg: null})
                        }, 5000)
                    })
                }

            }.bind(this),
            catch: function(e) {

                this.setState({
                    statusMsg: "Unexpected Error"
                })

            }.bind(this)
        })

    }

    onInputChange = (e) => {

        let modifiedField = e.target.id;
        let modifiedFieldValue = e.target.value;

        let state = {...this.state};
        state[modifiedField] = modifiedFieldValue;

        this.setState(state);

    }

    toggle = () => {
        this.props.toggle();
    }

    toggleEmail = () => {
        this.setState({
            emailOpen: !this.state.emailOpen
        });
    }

    copyToClipboard = (e) => {
        this.textArea.select();
        document.execCommand('copy');
        e.target.focus();
        this.setState({
            justCopied: true
        }, function() {
            setTimeout(function() {
                this.setState({
                    justCopied: false
                })
            }.bind(this), 1500);
        })
    }

    getVacancies = () => {

        window.globalVars.server.api({
            method: "get",
            url: "/api/vacancy/get-titles",
            then: function(res) {
                if (res.data.success) {
                    this.setState({
                        vacancies: res.data.data.data
                    })
                }
            }.bind(this),
            catch: function(err) {
            }.bind(this)
        })

    }

    apply = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    render() {

        let url = this.state.url;

        if(this.props.job_id){
            url = `${url}/post/${this.props.job_id}`
        }
        
        let title = this.props.title;

        return (
            <Modal isOpen={this.state.modal} toggle={this.toggle} className="share-modal" data-email-open={this.state.emailOpen}>
                <div className="body">
                    <h2>Share Now</h2>
                    { (this.props.shareAttachVacancy) &&
                        <div className="attach-vacancy-wrapper">
                            <div className="form-group">
                                <input
                                    type="checkbox"
                                    onChange={() => {

                                        this.setState({
                                            attachVacancy: !this.state.attachVacancy
                                        })

                                        if (this.state.vacancies.length === 1) {
                                            this.getVacancies();
                                        }

                                    }}
                                    defaultChecked={this.state.attachVacancy}
                                />
                                <label>Attach vacancy</label>
                            </div>
                            { (this.state.attachVacancy) &&
                                <div className="vacancy-picker">
                                    <Autocomplete
                                        getItemValue={(item) => item.title}
                                        items={this.state.vacancies}
                                        shouldItemRender={(item, value) => item.title.toLowerCase().indexOf(this.state.vacancyTitle.toLowerCase()) > -1}
                                        renderItem={(item, isHighlighted) => {
                                            return (<div key={item.title} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                                                {item.title}
                                            </div>)
                                        }
                                        }
                                        value={this.state.vacancyTitle}
                                        onChange={(e) => {
                                            this.setState({vacancyTitle: e.target.value })
                                            for (let i = 0; i < this.state.vacancies.length; i++) {
                                                let vac = this.state.vacancies[i];
                                                if (vac.title === e.target.value) {
                                                    this.setState({
                                                        vacancyId: vac.id,
                                                        vacancyTitle: vac.title,
                                                    })
                                                    break;
                                                }
                                            }
                                        }
                                        }
                                        onSelect={(val) => {
                                            this.setState({ vacancyTitle: val })
                                            for (let i = 0; i < this.state.vacancies.length; i++) {
                                                let vac = this.state.vacancies[i];
                                                if (vac.title === val) {
                                                    this.setState({
                                                        vacancyId: vac.id,
                                                        vacancyTitle: vac.title,
                                                    })
                                                    break;
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            }
                        </div>
                    }
                    <div className="platforms">
                        { ( (this.props.type === "single") && (!this.state.attachVacancy) ) ?
                            <FacebookShareButton
                                url={url}
                                quote={title}
                                className="platform facebook"
                            >
                                <img src={facebook} alt="facebook" />
                                <span>Facebook</span>
                            </FacebookShareButton>
                            : null
                        }
                        { ( (this.props.type === "single") && (!this.state.attachVacancy) ) ?
                            <TwitterShareButton
                                url={url}
                                title={title}
                                className="platform twitter"
                            >
                                <img src={twitter} alt="twitter" />
                                <span>Twitter</span>
                            </TwitterShareButton>
                            : null
                        }
                        { ( (this.props.type === "single") && (!this.state.attachVacancy) ) ?
                            <LinkedinShareButton
                                url={url}
                                title={title}
                                className="platform linkedin"
                            >
                                <img src={linkedin} alt="linkedin" />
                                <span>Linkedin</span>
                            </LinkedinShareButton>
                            : null
                        }
                        <div className="platform email" onClick={() => {this.toggleEmail()}}>
                            <img src={email} alt="email" />
                            <span>Email</span>
                        </div>
                        { (!this.state.attachVacancy) &&
                            <WhatsappShareButton
                                url={url}
                                title={title}
                                className="platform whatsapp"
                            >
                                <img src={whatsapp} alt="whatsapp" />
                                <span>WhatsApp</span>
                            </WhatsappShareButton>
                        }
                        {
                            (document.queryCommandSupported('copy') && (!this.state.attachVacancy) ) &&
                                <div className="platform copy" onClick={this.copyToClipboard}>
                                    <img src={copy} alt="copy" />
                                    <span>{this.state.justCopied ? "Copied!" : "Copy link"}</span>
                                </div>
                        }
                        <textarea
                            ref={(textarea) => this.textArea = textarea}
                            value={url}
                        />
                    </div>
                    <div className="email-share">
                        <div className="form-group">
                            <label htmlFor="email">Email Address</label>
                            <input onBlur={this.onInputChange} defaultValue={this.state.emailL} type="email" name="email" id="email" placeholder="Email Address" />
                        </div>
                        { (this.state.statusMsg !== null) ?
                            <div className="status" style={{color: 'red', fontSize: '14px'}}>
                                {this.state.statusMsg}
                            </div>
                            : null
                        }
                        { (this.state.successMsg !== null) ?
                            <div className="status" style={{color: '#45F6A6', fontSize: '14px'}}>
                                {this.state.successMsg}
                            </div>
                            : null
                        }
                        <div className="form-group">
                            <button onClick={this.sendEmail}>Send Email</button>
                        </div>
                    </div>
                </div>
                <ToastContainer position={ToastContainer.POSITION.TOP_CENTER} store={ToastStore}/>
            </Modal>
        );

    }

}

export default ShareModal;
