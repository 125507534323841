import React, { Component } from "react";
import Slider from "react-slick";
import './HomeSlider.css';
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import GraduateSlider from './lottie/graduate-slider-1.json';
import BusinessSlider from './lottie/business.json';

class HomeSliderNew extends Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem("user"));
    const selected = parseInt(Cookies.get("user_type"));
    this.state = {
      user: user,
      isBusiness: (user && user.type === 4) || (selected && selected === 4) ? false : true,
    };
  }

  render() {

    const { isBusiness, user } = this.state;

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: isBusiness ? BusinessSlider : GraduateSlider,
      rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
    }

    return (
      <div className="home_new_slider home_new_slider_wrapper">
        <div className={`first_slide`}>
          <div className="new_slider_wrap">
            <div className="banner_content_wrap">
              <h1 className="display_xl">{"Connecting emerging talent with growing companies."}</h1>
              {isBusiness && (
                <p dangerouslySetInnerHTML={{ __html: 'Post a free job and get instantly <br /> matched to grads.' }} />
              )}
              <Link to={isBusiness ? user ? "/jobs/post/edit/new" : "/post-free-job" : "/jobs/express"} className="banner_slide_btn">
                {isBusiness ? "Get Started" : 'Browse Jobs'}
              </Link>
            </div>
            <figure>
              <Lottie options={defaultOptions} height={450} width={480} />
            </figure>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeSliderNew;